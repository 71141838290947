let shoppingLists = {
    methods: {
        getShoppingLists: function () {
            return this.axios.get(
                this.config.ecommerce_service_url +
                "/api/v1/users/" +
                this.user.id +
                "/channels/" +
                this.config.channel.id +
                "/shopping_lists?includes=shopping_list_items",
                {
                    headers: {
                        Authorization: "Bearer " + this.user.jwtToken,
                        Accept: "application/json",
                    },
                }
            );
        },
    }
};
export default shoppingLists