let Product = {
    data: () => ({
        weightMeasures: ['kg', 'g'],
        volumMeasures: ['l', 'ml', 'cl'],
    }),
    methods: {
        getProductSellingDescription(product) {
            let unitOfMeasure = this.$store.getters['commons/unitOfMeasure'](product.unit_of_measure_id);
            if (product.packaged === false) {
                if (this.weightMeasures.includes(unitOfMeasure.symbol)) {
                    return 'A peso';
                } else {
                    return 'Alla mescita';
                }
            } else {
                if (parseInt(product.pieces) === 1) {
                    return product.short_description + ' ' + parseFloat(product.quantity).toString() + unitOfMeasure.symbol
                } else {
                    return product.short_description+ ' ' + product.pieces + ' x ' + parseFloat(product.quantity).toString() + unitOfMeasure.symbol
                }
            }
        },
        getProductPrice(product, catalogProduct, vatIncluded) {
            if (product.packaged) {
                if (vatIncluded) {
                    return (parseFloat(catalogProduct.value) * (1 + parseFloat(this.$store.getters['commons/category'](product.category_id).vat_percentage) / 100)).toFixed(2) + '€/conf'
                } else {
                    return parseFloat(catalogProduct.value).toFixed(2) + '€/conf'
                }
            } else {
                let measureType = null;
                let unitOfMeasure = this.$store.getters['commons/unitOfMeasure'](product.unit_of_measure_id);
                let scalarValue = product.quantity;
                switch (unitOfMeasure.symbol) {
                    case'l':
                        measureType = 'volume';
                        break;
                    case'cl':
                        measureType = 'volume';
                        scalarValue = scalarValue / 100;
                        break;
                    case'ml':
                        measureType = 'volume';
                        scalarValue = scalarValue / 1000;
                        break;
                    case'kg':
                        measureType = 'weight';
                        break;
                    case'g':
                        measureType = 'weight';
                        scalarValue = scalarValue / 1000;
                        break;
                }
                let price = parseFloat(catalogProduct.value) * (1 / scalarValue / parseFloat(product.pieces));
                let priceUom = (measureType === 'volume') ? 'L' : 'kg';

                if (vatIncluded) {
                    return (price * (1 + parseFloat(this.$store.getters['commons/category'](product.category_id).vat_percentage) / 100)).toFixed(2) + ' €/' + priceUom;
                } else {
                    return price.toFixed(2) + ' €/' + priceUom;
                }
            }
        },

    }
}

export default Product;