let cart = {
    methods: {
        postCartItem: function (catalog_product_id, quantity) {
            return this.axios.post(
                this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/cart_items',
                {
                    data: {
                        catalog_product_id: catalog_product_id,
                        quantity: quantity
                    }
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.user.jwtToken,
                        Accept: 'application/json'
                    }
                }
            )
        },
    }
};
export default cart