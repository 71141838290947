<template>
    <v-container fuild style="max-width:1280px">
        <v-row>
            <v-col cols="6" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <v-banner color="customGreen" width="100%" dark>
                    <v-row class="justify-center pt-16 text-center">
                        <v-avatar
                            color="customGreen"
                            size="250"
                        >
                            <v-img contain src="@/assets/images/icona-coltivatori.png"></v-img>
                        </v-avatar>
                    </v-row>
                    <v-row class="text-center Raleway align-self-center text-uppercase white--text pt-5">
                        <v-col cols="12" class="text-center d-none d-sm-block">
                            <v-btn text @click="$router.push({name: 'Businesses'})" class="ma-0">
                                <h1 class="font-weight-light" style="letter-spacing:0.1em">incontra chi coltiva</h1>
                            </v-btn>
                            <div style="height:2px;width:calc(100%);background-color:white" class="d-flex my-1"></div>
                            <v-btn text @click="$router.push({name: 'Businesses'})" class="ma-0">
                                <h4 class="font-weight-light" style="letter-spacing:0.1em">scopri i nostri produttori</h4>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" class="text-center d-sm-none">
                            <v-btn text @click="$router.push({name: 'Businesses'})" class="ma-0 text-left">
                                <h1 class="font-weight-light" style="font-size:1.3em;letter-spacing:0.1em">incontra chi coltiva</h1>
                            </v-btn>
                            <div style="height:2px;width:100%;background-color:white" class="d-flex my-1"></div>
                            <v-btn text @click="$router.push({name: 'Businesses'})" class="ma-0">
                                <h4 class="font-weight-light" style="font-size:0.9em;letter-spacing:0.1em">scopri i nostri produttori</h4>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-banner>
            </v-col>
            <v-col v-for="(business , index) in businesses" :key="index"
                  class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 px-3 pb-6 d-flex">
                <BusinessCard v-bind:businessData="business"></BusinessCard>
            </v-col>
        </v-row>
        <v-row v-if="pages.length > 1">
          <v-col class="text-center middle-black-line" style="position:relative">
            <template v-for="(page, index) in pages">
              <v-btn :key="index" tile text
                     class="elevation-0 white font-weight-black"
                     @click="setBusinesses(14, page)"
              >
                {{ page }}
              </v-btn>
            </template>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BusinessCard from "../components/BusinessCard";
import StoreComputed from "@/mixins/StoreComputed";
import responseErrorHandler from "../../../mixins/ResponseErrorHandler";

export default {
    name: 'Businesses',
    components: {BusinessCard},
    mixins: [StoreComputed, responseErrorHandler],
    data: () => ({
        businessesIncludes: ['business_type', 'province', 'province.region', 'media'],
        viewFilters: false,
        filters: {aliases: [], provinceIds: [], filterableCategoryIds: []},
        businesses: [],
        pagination: {total: null, last_page: null, current_page: null},
        getBusinessesRequest: {loading: true},
        randomNumber: 1
    }),
    methods: {
        setBusinesses: function(limit, page) {
        this.getBusinessesRequest.loading = true;
        this.businesses = [];
        this.getBusinesses(limit, page)
            .then(res => {
                let businesses = res.data.data;
                businesses.forEach(business => {
                    this.businesses.push(business.business);
                });
                let meta = res.data.meta;
                this.pagination = {total: meta.total, current_page: meta.current_page, last_page: meta.last_page}
            })
            .catch(error => {
                this.handleResponseError(error);
            })
            .finally(() => {
                this.getBusinessesRequest.loading = false;
            });
        },
        getBusinesses: function(limit, page){
        return this.axios.get(
            this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/businesses',
            {
                params: {
                    page: page,
                    limit: limit,
                    includes: this.businessesIncludes.join(','),
                    random_sorting: this.randomNumber
                },
                headers: {
                    Authorization: 'Bearer ' + this.user.jwtToken,
                    Accept: 'application/json'
                }
            }
        )
        }
    },
    mounted() {
        this.randomNumber = Math.floor(Math.random() * 10000);
        this.setBusinesses(14, 1);
    },
    computed: {
        pages: function () {
            let pages = [];
            for (let i = 1; i <= this.pagination.last_page; i++) {
                pages.push(i);
            }
            return pages;
        },
    }
}
</script>
