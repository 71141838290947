<template>
  <v-navigation-drawer
      v-model="application.drawer"
      app
      clipped
      v-if="application.showMobileMenu"
  >
    <v-list>
      <v-list-item v-for="(parentFilterableCategory) in $store.getters['commons/structuredFilterableCategories']"
                   :key="parentFilterableCategory.name"
                   no-action
                   :to="'/category/'+parentFilterableCategory.id"
      >
        <v-list-item-content>
          <v-list-item-title v-text="parentFilterableCategory.name"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";

export default {
  name: "Drawer",
  mixins: [StoreComputed],
  data: () => ({
    drawer: true,
    showMobileSearch: false,
    userMenu: false,
    productSearchString: '',
    keycloak: {},
    isMobile: false,
    showMobileMenu: false
  }),
}
</script>

<style scoped>

</style>