<template>
  <v-card class="mb-3" tile>
    <v-container>
      <v-row class="d-flex align-center">
        <v-col class="d-none d-sm-block">
          <v-list-item two-line>
            <v-list-item-avatar tile size="80" color="grey">
              <template v-if="cartItemData.catalog_product.product.images.length !== 0">
                <v-img
                  style="cursor: pointer"
                  :src="cartItemData.catalog_product.product.images[0].media.url"
                  :srcset="cartItemData.catalog_product.product.images[0].media.srcset"
                  sizes="10vw"
                  contain
                  min-width="100%"
                  :aspect-ratio="1"
                ></v-img>
              </template>
              <template v-else>
                <v-img
                  style="cursor: pointer"
                  src="@/assets/placeholders/default-placeholder.jpeg"
                  cover
                  min-width="100%"
                  :aspect-ratio="1"
                >
                </v-img>
              </template>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1 text-wrap">
                {{ cartItemData.catalog_product.product.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{
                  getProductSellingDescription(
                    cartItemData.catalog_product.product
                  )
                }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="mt-2 text-wrap">
                Di: {{ cartItemData.catalog_product.product.business.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="justify-center">
            <CartQuantityModifier
              v-bind:catalogProductData="cartItemData.catalog_product"
            ></CartQuantityModifier>
          </v-list-item>
        </v-col>
        <v-col class="d-sm-none">
          <template v-if="cartItemData.catalog_product.product.images.length !== 0">
                <v-img
                  style="cursor: pointer"
                  :src="cartItemData.catalog_product.product.images[0].media.url"
                  :srcset="cartItemData.catalog_product.product.images[0].media.srcset"
                  sizes="10vw"
                  contain
                  min-width="100%"
                  :aspect-ratio="1"
                ></v-img>
              </template>
              <template v-else>
                <v-img
                  style="cursor: pointer"
                  src="@/assets/placeholders/default-placeholder.jpeg"
                  cover
                  min-width="100%"
                  :aspect-ratio="1"
                >
                </v-img>
              </template>
              <v-card-title>{{ cartItemData.catalog_product.product.name }}</v-card-title>
              <v-card-subtitle class="py-0">{{ getProductSellingDescription(cartItemData.catalog_product.product)}}</v-card-subtitle>
              <v-card-subtitle class="py-0">Di: {{ cartItemData.catalog_product.product.business.name }}</v-card-subtitle>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" style="width:16%">Costo</th>
                  <th class="text-left" style="width:16%">Iva</th>
                  <th class="text-left" style="width:16%">Imponibile</th>
                  <th class="text-left" style="width:20%">Costo Iva Inclusa</th>
                  <th class="text-left" style="width:16%">Quantità</th>
                  <th class="text-right" style="width:16%">Totale</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{
                      cartItemData.catalog_product.product.packaged
                        ? parseFloat(
                            cartItemData.catalog_product.value
                          ).toFixed(2) + "€"
                        : getProductPrice(
                            cartItemData.catalog_product.product,
                            cartItemData.catalog_product
                          )
                    }}
                  </td>
                  <td>
                    {{
                      parseFloat(
                        $store.getters["commons/category"](
                          cartItemData.catalog_product.product.category_id
                        ).vat_percentage
                      ).toString()
                    }}
                    %
                  </td>
                  <td>
                    {{
                      (
                        parseFloat(cartItemData.catalog_product.value) *
                        parseFloat(cartItemData.quantity)
                      ).toFixed(2)
                    }}€
                  </td>
                  <td>
                    {{ getProductPrice(cartItemData.catalog_product.product, cartItemData.catalog_product, true) }}
                  </td>
                  <td>
                    <template
                      v-if="cartItemData.catalog_product.product.packaged"
                    >
                      {{ Math.floor(parseInt(cartItemData.quantity)) }} pz
                    </template>
                    <template v-else>
                      {{ parseFloat(cartItemData.quantity).toFixed(2)
                      }}{{
                        $store.getters["commons/unitOfMeasure"](
                          cartItemData.catalog_product.product
                            .unit_of_measure_id
                        ).symbol
                      }}
                    </template>
                  </td>
                  <td class="text-right">
                    {{ (parseFloat(cartItemData.catalog_product.value) *
                      parseFloat(cartItemData.quantity ) * (
                          1 + parseFloat($store.getters['commons/category'](cartItemData.catalog_product.product.category_id).vat_percentage)/100)
                  ).toFixed(2)}}€
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import CartQuantityModifier from "@/views/ecommerce/components/product/CartQuantityModifier";
import Product from "@/mixins/Product";
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";

export default {
  components: { CartQuantityModifier },
  name: "CartItem",
  props: ["cartItemData"],
  mixins: [Product, StoreComputed, ResponseErrorHandler],
};
</script>

<style scoped>
</style>