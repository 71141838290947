let ResponseErrorHandler = {
    methods: {
        handleResponseError: function (error) {
            let response = error.response;
            if (response === undefined) {
                console.log(error);
                this.$store.commit('snackbar/update', {
                    show: true,
                    text: 'Servizio momentaneamente non disponibile',
                    color: 'error'
                });
            } else {
                let status = response.status;
                if (status === undefined) {
                    this.$store.commit('snackbar/update', {
                        show: true,
                        text: error.toString(),
                        color: 'error'
                    });
                } else if (status === 401) {
                    this.$store.commit('snackbar/update', {
                        show: true,
                        text: 'Sessione scaduta',
                        color: 'error'
                    });
                    let self = this;
                    setTimeout(function () {
                        localStorage.removeItem('ecommerce-coldiretti-access-token');
                        self.$router.push({name: 'Login'})
                    }, 3000);

                    setTimeout(function () {
                        localStorage.removeItem('ecommerce-coldiretti-access-token');
                        self.$router.push({name: 'Login'})
                    }, 1000);
                } else {
                    let errors = response.data.errors;
                    this.$store.commit('snackbar/update', {
                        show: true,
                        text: errors[0].detail,
                        color: 'error'
                    });
                }
            }
        }
    }
};
export default ResponseErrorHandler;