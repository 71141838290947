<template>
  <v-footer class="customGreen py-10">
    <v-container style="max-width:1280px">
      <v-row class=" white--text Raleway" style="font-size: small;">
        <v-col class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
          <v-list color="customGreen" dense>
            <v-list-item dense>
              <v-list-item-avatar color="white">
                <v-icon class="customGreen--text">
                  mdi-domain
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text">SOCIETA’ COOPERATIVA CONSORTILE AGRICOLA – LA NUOVA AURORA
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-avatar color="white">
                <v-icon class="customGreen--text">
                  mdi-map-marker
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text">Potenza al Viale del Basento n. 16</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-avatar color="white">
                <v-icon class="customGreen--text">
                  mdi-information
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text">P.IVA e Codice Fiscale n. 02058400769</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-avatar color="white">
                <v-icon class="customGreen--text">
                  mdi-phone
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text">0971/508211</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item dense>
              <v-list-item-avatar color="white">
                <v-icon class="customGreen--text">
                  mdi-at
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="white--text">
                  <a href="mailto:info@iosonolucano.it" class="white--text" style="text-decoration:none">
                    Mail: info@iosonolucano.it
                  </a> - <a href="mailto:lanuovaaurorasoccoopconso@pec.agritel.it" class="white--text" style="text-decoration:none">
                    Pec: lanuovaaurorasoccoopconso@pec.agritel.it
                  </a></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="2" class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12  align-self-center">
          <v-btn href="/documenti/Informativa privacy.pdf" target="_blank" tile text block class="text-uppercase white--text my-3">informativa privacy</v-btn>
          <v-btn href="/documenti/Termini e Condizioni di pagamento.pdf" target="_blank" tile text block class="text-uppercase white--text my-3">Termini e condizioni di pagamento</v-btn>
          <v-btn href="/documenti/Informativa generale e condizioni di uso della piattaforma IO SONO LUCANO.pdf" target="_blank" tile text block class="text-uppercase white--text my-3">Condizioni d'uso</v-btn>
          <v-btn href="/documenti/Politiche di vendita e resi.pdf" target="_blank" tile text block class="text-uppercase white--text my-3">Politiche di vendita e resi</v-btn>
          <v-btn href="/documenti/Codice etico.pdf" target="_blank" tile text block class="text-uppercase white--text my-3">codice etico</v-btn>
          <v-btn href="/documenti/Codice e Regolamento di Disciplina aziendale.pdf" target="_blank" tile text block class="text-uppercase white--text my-3">Codice e Regolamento di Disciplina aziendale</v-btn>
        </v-col>
        <v-col cols="1" class="black align-self-center text-center col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12 align-self-center">
          <v-btn icon color="white" @click="$vuetify.goTo(0)">
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: "Footer.vue"
}
</script>
<style scoped lang="scss">

</style>