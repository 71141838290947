<template>
  <div>
    <Drawer v-if="application.showMobileMenu"></Drawer>
    <Header class="my-4"></Header>
    <v-main class="mt-4">
      <router-view style="min-height:100vh"></router-view>
    </v-main>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/ecommerce/components/Header";
import Footer from "@/views/ecommerce/components/Footer";
import Drawer from '@/views/ecommerce/components/Drawer.vue';
import StoreComputed from "@/mixins/StoreComputed";

export default {
  name: "Ecommerce.vue",
  components: {Header, Drawer, Footer},
  mixins: [StoreComputed],
  data: () => ({
    isMobile: false,
    showMobileMenu: false
  }),
  methods: {
    setWindowWithRelatedValues() {
      this.$store.commit('application/setIsMobile', window.innerWidth < 960);
      this.$store.commit('application/setShowMobileMenu', window.innerWidth < 1130);
    }
  },
  mounted() {
    this.setWindowWithRelatedValues();
    window.addEventListener('resize', this.setWindowWithRelatedValues, {passive: true})
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.setWindowWithRelatedValues, {passive: true})
    }
  }
}
</script>

<style scoped>

</style>