<template>
  <v-container>
    <router-view
      v-on:shoppingList:created="getList(user.id)"
      v-on:shoppingList:deleted="getList(user.id)"
      v-on:shoppingList:updated="getList(user.id)"
    ></router-view>
    <v-col cols="12">
      <v-toolbar dark color="primary">
        <v-toolbar-title>LE TUE LISTE</v-toolbar-title>
          <v-spacer />

        <v-btn icon>
          <v-icon @click="$router.push({ name: 'ShoppingListCreate' })"
            >mdi-plus-circle-outline</v-icon
          > 
        </v-btn>
      </v-toolbar>
    </v-col>

    <v-col cols="12" v-for="list in shoppingLists" :key="list.id">
      <v-card color="primary" dark
        >
        <div class="d-flex flex-no-wrap justify-space-between">
          <v-card-title >
             {{ list.name }}
          </v-card-title>
          <v-card-actions>
              <v-btn
              outlined
              x-small
              @click="
                $router.push({
                  name: 'ShoppingListShow',
                  params: { listId: list.id },
                })
              "
            >visualizza
            </v-btn>
            <v-btn
              outlined
              x-small
              @click="
                $router.push({
                  name: 'ShoppingListEdit',
                  params: { listId: list.id },
                })
              "
            >
              Rinomina
            </v-btn>
            <v-btn
              outlined
              x-small
              @click="
                $router.push({
                  name: 'ShoppingListDelete',
                  params: { listId: list.id },
                })
              "
            >
              Rimuovi
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";

export default {
  name: "ShoppingList",
  mixins: [StoreComputed, ResponseErrorHandler],
  data: () => ({
    getUserShoppingListRequest: { loading: false },
    putUserShoppingRequest: { loading: false },
    shoppingLists: [],
  }),
  methods: {
    getList(userId) {
      this.getUserShoppingListRequest.loading = true;
      this.getShoppingLists(userId)
        .then((response) => {
          
          this.shoppingLists = this._.map(response.data.data, (list) => {
            var countShoppingProducts = list.shopping_list.shopping_list_items.reduce((a, obj) => a + Object.keys(obj).length, 0);
            return list.shopping_list;

          });
        })
        .catch((error) => {
          this.handleResponseError(error);
        })
        .finally(() => {
          this.getUserShoppingListRequest.loading = false;
        });
    },
    getShoppingLists: function () {
      return this.axios.get(
        this.config.ecommerce_service_url +
          "/api/v1/users/" +
          this.user.id +
          "/channels/" +
          this.config.channel.id +
          "/shopping_lists?includes=shopping_list_items",
        {
          headers: {
            Authorization: "Bearer " + this.user.jwtToken,
            Accept: "application/json",
          },
        }
      );
    },
  },
  created() {
    this.getList(this.user.id);
  },
};
</script>

<style scoped>
</style>