<template>
  <v-dialog
    v-model="show"
    max-width="600"
    scrollable
    @click:outside="show = false"
    :disabled="postShoppingListRequest.loading"
  >
    <v-card>
      <v-card-title
        dense
        class="white--text customRaspBerry text-uppercase font-weight-bold elevation-0"
      >
        Aggiungi Lista Della Spesa
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-col cols="12" md="12">
          <ShoppinglistForm ref="addShoppingListForm"></ShoppinglistForm>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="customRaspBerry"
          text
          tile
          large
          @click="postShoppingList()"
          :loading="postShoppingListRequest.loading"
        >
          Crea lista della spesa
          <v-icon right dark> mdi-plus </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import ShoppinglistForm from "@/views/ecommerce/components/forms/ShoppingListForm";

export default {

  name: "ShoppingListCreate",
  components: { ShoppinglistForm },
  props: ['visible','products'],
  mixins: [StoreComputed, ResponseErrorHandler],
  data() {
    return {
      postShoppingListRequest: { loading: false },
    };
  },
  computed: {
    show: {
      get(){
        return this.visible
      },
      set(value){
        if(!value){
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    postShoppingList: function () {
      if (!this.$refs.addShoppingListForm.$refs.form.validate()) {
           this.$store.commit("snackbar/update", {
            show: true,
            text: "I campi nome e descrizione sono obbligatori.",
            color: "error",
          });
          return false;
      }

      var today = new Date();

      var dateOfShoppingList =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      let defaultShoppingList = {
        name: "lista:" + this.$refs.addShoppingListForm.shoppingtListRequest.name +" "+ dateOfShoppingList,
        description: this.$refs.addShoppingListForm.shoppingtListRequest.description,
      };
      this.axios
        .post(
          this.config.ecommerce_service_url +
            "/api/v1/users/" +
            this.user.id +
            "/channels/" +
            this.config.channel.id +
            "/shopping_lists",
          {
            data: defaultShoppingList,
          },
          {
            headers: {
              Authorization: "Bearer " + this.user.jwtToken,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.products.forEach((cart_item) => {
            console.log(cart_item);
            this.axios
              .post(
                this.config.ecommerce_service_url +
                  "/api/v1/users/" +
                  this.user.id +
                  "/channels/" +
                  this.config.channel.id +
                  "/shopping_lists/" +
                  response.data.data.shopping_list.id +
                  "/shopping_list_items",
                {
                  data: {
                    catalog_product_id: cart_item.catalog_product_id,
                    notes: "A note",
                    quantity: cart_item.quantity,
                    priority: 4,
                  },
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.user.jwtToken,
                    Accept: "application/json",
                  },
                }
              )
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                this.handleResponseError(error);
              })
              .finally(() => {});
          });
        })
        .catch((error) => {
          this.handleResponseError(error);
        })
        .finally(() => {
          this.$store.commit("snackbar/update", {
            show: true,
            text: "Lista del carrello generata correttamente",
            color: "success",
          });
        });
    },
  },
};
</script>

<style scoped></style>
