<template>
  <v-container fuild style="max-width: 1280px">
    <ShoppingListGenerator :visible="showShoppingListGenerator" :products="this.cart.cartItems"
                           @close="showShoppingListGenerator = false"></ShoppingListGenerator>
    <router-view></router-view>
    <v-row class="px-4 mb-5">
      <v-card class="pa-0 elevation-0">
        <h1
            class="roboto font-weight-thin text-uppercase border-bottom-text mr-3"
        >
          carrello
        </h1>
      </v-card>
      <v-col class="border-shadow pa-0"></v-col>
      <v-btn
          @click="showShoppingListGenerator = true"
          text
          :loading="deleteCartItemsRequest.loading"
          v-if="false && cart.cartItems.length > 0"
      >
        <v-icon left dark> mdi-basket</v-icon>
        CREA LISTA DELLA SPESA DA CARRELLO
      </v-btn>
      <v-btn
          @click="deleteCartItems"
          text
          :loading="deleteCartItemsRequest.loading"
          v-if="cart.cartItems.length > 0"
      >
        <v-icon left dark> mdi-delete</v-icon>
        SVUOTA CARRELLO
      </v-btn
      >
    </v-row>
    <template v-if="getCartItemsRequest.loading">
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <template v-if="unavailableCartItems.length > 0">
        <v-dialog
            v-model="showUnavailableCartItemsDialog"
            persistent
            max-width="500"
        >
          <v-card tile>
            <v-toolbar dense flat color="primary" dark>
              <v-icon class="mr-3">mdi-alert</v-icon>
              <v-toolbar-title>Attenzione</v-toolbar-title>
            </v-toolbar>
            <v-container>
              <v-row>
                <v-col>
                  I seguenti articoli non sono più disponibili, per tanto
                  verranno rimossi dal carrello
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    v-for="cartItem in unavailableCartItems"
                    :key="cartItem.id"
                    class="mb-5"
                >
                  <ProductRow v-bind:cartItemData="cartItem"/>
                </v-col>
              </v-row>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  @click="removeCartItemErrors"
                  :loading="removeCartItemProcerure.loading"
              >PROCEDI
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-else-if="notValidQuantityCartItems.length > 0">
        <v-dialog
            v-model="showNotValidQuantityCartItemsDialog"
            persistent
            max-width="500"
        >
          <v-card tile>
            <v-toolbar dense flat color="primary" dark>
              <v-icon class="mr-3">mdi-alert</v-icon>
              <v-toolbar-title>Attenzione</v-toolbar-title>
            </v-toolbar>
            <v-container>
              <v-row>
                <v-col>
                  Per i seguenti prodotti sono cambiate le condizioni di
                  vendita. Modificare la quantità nel carrello o rimuovere il
                  prodotto.
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    v-for="cartItem in notValidQuantityCartItems"
                    :key="cartItem.id"
                    class="mb-5"
                >
                  <ProductRow v-bind:cartItemData="cartItem"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </template>
      <template v-else-if="aboveMaximumQuantityOrStockQuantityCartItems.length > 0">
        <v-dialog
            v-model="showAboveMaximumQuantityOrStockQuantityCartItems"
            persistent
            max-width="500"
        >
          <v-card tile>
            <v-toolbar dense flat color="primary" dark>
              <v-icon class="mr-3">mdi-alert</v-icon>
              <v-toolbar-title>Attenzione</v-toolbar-title>
            </v-toolbar>
            <v-container>
              <v-row>
                <v-col>
                  Per i seguenti prodotti sono cambiate le condizioni di
                  vendita. Modificare la quantità nel carrello o rimuovere il
                  prodotto.
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    v-for="cartItem in aboveMaximumQuantityOrStockQuantityCartItems"
                    :key="cartItem.id"
                    class="mb-5"
                >
                  <ProductRow v-bind:cartItemData="cartItem"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </template>


      <template v-else>
        <template v-if="cart.cartItems.length > 0">
          <v-row>
            <v-col cols="12" md="8">
              <CartItem
                  class="mb-3"
                  v-for="(cartItem, index) in cart.cartItems"
                  :key="index"
                  v-bind:cartItemData="cartItem"
              ></CartItem>
            </v-col>
            <v-col cols="12" md="4">
              <v-card tile>
                <v-toolbar dense color="primary">
                  <v-toolbar-title>Carrello</v-toolbar-title>
                </v-toolbar>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-list two-line>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title> Totale Provvisorio</v-list-item-title>
                            <v-list-item-subtitle>
                              {{ $store.getters["cart/total"] }} € ({{ this.cart.cartItems.length }} Articoli)
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                      v-if="user.isAuthenticated"
                      tile
                      color="primary"
                      width="100%"
                      class="elevation-0 white--text"
                      @click="$router.push({ name: 'Checkout' })"
                  >
                    PROCEDI ALL'ORDINE
                  </v-btn>
                  <template v-else>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                              tile
                              color="primary"
                              width="100%"
                              class="elevation-0 white--text"
                              @click="$router.push({ name: 'SignUp' })"
                          >
                            REGISTRATI
                          </v-btn>
                        </v-col>
                        <v-col class="text-center middle-black-line pa-0" style="position:relative">
                          <v-btn class="elevation-0 white py-0" tile text>oppure</v-btn>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                              tile
                              color="primary"
                              width="100%"
                              class="elevation-0 white--text"
                              @click="$router.push({ name: 'Login' })"
                          >
                            ACCEDI
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col> Nessun prodotto nel carrello</v-col>
          </v-row>
        </template>
      </template>
    </template>
  </v-container>
</template>

<script>
import CartItem from "./components/CartItem";
import Product from "@/mixins/Product";
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import CartItemMixin from "@/mixins/CartItem";
import ProductRow from "./components/CartItemRow";
import ShoppingListGenerator from "@/views/ecommerce/cart/components/ShoppingListGenerator";


export default {
  name: "Index",
  components: {CartItem, ProductRow, ShoppingListGenerator},
  mixins: [Product, StoreComputed, ResponseErrorHandler, CartItemMixin],
  data: () => ({
    days: ['', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica'],
    selectedDeliveryOption: null,
    deliveryOptions: [],
    postcode: '',
    showShoppingListGenerator: false,
    checkOutCompletedDialog: {show: false},
    deleteCartItemRequest: {loading: false, error: false},
    deleteCartItemsRequest: {loading: false, error: false},
    postCheckOutRequest: {loading: false, error: false},
    getCartItemsRequest: {loading: false, error: false},
    removeCartItemProcerure: {loading: false, error: false},
    showUnavailableCartItemsDialog: false,
    showNotValidQuantityCartItemsDialog: false,
    showAboveMaximumQuantityOrStockQuantityCartItems:false,
  }),
  mounted() {
    this.getCartItems();
  },
  methods: {
    removeCartItemErrors: function () {
      this.removeCartItemProcerure.loading = true;
      this.unavailableCartItems.forEach((cartItem) => {
        this.removeCartItem(cartItem.id);
      });
      this.removeCartItemProcerure.loading = false;
    },
    removeCartItem: function (catItemId) {
      this.deleteCartItemRequest.loading = true;
      this.axios
          .delete(
              this.config.ecommerce_service_url +
              "/api/v1/channels/" +
              this.channel.id +
              "/cart_items/" +
              catItemId,
              {
                headers: {
                  Authorization: "Bearer " + this.user.jwtToken,
                  Accept: "application/json",
                },
              }
          )
          .then(() => {
            this.$store.commit("cart/removeCartItem", catItemId);
          })
          .catch((e) => {
            this.handleResponseError(e);
          })
          .finally(() => {
            this.deleteCartItemRequest.loading = false;
          });
    },
    deleteCartItems: function () {
      if (this.user.isAuthenticated) {
        this.deleteCartItemsRequest.loading = true;
        this.axios
            .delete(
                this.config.ecommerce_service_url +
                "/api/v1/channels/" +
                this.channel.id +
                "/cart_items",
                {
                  headers: {
                    Authorization: "Bearer " + this.user.jwtToken,
                    Accept: "application/json",
                  },
                }
            )
            .then(() => {
              this.$store.commit("cart/empty");
              this.$store.commit("snackbar/update", {
                show: true,
                color: "success",
                text: "Tutti i prodotti eliminati dal carrello con successo",
              });
            })
            .catch((e) => {
              this.handleResponseError(e);
            })
            .finally(() => {
              this.deleteCartItemsRequest.loading = false;
            });
      } else {
        this.$store.commit("cart/empty");
        localStorage.setItem('mercati-campagnamica-guest-cart', JSON.stringify([]));
        this.$store.commit("snackbar/update", {
          show: true,
          color: "success",
          text: "Tutti i prodotti eliminati dal carrello con successo",
        });
      }
    },
    getCartItems: function () {
      if (this.user.isAuthenticated) {
        this.getCartItemsRequest.loading = true;
        this.axios
            .get(
                this.config.ecommerce_service_url +
                "/api/v1/channels/" +
                this.channel.id +
                "/cart_items?includes=catalog_product,catalog_product.product,catalog_product.product.stock,catalog_product.product.business,catalog_product.product.images",
                {
                  headers: {
                    Authorization: "Bearer " + this.user.jwtToken,
                    Accept: "application/json",
                  },
                }
            )
            .then((res) => {
              let cartItems = [];
              res.data.data.forEach((cart_item) => {
                cartItems.push(cart_item.cart_item);
              });
              this.$store.commit("cart/setCartItems", cartItems);
            })
            .catch((error) => {
              this.handleResponseError(error);
            })
            .finally(() => {
              this.getCartItemsRequest.loading = false;
            });
      } else {
        let catalogProductsIds = this.cart.cartItems.map((cartItem) => {
          return cartItem.catalog_product_id;
        })
        if (catalogProductsIds.length > 0) {
          this.getCartItemsRequest.loading = true;
          this.axios
              .get(this.config.ecommerce_service_url +
                  "/api/v1/channels/" +
                  this.channel.id + '/products?catalog_product_id=' + catalogProductsIds.join(',') + '&includes=product.stock,product,product.business,product.images')
              .then(response => {
                let catalogProducts = response.data.data.map((catalogProduct) => {
                  return catalogProduct.catalog_product
                })
                let refreshedCartItems = this.cart.cartItems.map((cartItem) => {
                  let cartItemRelatedCatalogProduct = catalogProducts.find(catalogProduct => catalogProduct.id === cartItem.catalog_product_id)

                  if (cartItemRelatedCatalogProduct !== undefined) {
                    delete cartItem.catalog_product;
                    cartItem.catalog_product = cartItemRelatedCatalogProduct;
                    return cartItem
                  }
                })
                refreshedCartItems = refreshedCartItems.filter(val => val !== undefined)
                this.$store.commit("cart/setCartItems", refreshedCartItems);
              })
              .catch(error => {
                this.handleResponseError(error)
              }).finally(() => {
            this.getCartItemsRequest.loading = false;
          })
        }
        console.log(catalogProductsIds);
      }
    },
    postShoppingListItem: function () {
      var today = new Date();

      var dateOfShoppingList =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate();

      let defaultShoppingList = {
        name: "carrello del " + dateOfShoppingList,
        description: "lista generata dal carrello",
      };
      this.axios
          .post(
              this.config.ecommerce_service_url +
              "/api/v1/users/" +
              this.user.id +
              "/channels/" +
              this.config.channel.id +
              "/shopping_lists",
              {
                data: defaultShoppingList,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.user.jwtToken,
                  Accept: "application/json",
                },
              }
          )
          .then((response) => {
            this.cart.cartItems.forEach((cart_item) => {
              console.log(cart_item);
              this.axios
                  .post(
                      this.config.ecommerce_service_url +
                      "/api/v1/users/" +
                      this.user.id +
                      "/channels/" +
                      this.config.channel.id +
                      "/shopping_lists/" +
                      response.data.data.shopping_list.id +
                      "/shopping_list_items",
                      {
                        data: {
                          catalog_product_id: cart_item.catalog_product_id,
                          notes: "A note",
                          quantity: cart_item.quantity,
                          priority: 4,
                        },
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.user.jwtToken,
                          Accept: "application/json",
                        },
                      }
                  )
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((error) => {
                    this.handleResponseError(error);
                  })
                  .finally(() => {
                  });
            });
          })
          .catch((error) => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.$store.commit("snackbar/update", {
              show: true,
              text: "Lista del carrello generata correttamente",
              color: "success",
            });
          });
    },
    postDeliveryOptions: function () {
      this.axios
          .post(
              this.config.ecommerce_service_url +
              "/api/v1/channels/" +
              this.config.channel.id +
              "/available_delivery_options",
              {
                data: {zip_code: this.postcode, cart_items: this.cart.cartItems},
              },
              {
                headers: {
                  Authorization: "Bearer " + this.user.jwtToken,
                  Accept: "application/json",
                },
              }
          )
          .then(response => {
            this.deliveryOptions = response.data.data
          })
    }
  },
  computed: {
    unavailableCartItems: function () {
      return this._.filter(this.cart.cartItems, (cartItem) => {
        return this.isCartItemAvailable(cartItem);
      });
    },
    notValidQuantityCartItems: function () {
      return this._.filter(this.cart.cartItems, (cartItem) => {
        return !this.isCartItemQuantityValid(cartItem);
      });
    },
    aboveMaximumQuantityOrStockQuantityCartItems: function () {
      return this._.filter(this.cart.cartItems, (cartItem) => {
        return !this.isCartQuantityInStock(cartItem) || !this.isCartQuantityLessThenMaximumQuantity(cartItem)
      });
    },
  },
  watch: {
    unavailableCartItems: function (newNotAvailableCartItems) {
      this.showUnavailableCartItemsDialog = newNotAvailableCartItems.length > 0;
    },
    notValidQuantityCartItems: function (newNotValidQuantityCartItems) {
      this.showNotValidQuantityCartItemsDialog =
          newNotValidQuantityCartItems.length > 0;
    },
    aboveMaximumQuantityOrStockQuantityCartItems: function (newAboveMaximumQuantityOrStockQuantityCartItems) {
      this.showAboveMaximumQuantityOrStockQuantityCartItems =
          newAboveMaximumQuantityOrStockQuantityCartItems.length > 0;
    },
  },
};
</script>

<style scoped>
.middle-black-line::before {
  transform: none;
  border-top: 1px solid rgba(0, 0, 0, .12);
  background: rgba(0, 0, 0, .12);
}
</style>