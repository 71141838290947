let status = {
    methods: {
        getStatus: function () {
            return this.axios
                .get(this.config.ecommerce_service_url + '/api/v1/status', {
                    headers: {
                        Authorization: 'Bearer ' + this.user.jwtToken,
                        Accept: 'application/json'
                    }
                })
        },
    }
};
export default status