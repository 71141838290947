let user = {
    methods: {
        getUser: function (userId) {
            return this.axios
                .get(this.config.ecommerce_service_url + '/api/v1/users/' + userId + '?includes=role,business', {
                    headers: {
                        Authorization: 'Bearer ' + this.user.jwtToken,
                        Accept: 'application/json'
                    }
                })
        },
    }
};
export default user

