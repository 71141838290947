<template>
  <v-dialog
      :value="true"
      persistent
      max-width="360"
      scrollable
      @click:outside="$router.push({name:'ShoppingList'})"
      :disabled="deleteShoppingList.loading"
  >
    <v-card>
      <v-card-title dense class="white--text primary text-uppercase font-weight-bold elevation-0">
        Rimuovi
        <v-spacer></v-spacer>
        <v-btn icon @click="$router.push({name: 'ShoppingList'})">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        Stai procedendo alla rimozione di un lista della spesa, sei sicuro?
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text tile large @click="$router.push({name: 'ShoppingList'})"
               :loading="deleteShoppingList.loading">
          chiudi
          <v-icon right dark>
            mdi-close-thick
          </v-icon>
        </v-btn>
        <v-btn color="primary" text tile large @click="cutShoppingList()"
               :loading="deleteShoppingList.loading">
          rimuovi
          <v-icon right dark>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";

export default {
  name: "ShoppingListDelete",
  mixins: [StoreComputed, ResponseErrorHandler],
  data() {
    return {
      deleteShoppingList: {loading: false},
      dialog: true,
    };
  },
  computed:{
    listId(){
      return this.$route.params.listId;
    }
  },
  methods: {
      cutShoppingList: function () {
      this.deleteShoppingList.loading = true;
      this.axios.delete(
             this.config.ecommerce_service_url +
            "/api/v1/users/" +
            this.user.id +
            "/channels/" + this.config.channel.id + "/shopping_lists/" +
              this.listId,
              {
                headers: {
                  Authorization: "Bearer " + this.user.jwtToken,
                  Accept: "application/json",
                },
              }
          )
          .then(() => {
            this.$emit('shoppingList:deleted');
            this.$store.commit('snackbar/update', {
              show: true,
              text: 'La lista è stata rimossa con successo',
              color: 'success'
            })
            this.$router.push({name: "ShoppingList"});
          })
          .catch((error) => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.deleteShoppingList.loading = false;
          });
    },
  }
};
</script>

<style scoped></style>
