<template>
  <div class="d-flex white justify-space-between align-center" style="width:100%;height:55px;">

    <v-tabs
        centered align-with-title
        slider-color="black" color="black"
        class="Raleway"
        active-class="font-weight-black"
    >
      <v-spacer></v-spacer>
      <v-menu
          open-on-hover
          elevation="0"
          tile
          offset-y
          min-width="100%"
          v-for="(parentFilterableCategory,index) in $store.getters['commons/structuredFilterableCategories']"
          :key="index"
          content-class="elevation-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-tab
              v-bind="attrs"
              v-on="on"
              :key="index"
              :to="{name:'Category',params:{parentFilterableCategoryId:parentFilterableCategory.id}}"
              active-class="font-weight-black"
          >
            {{ parentFilterableCategory.name }}
          </v-tab>

        </template>

        <v-container fluid style="max-width:1280px" class="overflow-hidden light-grey">
          <v-row class="overflow-hidden mx-0 white">
            <template v-for="(childrenFilterableCategory, index) in parentFilterableCategory.childrens">
              <v-col cols="4"
                     :key="index"
                     class="white"
                     v-if="childrenFilterableCategory.products_count !== 0"
              >
                <v-btn
                    class="elevation-0 font-weight-bold"
                    style="font-size:0.6rem"
                    width="100%"
                    tile
                    active-class="font-weight-black"
                    :to="{name:'Category',params:{parentFilterableCategoryId:parentFilterableCategory.id,childrenFilterableCategoryId:childrenFilterableCategory.id}}">
                  {{ childrenFilterableCategory.name }}
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-menu>
      <v-spacer></v-spacer>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: "FilterableCategoryMenu"
}
</script>

<style scoped>

</style>