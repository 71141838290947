<template>
  <v-form ref="form" v-model="form">
    <v-container>
      <v-row class="text-center justify-center mt-5">
        <v-col cols="12">
          <v-text-field
              :loading="loading"
              :disabled="loading"
              dense
              v-model="user.first_name"
              label="Nome"
              :rules="[v => !!v || 'Inserire nome']"/>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :loading="loading"
              :disabled="loading"
              dense
              v-model="user.last_name"
              label="Cognome"
              :rules="[v => !!v || 'Inserire cognome']"/>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :loading="loading"
              :disabled="loading"
              dense
              v-model="user.email"
              label="Email"
              type="email"
              :rules="[value => !!value || 'Inserire indirizzo email', rules.email]"/>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :loading="loading"
              :disabled="loading"
              dense
              v-model="user.password"
              label="Password"
              type="password"
              :rules="[value => !!value || 'Inserire la password']"/>
        </v-col>
        <v-col cols="12">
          <div>
            DICHIARO DI AVER LETTO LE CONDIZIONI GENERALI DI VENDITA
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a
                    target="_blank"
                    href="/documenti/Avvertenze e condizioni specifiche di vendita, consegna e facoltà di recesso.pdf"
                    @click.stop
                    v-on="on"
                >
                  LINK
                </a>
              </template>
              Apri in nuova finestra
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12">
          <div>
            DICHIARO DI AVER LETTO L’INFORMATIVA  PRIVACY
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a
                    target="_blank"
                    href="/documenti/Informativa privacy.pdf"
                    @click.stop
                    v-on="on"
                >
                 LINK
                </a>
              </template>
              Apri in nuova finestra
            </v-tooltip>
          </div>
        </v-col>
        <v-col cols="12" v-if="false">
          <v-radio-group
              v-model="user.newsletter"
              row
          >
            <v-radio
                label="ACCONSENTO "
                value="true"
            ></v-radio>
            <v-radio
                label="NON ACCONSENTO"
                value="false"
            ></v-radio>
            <label>a ricevere la newsletter sulle attività istituzionali e sui servizi dedicati offerti da CA Servizi S.r.l.
              Qualora decidesse di non acconsentire, potrà comunque registrarsi al Portale</label>
          </v-radio-group>
        </v-col>

      </v-row>
      <v-row class="text-center justify-center">
        <v-col class="col-sm-3 col-xs-6">
          <CaptchaImage ref="captchaImg" v-on:createdCaptchaCode="getCaptchaCode"></CaptchaImage>
        </v-col>
        <v-col class="col-sm-5 col-xs-6">
          <v-text-field
              :disabled="loading"
              dense
              v-model="inputCaptcha"
              label="Digitare i caratteri"
              :rules="[value => !!value || 'Inserire il codice mostrato nell\'immagine',checkCaptcha]"
          ></v-text-field>
          <a @click="regenerateCapcha()">
            <v-icon dense color="primary">mdi-reload</v-icon>
            Nuovo codice
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import CaptchaImage from './CaptchaImage'

export default {
  name: 'SingUpForm',
  components: {CaptchaImage},
  props: ['loading'],
  data: () => ({
    user: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      newsletter: false,
    },
    form: true,
    rules: {
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Inserire indirizzo email valido'
      },
    },
    codeCaptcha: '',
    inputCaptcha: '',
  }),
  methods: {
    checkCaptcha: function (value) {
      return value === this.codeCaptcha || 'Codice di verifica errato'
    },
    getCaptchaCode: function (codice) {
      this.codeCaptcha = codice;
    },
    regenerateCapcha: function () {
      this.inputCaptcha = "";
      this.$refs.captchaImg.createCaptcha();
    },
  }
}
</script>
