<template>
  <v-container fuild style="max-width:1280px">
    <v-row>
      <v-card class="pa-0 pt-3 elevation-0">
        <h1 class="roboto font-weight-thin text-uppercase border-bottom-text mr-3">Registrazione</h1>
      </v-card>
      <v-col class="border-shadow pa-0"></v-col>
    </v-row>
    <v-row v-if="!signupCompleted">
      <v-col cols="auto" class="mx-auto">
        <v-card class="elevation-0" style="max-width: 800px;margin-bottom: 30px">
          <v-card-text>
            <SingUpForm ref="signupForm" :loading="postSignupRequest.loading"/>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="postSignup" class="font-weight-bold white--text" color="primary">
              Registrati
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="auto" class="mx-auto">
        <v-card class="elevation-0" style="max-width: 600px">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="auto">
                  <v-icon large color="success">mdi-check-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex">
                  <h1 class="mb-0 align-self-center">Registrazione Completata.</h1>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto">
                  <v-icon large color="warning">mdi-alert-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex">
                  <p class="mb-0 align-self-center">Il link per la conferma dell'e-mail è stato inviato all'indirizzo da lei inserito.</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import StoreComputed from '@/mixins/StoreComputed';
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import SingUpForm from './components/SingUpForm';

export default {
  name: 'SingUp',
  components: {SingUpForm},
  mixins: [StoreComputed, ResponseErrorHandler],
  data: () => ({
    postSignupRequest: {loading: false},
    signupCompleted: false
  }),
  methods: {
    postSignup: function () {
      if (this.$refs.signupForm.$refs.form.validate()) {
        this.postSignupRequest.loading = true;
        this.axios.post(
            this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/signup',
            {
              data: this.$refs.signupForm.user
            },
            {
              headers: {
                Accept: 'application/json'
              }
            }
        ).then(() => {
          this.signupCompleted = true;
        }).catch(error => {
          this.handleResponseError(error);
        }).finally(() => {
          this.postSignupRequest.loading = false;
        })
      }
    }
  }
}
</script>