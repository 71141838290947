<template>
  <v-container class="pt-0" fuild style="max-width:1280px">
    <v-row>
      <v-col class="px-0">
        <v-img
            :aspect-ratio="2.6/1"
            src="@/assets/images/sfondo-banner.png"
        >
          <v-overlay
              absolute opacity=0
              class="d-flex align-end justify-start"
          >
            <v-card class="transparent elevation-0">
              <v-container>
                <v-row>
                  <v-col class="px-14 mb-16 pb-16 text-uppercase Raleway font-weight-bold d-none d-sm-block" style="font-size:2rem;line-height: 1.25">
                    Un nuovo modo di fare<br> la spesa
                  </v-col>
                  <v-col class="px-14 mb-3 pb-3 text-uppercase Raleway font-weight-bold d-sm-none" style="font-size:1.0rem;line-height: 1.25">
                    Un nuovo <br> modo di fare<br> la spesa
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-overlay>
        </v-img>
      </v-col>
    </v-row>
    <v-row class="mb-5 justify-center">
      <h1 class="Raleway font-weight-thin text-center">I nostri prodotti</h1>
    </v-row>
    <v-row>
      <v-data-iterator
          :items="catalogProducts"
          item-key="id"
          :items-per-page="8"
          hide-default-footer
          :loading="getFilterableCategoriesProductRequest.loading"
          :page="pagination.current_page"
      >
        <template v-slot:default="{ items }">
          <v-row>
            <v-col v-for="(catalogProduct , index) in items" :key="index"
                  class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 px-4 pb-6 d-flex">
              <ProductCard :key="index"
                          :catalogProduct="catalogProduct"
                          :show-business="true"
              ></ProductCard>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-row>
    <v-row>
      <v-banner color="customGreen" width="100%" two-line dark @click="$router.push({name: 'Businesses'})">
        <v-row class="justify-center">
          <v-col cols="col-lg-3 col-md-3 col-sm-12 col-12 text-center">
            <v-btn depressed color="customGreen" width="auto" height="auto" @click="$router.push({name: 'Businesses'})">
              <v-avatar
                color="customGreen"
                size="150"
              >
                <v-img src="@/assets/images/icona-coltivatori.png" contain></v-img>
              </v-avatar>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="Raleway align-self-center text-uppercase white--text d-none d-sm-block">
            <v-row class="text-center">
              <v-col cols="12">
                <v-btn text @click="$router.push({name: 'Businesses'})" class="ma-0">
                  <h1 class="font-weight-light" style="letter-spacing:0.1em">incontra chi coltiva</h1>
                </v-btn>
                <div style="height:2px;width:100%;background-color:white" class="d-flex my-1"></div>
                <v-btn text @click="$router.push({name: 'Businesses'})" class="ma-0">
                  <h4 class="font-weight-light" style="letter-spacing:0.1em">scopri i nostri produttori</h4>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" class="Raleway align-self-center text-uppercase white--text d-sm-none">
            <v-row class="text-center">
              <v-col cols="12" class="text-center">
                <v-btn text @click="$router.push({name: 'Businesses'})" class="ma-0 text-left">
                  <h1 class="font-weight-light" style="font-size:1.3em;letter-spacing:0.1em">incontra chi coltiva</h1>
                </v-btn>
                <div style="height:2px;width:100%;background-color:white" class="d-flex my-1"></div>
                <v-btn text @click="$router.push({name: 'Businesses'})" class="ma-0">
                  <h4 class="font-weight-light" style="font-size:0.9em;letter-spacing:0.1em">scopri i nostri produttori</h4>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-banner>
      <v-col v-for="(business , index) in businesses" :key="index"
                  class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 px-3 pb-6 d-flex">
        <BusinessCard v-bind:businessData="business"></BusinessCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-img  src="@/assets/images/banner_PSR.jpg"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BusinessCard from "../components/BusinessCard";
import StoreComputed from "@/mixins/StoreComputed";
import computedMixin from "../../../mixins/StoreComputed";
import responseErrorHandler from "../../../mixins/ResponseErrorHandler";
import ProductCard from "@/views/ecommerce/components/product/ProductCard";

export default {
  name: "Home",
  components: {BusinessCard, ProductCard},
  mixins: [StoreComputed, computedMixin, responseErrorHandler],
  data: () => ({
    businessesIncludes: ['business_type', 'province', 'province.region', 'media'],
    viewFilters: false,
    filters: {aliases: [], provinceIds: [], filterableCategoryIds: []},
    businesses: [],
    pagination: {total: null, last_page: null, current_page: null},getBusinessesRequest: {loading: true},
    randomNumber: 1,
    includes: ['product.business', 'product.images', 'product.certifications', 'product.specification_sheet','product.stock'],
    getFilterableCategoriesProductRequest: {loading: false, error: false},
    catalogProducts: [],
  }),
  methods: {
    setFilterableCategoryProducts(limit, page) {
      this.getFilterableCategoriesProductRequest.loading = true;
      this.getFilterableCategoryProducts(limit, page).then(res => {
        this.catalogProducts = [];
        res.data.data.forEach(catalogProduct => {
          this.catalogProducts.push(catalogProduct.catalog_product)
        });
        let meta = res.data.meta;
        this.pagination = {total: meta.total, current_page: meta.current_page, last_page: meta.last_page}
      }).catch(error => {
        this.handleResponseError(error);
      }).finally(() => {
        this.getFilterableCategoriesProductRequest.loading = false;
      });
    },
    getFilterableCategoryProducts(limit, page) {
      return this.axios.get(
          this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/products',
          {
            params: {
              limit: limit,
              page: page,
              includes: this.includes.join(',')
            },
            headers: {
              Authorization: 'Bearer ' + this.user.jwtToken,
              Accept: 'application/json'
            }
          }
      )
    },
    setBusinesses() {
      this.getBusinessesRequest.loading = true;
      this.businesses = [];
      this.getBusinesses()
          .then(res => {
            let businesses = res.data.data;
            businesses.forEach(business => {
              if(this.businesses.length < 4){
                this.businesses.push(business.business);
              }
            });
            let meta = res.data.meta;
            this.pagination = {total: meta.total, current_page: meta.current_page, last_page: meta.last_page}
          })
          .catch(error => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.getBusinessesRequest.loading = false;
          });
    },
    getBusinesses: function () {
      return this.axios.get(
          this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/businesses',
          {
            params: {
              includes: this.businessesIncludes.join(','),
              random_sorting: this.randomNumber
            },
            headers: {
              Authorization: 'Bearer ' + this.user.jwtToken,
              Accept: 'application/json'
            }
          }
      )
    }
  },
  mounted() {
    this.randomNumber = Math.floor(Math.random() * 10000);
    this.setBusinesses();
    this.setFilterableCategoryProducts(6, 1);
  },
  watch: {
    filteredBusinesses: function () {
      this.pagination.current_page = 1;
    }
  },
  computed: {
    pages: function () {
      let pages = [];
      for (let i = 1; i <= this.numberOfPages; i++) {
        pages.push(i)
      }
      return pages;
    },
    selectableBusinessAlias: function () {
      return this._.map(this.businesses, (business) => {
        return {text: business.alias, value: business.id};
      });
    },
    selectableBusinessProvince: function () {
      return this._.map(this.businesses, (business) => {
        return {text: business.province.name, value: business.province.id}
      });
    },
    selectableBusinessFilterableCategories: function () {
      let filterableCategories = [];
      this.businesses.forEach(business => {
        business.filterable_categories.forEach(filterableCategory => {
          filterableCategories.push({text: filterableCategory.name, value: filterableCategory.id})
        })
      });
      return this._.uniqBy(filterableCategories, 'value');
    },
    filteredBusinesses: function () {
      let filteredBusinesses = this.businesses;
      let self = this;
      if (self.filters.aliases.length !== 0) {
        filteredBusinesses = this._.filter(filteredBusinesses, function (business) {
          return self.filters.aliases.indexOf(business.id) !== -1;
        });
      }
      if (self.filters.provinceIds.length !== 0) {
        filteredBusinesses = this._.filter(filteredBusinesses, function (business) {
          return self.filters.provinceIds.indexOf(business.province.id) !== -1;
        });
      }
      if (self.filters.filterableCategoryIds.length !== 0) {
        filteredBusinesses = this._.filter(filteredBusinesses, function (business) {
          let businessFilterableCategoriesId = [];
          business.filterable_categories.forEach(filterableCategory => {
            businessFilterableCategoriesId.push(filterableCategory.id)
          });
          return self.filters.filterableCategoryIds.some(r => businessFilterableCategoriesId.indexOf(r) >= 0);
        });
      }
      return filteredBusinesses;
    },
    numberOfPages() {
      return Math.ceil(this.filteredBusinesses.length / 12)
    },
    filterableCategoryParam: function () {
      let filterableCategoryParam = [];
      if (this.$route.params.childrenFilterableCategoryId !== undefined) {
        filterableCategoryParam.push(this.$route.params.childrenFilterableCategoryId);
      } else {
        filterableCategoryParam = this._.map(this.$store.getters['commons/childrenFilterableCategories'](this.$route.params.parentFilterableCategoryId), 'id');
      }
      return filterableCategoryParam;
    }
  }
}
</script>
<style>
  .middle-black-line::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 2px solid black;
    background: black;
    width: 100%;
    transform: translateY(-50%);
  }

  div.v-banner__wrapper{
    padding: 16px!important;
  }
</style>