<template>
  <v-card flat class="elevation-0 mx-0 my-0 border-bottom business-card d-flex align-stretch" tile>
    <product-dialog :catalogProduct="catalogProduct" :dialog="dialog" @close-dialog="dialog = false"></product-dialog>
    <v-container no-gutters class="d-flex align-start flex-wrap pa-0">
      <v-row class="d-flex align-self-start" style="width:100%">
        <v-col @click="toggleProductDialog" cols="12">
          <template v-if="catalogProduct.product.images.length !== 0">
            <v-img
                contain
                sizes="10vw"
                min-width="100%"
                :src="catalogProduct.product.images[0].media.url"
                :srcset="catalogProduct.product.images[0].media.srcset"
                :aspect-ratio="1"
            ></v-img>
          </template>
          <template v-else>
            <v-carousel-item>
              <v-img
                  src="@/assets/placeholders/default-placeholder.jpeg"
                  cover
                  min-width="100%"
                  :aspect-ratio="1"
              ></v-img>
            </v-carousel-item>
          </template>
        </v-col>
        <v-col cols="12">
          <h3 class="product-card-description">{{ catalogProduct.product.name }}</h3>
          <p class="mb-0">{{ getProductSellingDescription(catalogProduct.product) }}</p>
          <p class="font-weight-bold mb-0">{{ getProductPrice(catalogProduct.product, catalogProduct, true) }}</p>
        </v-col>
      </v-row>
      <CartQuantityModifier v-bind:catalogProductData="catalogProduct"></CartQuantityModifier>
      <v-row v-if="showBusiness" class="d-flex align-self-start" style="width:100%">
        <v-col cols="12" class="py-0">
          <a class="roboto font-weight-black customRaspBerry--text subtitle-2"
             @click="$router.push({name:'Business', params:{id :catalogProduct.product.business.id}})">{{
              catalogProduct.product.business.name
            }}</a>
        </v-col>
      </v-row>
      <v-row class="d-flex align-self-end" style="width:100%">
        <v-col>
          <div class="d-flex align-center">
            <div style="height:2px;width:calc(100% - 20px) ;background-color: black" class="d-flex">
            </div>
            <div class="d-flex align-center justify-center ml-3" style="height:10px;width:10px;position:relative">
              <div style="height:100%;width:2px ;background-color: black;" class="d-flex">
              </div>
              <div style="width:100%;height:2px ;background-color: black;position: absolute" class="d-flex">
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>

</template>

<script>
import Product from "@/mixins/Product";
import ProductDialog from "@/views/ecommerce/components/product/ProductDialog";
import CartQuantityModifier from "@/views/ecommerce/components/product/CartQuantityModifier";

export default {
  data: () => ({
    dialog: false
  }),
  methods: {
    toggleProductDialog() {
      this.dialog = !this.dialog;
    },
  },
  name: "ProductCard",
  components: {ProductDialog, CartQuantityModifier},
  props: ['catalogProduct','showBusiness'],
  mixins: [Product],
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.border-bottom-canvas {

  height: 20px
}

.product-card-description {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.75rem;
  line-height: 1.375rem;
}
</style>