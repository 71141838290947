<template>
  <v-container fuild style="max-width:1280px">
    <template v-if="getBusinessRequest.loading">
      <v-row>
        <v-col class="mt-10 text-center">
          <v-progress-circular indeterminate color="customGreen"></v-progress-circular>
        </v-col>
      </v-row>
    </template>
    <template v-if="business !== null">
      <BusinessRecap :business="business"/>
      <BusinessProducts v-if="business !== null" :business="business"/>
    </template>
    <template v-else-if="business === null && !getBusinessRequest.loading">
      <v-row>
        <v-col class="text-center">
          Impossibile recuperare i dati dell'azienda
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import BusinessRecap from "./components/BusinessRecap";
import BusinessProducts from "./components/BusinessProducts.vue";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";

export default {
  name: "Business",
  mixins: [StoreComputed, ResponseErrorHandler],
  components: {BusinessRecap, BusinessProducts},
  data: () => ({
    businessIncludes: ['business_type', 'province', 'province.region', 'address', 'media'],
    getBusinessRequest: {loading: false},
    business: null,
  }),
  computed: {
    businessId() {
      return this.$route.params.id;
    }
  },
  methods: {
    setBusiness(businessId) {
      this.getBusinessRequest.loading = true;
      this.getBusiness(businessId)
          .then(response => {
            this.business = response.data.data.business;
          })
          .catch(error => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.getBusinessRequest.loading = false;
          })
    },
    getBusiness: function (businessId) {
       return this.axios.get(this.config.ecommerce_service_url + '/api/v1/channels/'+this.channel.id+'/businesses/' + businessId,
          {
            params: {includes: this.businessIncludes.join(',')},
            headers: {
              Accept: 'application/json'
            }
          })
    },
  },
  created() {
    this.setBusiness(this.businessId);
  }
}
</script>

<style scoped>
.middle-black-line::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 2px solid black;
  background: black;
  width: 100%;
  transform: translateY(-50%);
}
</style>