<template>
  <v-container fluid class="pa-0"> 
    <v-row class="pa-0">
      <v-col cols="12" class="pa-0">
        <v-img v-if="business.media.length !== 0" :aspect-ratio="2.96" :src="business.media[0].media.url"></v-img>
        <v-img v-else src="@/assets/placeholders/default-placeholder.jpeg" :aspect-ratio="2.96"></v-img>
      </v-col>
    </v-row>
    <v-row class="px-10 customGreen white--text">
      <v-col cols="12" class="py-6">
        <h1 class="text-uppercase Raleway font-weight-light">INCONTRA CHI COLTIVA</h1>
      </v-col>
    </v-row>
     <v-row class="px-10 customGreen white--text Raleway">
      <v-col class="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-12 pt-0">
        <p class="ma-0"><v-icon color="white" class="mr-2">mdi-account</v-icon>{{business.name}}</p>
      </v-col>
      <v-col class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12 pt-0" v-if="business.email1 != null">
        <p class="ma-0">
          <v-icon color="white" class="mr-2">mdi-email-outline</v-icon>
          <a :href="'mailto:' + business.email1" class="white--text" style="text-decoration:none">{{ business.email1 }}</a>
        </p>
      </v-col>
      <v-col class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 pt-0" v-if="business.phone1 != null">
        <p class="ma-0">
          <v-icon color="white" class="mr-2">mdi-phone</v-icon>
          <a :href="'tel:' + business.phone1" class="white--text" style="text-decoration:none">{{ business.phone1 }}</a>
        </p>
      </v-col>
      <v-col class="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 pt-0" v-if="business.web_url != null">
        <p class="ma-0">
          <v-icon color="white" class="mr-2">mdi-earth</v-icon>
          <a :href="business.web_url" class="white--text" style="text-decoration:none">{{business.web_url}}</a>
        </p>
      </v-col>
      <v-col v-if="business.address != null" class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 pt-0">
        <p class="ma-0">
          <v-icon color="white">mdi-map-marker</v-icon>
          {{ business.address.street_name }} {{ business.address.street_number }}, {{ business.address.postcode }} {{ business.address.city }} ({{ business.address.district }})
        </p>
      </v-col>
    </v-row>
    <v-row class="px-10 customGreen white--text Raleway">
      <v-col cols="12" v-if="business.description != null">
        <p style="font-size:small;">{{ business.description }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StoreComputed from '@/mixins/StoreComputed';

export default {
  name: 'BusinessRecap',
  mixins: [StoreComputed],
  data: () => ({
    getBusinessRequest: {loading: false},
    isMobile: false,
  }),
  props: {
    business: Object,
  },
  watch: {
    isMobile: function (isMobile) {
      if (!isMobile && this.showMobileSearch) {
        this.showMobileSearch = false;
      }
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 960;
      this.showMobileMenu = window.innerWidth < 1130;
    },
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, {passive: true})
    }
  },
  mounted() {
    this.onResize();
  }
}
</script>

<style scoped>

</style>