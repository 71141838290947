var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{class:{ card: true , default: _vm.address.default},attrs:{"outlined":"","height":"100%"}},[_c('v-responsive',{staticClass:"d-flex",attrs:{"aspect-ratio":1}},[_c('v-card-title',{staticClass:"px-3 align-self-end",attrs:{"dense":"","flat":""}},[(_vm.address.default)?_c('v-btn',{staticClass:"elevation-0",attrs:{"dark":"","color":"primary","x-small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-star ")]),_vm._v(" Predefinito ")],1):_c('v-btn',{attrs:{"plain":"","text":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.$emit('address:setDefault',_vm.address.id)}}},[_vm._v(" Imposta Come Predefinito ")])],1),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"headline mb-4"},[_vm._v(" "+_vm._s(_vm.address.addressee)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.address.street_name)+" "+_vm._s(_vm.address.street_number)+", "+_vm._s(_vm.address.postcode)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.address.city)+" , "+_vm._s(_vm.address.district)+" ")])],1)],1),_c('v-card-actions',{staticClass:"card-actions px-3"},[_c('v-btn',{attrs:{"color":"primary","x-small":"","outlined":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'AccountAddressesEdit',
                  params: { addressId: _vm.address.id },
                })}}},[_vm._v(" Modifica ")]),_c('v-btn',{attrs:{"outlined":"","color":"primary","x-small":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'AccountAddressesDelete',
                  params: { addressId: _vm.address.id },
                })}}},[_vm._v(" Rimuovi ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }