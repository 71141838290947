<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-card tile max-width="600">
          <v-card-title class="roboto font-weight-medium primary">
            Completamento Pagamento
          </v-card-title>
          <v-card-text class="text-center pa-3">
            <template v-if="!orderCompleted">
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
              <p class="roboto font-weight-medium pt-3">Completamento pagamento in corso</p>
            </template>
            <template v-else>
              <v-icon color="success" x-large>mdi-check</v-icon>
              <p class="roboto font-weight-medium pt-3">Pagamento Completato</p>
              <v-btn @click="$router.push({name:'AccountOrders'})" color="primary" tile>Vai agli ordini</v-btn>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import computedMixin from "@/mixins/StoreComputed";
import responseErrorHandler from "@/mixins/ResponseErrorHandler";

export default {
  name: "CompletePayment",
  mixins: [computedMixin, responseErrorHandler],
  data: () => ({
    postPaypalOrderCaptureRequest: {loading: true},
    orderCompleted: false,
  }),
  methods: {
    postPaypalOrderCapture: function () {
      this.axios.post(this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/paypal_order_capture', {
            data: {
              paypal_order_id: this.$route.query.token
            }
          },
          {
            headers: {
              Authorization: 'Bearer ' + this.user.jwtToken,
              Accept: 'application/json'
            }
          }
      ).then(response => {
        this.orderCompleted = true;
      }).catch(error => {
        this.handleResponseError(error);
      }).finally(() => {
        this.postPaypalOrderCaptureRequest = false;
      })
    }
  },
  created() {
    this.postPaypalOrderCapture()
  }
}
</script>

<style scoped>

</style>