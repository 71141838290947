<template>
  <v-dialog
      :value="true"
      persistent
      max-width="1024"
      scrollable
      @click:outside="$router.push({name:'AccountAddressesList'})"
      :disabled="putUserAddressRequest.loading"
  >
    <v-card :loading="getUserAddressRequest.loading">
      <v-card-title dense class="white--text primary text-uppercase font-weight-bold elevation-0">
        Modifica indirizzo
        <v-spacer></v-spacer>
        <v-btn icon @click="$router.push({name: 'AccountAddresses'})">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        <SpippingAddressForm ref="editAddressForm" v-if="!getUserAddressRequest.loading"
                             :loading="putUserAddressRequest.loading || getUserAddressRequest.loading"
                             :addressData="address"></SpippingAddressForm>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text tile large @click="updateUserAddress"
               :loading="putUserAddressRequest.loading">
          Salva
          <v-icon right dark>
            mdi-content-save
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import SpippingAddressForm from "@/views/ecommerce/components/forms/ShippingAddressForm";

export default {
  name: "AccountAddressesEdit",
  components: {SpippingAddressForm},
  mixins: [StoreComputed, ResponseErrorHandler],
  data() {
    return {
      putUserAddressRequest: {loading: false},
      getUserAddressRequest: {loading: false},
      address: {},
      addressIndexToUpdate: []
    };
  },
  methods: {
    updateUserAddress() {
      if (this.$refs.editAddressForm.$refs.form.validate()) {
        this.putUserAddressRequest.loading = true;
        this.putUserAddress(this.user.id, this.$route.params.addressId, this.$refs.editAddressForm.address).then(response => {
          this.$emit('address:updated', response.data.data.address);
          this.$store.commit('snackbar/update', {
            show: true,
            text: 'Indirizzo modificato con successo',
            color: 'success'
          })
          this.$router.push({name: 'AccountAddressesList'})
        }).catch(error => {
          this.handleResponseError(error);
        }).finally(() => {
          this.putUserAddressRequest.loading = false;
        });
      }
    },
    getUserAddress(userId, addressId) {
      return this.axios.get(this.config.ecommerce_service_url +
          "/api/v1/users/" +
          userId +
          "/addresses/" +
          addressId,
          {
            headers: {
              Authorization: "Bearer " + this.user.jwtToken,
              Accept: "application/json",
            },
          })
    },
    putUserAddress: function (userId, addressId, address) {
      return this.axios
          .put(
              this.config.ecommerce_service_url +
              "/api/v1/users/" +
              userId +
              "/addresses/" +
              addressId,
              {
                data: address,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.user.jwtToken,
                  Accept: "application/json",
                },
              }
          )
    }
  },
  mounted() {
    this.getUserAddressRequest.loading = true;
    this.getUserAddress(this.user.id, this.$route.params.addressId)
        .then((response) => {
          console.log(response.data.data.address);
          this.address = response.data.data.address;
        })
        .catch((error) => {
          this.handleResponseError(error)
        })
        .finally(() => {
          this.getUserAddressRequest.loading = false;
        });
  }
};
</script>

<style scoped></style>
