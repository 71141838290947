<template>
  <v-container fuild style="max-width:1280px">
    <v-row>
      <v-card class="pa-0 pt-3 elevation-0">
        <h1 class="roboto font-weight-thin text-uppercase border-bottom-text mr-3">Password Dimenticata</h1>
      </v-card>
      <v-col class="border-shadow pa-0"></v-col>
    </v-row>
    <v-row v-if="!requestResetPasswordEmailCompleted">
      <v-col cols="auto" class="mx-auto">
        <v-card class="elevation-0" style="max-width: 800px;margin-bottom: 30px">
          <v-card-text>
            <ForgotPasswordForm ref="forgotPasswordForm" :loading="postRequestResetPasswordEmailRequest.loading"/>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="postRequestResetPasswordEmail" class="font-weight-bold white--text" color="primary">
              Richiedi Reset Password
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="auto" class="mx-auto">
        <v-card class="elevation-0" style="max-width: 700px">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="auto">
                  <v-icon large color="success">mdi-check-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex">
                  <h1 class="mb-0 align-self-center">Richiesta Reset Password Completata.</h1>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto">
                  <v-icon large color="warning">mdi-alert-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex">
                  <p class="mb-0 align-self-center">Il link per il reset della password è stato inviato all'indirizzo
                    da lei inserito.</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import ForgotPasswordForm from "@/views/ecommerce/forgot_password/Components/ForgotPasswordForm";

export default {
  name: "ForgotPassword",
  mixins: [StoreComputed, ResponseErrorHandler],
  components: {ForgotPasswordForm},
  data: () => ({
    postRequestResetPasswordEmailRequest: {loading: false},
    requestResetPasswordEmailCompleted: false
  }),
  methods: {
    postRequestResetPasswordEmail: function () {
      if (this.$refs.forgotPasswordForm.$refs.form.validate()) {
        this.postRequestResetPasswordEmailRequest.loading = true;
        this.axios.post(
            this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/request_reset_password_email',
            {
              data: this.$refs.forgotPasswordForm.data
            },
            {
              headers: {
                Accept: 'application/json'
              }
            }
        ).then(() => {
          this.requestResetPasswordEmailCompleted = true;
        }).catch(error => {
          this.handleResponseError(error);
        }).finally(() => {
          this.postRequestResetPasswordEmailRequest.loading = false;
        })
      }
    }
  }
}
</script>

<style scoped>

</style>