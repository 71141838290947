<template>
  <v-dialog
      :value="true"
      persistent
      max-width="360"
      scrollable
      @click:outside="$router.push({name:'AccountAddressesList'})"
      :disabled="deleteUserAddressRequest.loading"
  >
    <v-card>
      <v-card-title dense class="white--text primary text-uppercase font-weight-bold elevation-0">
        Rimuovi indirizzo
        <v-spacer></v-spacer>
        <v-btn icon @click="$router.push({name: 'AccountAddresses'})">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        Stai procedendo alla rimozione di un indirizzo, sei sicuro?
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text tile large @click="$router.push({name: 'AccountAddresses'})"
               :loading="deleteUserAddressRequest.loading">
          chiudi
          <v-icon right dark>
            mdi-close-thick
          </v-icon>
        </v-btn>
        <v-btn color="primary" text tile large @click="deleteUserAddress()"
               :loading="deleteUserAddressRequest.loading">
          rimuovi
          <v-icon right dark>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";

export default {
  name: "AccountAddressesDelete",
  mixins: [StoreComputed, ResponseErrorHandler],
  data() {
    return {
      deleteUserAddressRequest: {loading: false},
      createAddressDialog: false,
      dialog: true,
    };
  },
  computed:{
    addressId(){
      return this.$route.params.addressId;
    }
  },
  methods: {
    deleteUserAddress: function () {
      this.deleteUserAddressRequest.loading = true;
      this.axios
          .delete(
              this.config.ecommerce_service_url +
              "/api/v1/users/" +
              this.user.id +
              "/addresses/" +
              this.addressId,
              {
                headers: {
                  Authorization: "Bearer " + this.user.jwtToken,
                  Accept: "application/json",
                },
              }
          )
          .then(() => {
            this.$emit('address:deleted', this.addressId);
            this.$store.commit('snackbar/update', {
              show: true,
              text: 'Indirizzo rimosso con successo',
              color: 'success'
            })
            this.$router.push({name: "AccountAddresses"});
          })
          .catch((error) => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.deleteUserAddressRequest.loading = false;
          });
    },
  }
};
</script>

<style scoped></style>
