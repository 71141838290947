let provinces = {
    methods: {
        getProvinces: function () {
            return this.axios
                .get(this.config.ecommerce_service_url + "/api/v1/channels/" + this.channel.id + "/provinces", {
                    headers: {
                        //Authorization: "Bearer " + this.user.jwtToken,
                        Accept: "application/json",
                    },
                })
        },
    }
};
export default provinces