<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-snackbar v-model="snackbar.show" top tile
          :color="snackbar.color"
          :timeout="3000"
        >
          {{ snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn v-bind="attrs" @click="snackbar.show = false"
              :color="snackbar.color" fab depressed x-small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <v-row align="center" justify="center">
          <v-col cols="12" lg="5" sm="7" xs="12">
            <v-card class="mx-auto elevation-12">
              <v-toolbar flat color="customBlue">
                <v-toolbar-title class="font-weight-bold white--text">LOGIN</v-toolbar-title>
              </v-toolbar>

              <v-form ref="form" v-model="form" @submit.prevent="login">
                <v-card-text>
                  <v-text-field
                      v-model="email" name="email"
                      label="email" type="email"
                      prepend-icon="mdi-at" :disabled="loading"
                      :rules="[value => !!value || 'Inserire l\'email', rules.email]"
                      required
                  />
                  <v-text-field
                      v-model="password" name="password"
                      label="password" type="password"
                      prepend-icon="mdi-lock" :disabled="loading"
                      :rules="[value => !!value || 'Inserire la password']"
                      required
                  />
                  <p class="mb-0 align-self-center">Password Dimenticata? Clicca <router-link :to="{ name: 'ForgotPassword'}">qui</router-link></p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="font-weight-bold" color="customGreen" width="100%" type="submit">
                    Accedi
                  </v-btn>
                </v-card-actions>
              </v-form>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import StoreComputed from '@/mixins/StoreComputed';
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";

export default {
  name: "Login",
  data() {
    return {
      valid: true,
      form: true,
      email: "",
      password: "",
      loading: false,
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Inserire indirizzo email valido'
        },
      },
    }
  },
  mixins: [StoreComputed, ResponseErrorHandler],
  methods: {
    validateForm() {
      this.$refs.form.validate();
    },
    login() {
      this.validateForm();
      if (this.form) {
        this.loading = true;
        this.axios.post(
            this.config.ecommerce_service_url + '/api/v1/login',
            {"data": {"email": this.email, "password": this.password}},
            {
              headers: {
                Accept: 'application/json'
              }
            }
        )
            .then(data => {
              this.loading = false;
              localStorage.setItem('ecommerce-coldiretti-access-token', data.data.data.access_token);
              this.$router.push({name: 'Home'})
                  .then(() => console.log("Successfully Logged In"))
                  .catch(e => console.log(e));
            })
            .catch(error => {
              if (error.response.status === 401) {
                this.$store.commit('snackbar/update', {
                  show: true, text: 'Credenziali non valide', color: 'error'
                });
              } else {
                this.handleResponseError(error);
              }
              this.$refs.form.reset();
            })
            .finally(() => {
              this.loading = false;
            });
      }
    },
  }
}
</script>

<style scoped>

</style> 