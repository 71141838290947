<template>
  <v-card class="elevation-0 mx-0 my-0 border-bottom business-card d-flex align-stretch" tile
          @click="$router.push({name:'Business',params: { id: businessData.id }})">
    <v-container no-gutters style="flex-wrap: nowrap;" class="d-flex align-start flex-wrap pa-0">
      <v-row class="d-flex align-self-start" style="width:100%">
        <v-col cols="12">
          <v-img v-if="businessData.media.length !== 0" max-width="100%" aspect-ratio="1" cover
                 :src="businessData.media[0].media.url"></v-img>
          <v-img v-else max-width="100%" aspect-ratio="1" cover
                 src="@/assets/placeholders/default-placeholder.jpeg"></v-img>
          <h5 class="mt-5 mb-0 Raleway text-uppercase business-card-alias">{{ businessData.alias }}</h5>
          <template v-for="(filterableCategory ,index) in businessData.filterable_categories.slice(0, 5)">
            <template v-if="index > 0 && index < businessData.filterable_categories.slice(0, 5).length -1 "
            >,&nbsp;</template>
            <template v-else-if="index > 0 && index < businessData.filterable_categories.slice(0, 5).length"
            >&nbsp;&amp;&nbsp;</template>
            <router-link
                class="Raleway font-weight-regular black--text"
                :z-index="1"
                :to="'/category/'+filterableCategory.parent_filterable_category_id+'/'+filterableCategory.id"
                :key="index"
            >{{ filterableCategory.name }}</router-link>
          </template>
        </v-col>
      </v-row>
      <v-row class="d-flex align-self-end" style="width:100%">
        <v-col class="py-0">
          <div class="d-flex align-center" >
            <div style="height:2px;width:calc(100% - 20px) ;background-color: black" class="d-flex">
            </div>
            <div class="d-flex align-center justify-center ml-3" style="height:10px;width:10px;position:relative">
              <div style="height:100%;width:2px ;background-color: black;" class="d-flex">
              </div>
              <div style="width:100%;height:2px ;background-color: black;position: absolute" class="d-flex">
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "BusinessCard",
  props: ['businessData']
}
</script>

<style>
.business-card {
  width: 100%
}

.business-card-alias {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.75rem;
  line-height: 1.375rem;
}
</style>
