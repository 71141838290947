<template>
  <v-dialog
      :value="true"
      persistent
      @click:outside="$router.push({name:'AccountOrders'})"
      max-width="1024"
      scrollable
  >
    <v-card tile>
      <v-card-title class="primary white--text">
        <v-icon large left color="white">
          mdi-information
        </v-icon>
        <span class="title font-weight-black">Dettaglio ordine #{{ $route.params.id }}</span>
        <v-spacer></v-spacer>
        <v-btn v-if="!getOrderGroupDetailRequest.loading"
          :color="$store.getters['commons/orderGroupColor'](orderGroup.order_group_status.id).color"
          label class="text-uppercase"
          @click="orderStatusAction"
        >
          {{ orderGroup.order_group_status.label }}
        </v-btn>
      </v-card-title>
      <v-card-title class="text-right primary white--text mb-5 mt-n4 text-subtitle-1 font-weight-bold" v-if="orderTransactionText">
        <v-spacer></v-spacer>
        <span>{{orderTransactionText}}</span>
      </v-card-title>
      <v-card-text class="px-0">
        <v-container fluid v-if="orderGroup !== null">
          <v-row>
            <v-col cols="12" sm="6">
              <v-card tile class="elevation-0">
                <v-card-title class="primary white--text">
                  Indirizzo Di Fatturazione:
                </v-card-title>
                <v-card-text class="px-0">
                  <v-list class="white" dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Nome</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-black">
                          {{ orderGroup.billing_address.addressee }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Indirizzo</v-list-item-subtitle>
                        <v-list-item-title>
                          {{ orderGroup.billing_address.street_name }}
                          {{ orderGroup.billing_address.street_number }},<br>
                          <template v-if="orderGroup.billing_address.apartment !== null">
                            {{ orderGroup.billing_address.apartment + ', ' }}<br>
                          </template>
                          {{ orderGroup.billing_address.city }},
                          {{ orderGroup.billing_address.district }}
                          {{ orderGroup.billing_address.postcode }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="orderGroup.billing_address.vat_number !== null">
                      <v-list-item-content>
                        <v-list-item-subtitle>Partita Iva</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">
                          {{ orderGroup.billing_address.vat_number }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="orderGroup.billing_address.ssn">
                      <v-list-item-content>
                        <v-list-item-subtitle>Codice Fiscale</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">
                          {{ orderGroup.billing_address.ssn }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">
                          <a :href="'tel:' + orderGroup.billing_address.phone">{{ orderGroup.billing_address.phone }}</a>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card tile class="elevation-0">
                <v-card-title class="primary white--text">
                  Indirizzo Di
                  <template v-if="orderGroup.delivery.delivery_type.name === 'shipping'">
                  Spedizione:
                </template>
                  <template v-else>
                    Ritiro:
                  </template>
                </v-card-title>
                <v-card-text class="px-0">
                  <v-list class="white" dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Nome</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">
                          {{ orderGroup.shipping_address.addressee }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Indirizzo</v-list-item-subtitle>
                        <v-list-item-title>
                          {{ orderGroup.shipping_address.street_name }}
                          {{ orderGroup.shipping_address.street_number }},<br>
                          <template v-if="orderGroup.shipping_address.apartment !== null">
                            {{ orderGroup.shipping_address.apartment + ', ' }}<br>
                          </template>
                          {{ orderGroup.shipping_address.city }},
                          {{ orderGroup.shipping_address.district }}
                          {{ orderGroup.shipping_address.postcode }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="orderGroup.delivery.delivery_type.name === 'shipping'">
                      <v-list-item-content>
                        <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                        <v-list-item-title>
                          <a :href="'tel:' + orderGroup.shipping_address.phone">{{ orderGroup.shipping_address.phone }}</a>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="orderGroup.shipping_address.notes !== null">
                      <v-list-item-content>
                        <v-list-item-subtitle>Note</v-list-item-subtitle>
                        <v-list-item-title>{{ orderGroup.shipping_address.notes }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile class="elevation-0">
                <v-card-title class="primary white--text">
                  Prodotti
                </v-card-title>
                <v-card-text class="px-0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th>prodotto</th>
                        <th>modalità di vendita</th>
                        <th>prezzo(iva esclusa)</th>
                        <th>quantità</th>
                        <th>iva</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(line , index ) in orderGroup.order_lines" :key="index">
                        <td>
                          {{ line.order_line.product_name }}
                        </td>
                        <td>
                          {{ getOrderLinePackageDescription(line.order_line) }}
                        </td>
                        <td>
                          {{ (parseFloat(line.order_line.line_subtotal) / parseFloat(line.order_line.purchased_quantity)).toFixed(2) }}€/{{ (line.order_line.product_packaged) ? 'conf' : line.order_line.unit_of_measure }}
                        </td>
                        <td>
                          {{ parseFloat(line.order_line.purchased_quantity).toString() }}
                          {{ (line.order_line.product_packaged) ? 'conf' : line.order_line.unit_of_measure }}
                        </td>
                        <td>
                          {{ parseFloat(line.order_line.product_vat_percentage).toFixed(2) }} %
                        </td>
                      </tr>
                      </tbody>
                      <tfoot>
                      <tr :key="'totale_iva_esclusa'">
                        <td colspan="3" style="border-top:thin solid rgba(0, 0, 0, 0.12);"></td>
                        <td class="font-weight-black text-uppercase" style="border-top:thin solid rgba(0, 0, 0, 0.12);">
                          Totale Prodotti
                        </td>
                        <td style="border-top:thin solid rgba(0, 0, 0, 0.12);">
                          {{ totalWithoutDelivery.toFixed(2) }} €
                        </td>
                      </tr>
                      <tr :key="'consegna'">
                        <td colspan="3"></td>
                        <td class="font-weight-black text-uppercase" style="border-top:thin solid rgba(0, 0, 0, 0.12);">
                          Consegna
                        </td>
                        <td style="border-top:thin solid rgba(0, 0, 0, 0.12);">
                          {{ parseFloat(orderGroup.delivery.cost).toFixed(2) }} €
                        </td>
                      </tr>
                      <tr :key="'totale'">
                        <td colspan="3"></td>
                        <td class="primary font-weight-black white--text text-uppercase"
                            style="border-top:thin solid rgba(0, 0, 0, 0.12);">totale
                        </td>
                        <td class="font-weight-black" style="border-top:thin solid rgba(0, 0, 0, 0.12);">
                          {{ parseFloat(orderGroup.total).toFixed(2) }} €
                        </td>
                      </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if="orderGroupIsPayable" color="primary" width-min="20%" class="white--text" @click="payOrder">paga ora</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import computedMixin from "@/mixins/StoreComputed";
import responseErrorHandler from "@/mixins/ResponseErrorHandler";
import orderLineMixin from "@/mixins/OrderLine";
import {mapActions} from "vuex";

export default {
  name: "OrderGroupDetail",
  mixins: [computedMixin, responseErrorHandler,orderLineMixin],
  data: () => ({
    getOrderGroupDetailRequest: {loading: false},
    orderGroup: null
  }),
  computed:{
    totalWithoutDelivery:function(){
      let total = 0;
      this.orderGroup.order_lines.forEach(order_line=>{
        total += parseFloat(order_line.order_line.line_total)
      })
      return total
    },
    orderGroupIsPayable: {
      get(){
        return this.orderGroup?.order_group_status?.id === '1';
      }
    },
    orderTransactionText: {
      get(){
        let txNumber = this.orderGroup?.paypal_payment?.captures?.map(e => `#${e}`)?.join(", ");
        return txNumber ? "Transazione " + txNumber : "";
      }
    }
  },
  methods: {
    ...mapActions({
      updateOrderGroup: 'userOrders/updateOrderData',
    }),
    orderStatusAction: function(){
      if(this.orderGroupIsPayable){
        this.payOrder();
      }
    },
    payOrder(){
      const paypalPaymentId = this.orderGroup?.paypal_payment?.id;
      this.axios
          .patch(
              `${this.config.ecommerce_service_url}/api/v1/paypal_orders/${paypalPaymentId}`,
              {},
              {
                headers: {
                  Authorization: 'Bearer ' + this.user.jwtToken,
                  Accept: 'application/json'
                }
              }
          )
          .then( (response) => {
            const paypalPayment = response.data?.data?.paypal_payment;
            if(paypalPayment && paypalPayment.status === 'CREATED'){
              if(paypalPayment.deleted_at !== null){
                this.$store.commit("snackbar/update", {
                  show: true,
                  color: "error",
                  text: "Impossibile completare il pagamento: token scaduto! L'ordine è stato annullato.",
                });
                this.$router
                    .push({name: "AccountOrders"})
                    .then( () => {
                      this.getOrderGroupDetail(this.orderGroup.id);
                    });
              }
              else{
                window.location.href = paypalPayment.approve_link;
              }
            }
            else{
              this.$store.commit("snackbar/update", {
                show: true,
                color: "error",
                text: "Impossibile completare il pagamento.",
              });
            }
          })
          .catch( errors => {
            this.handleResponseError(errors);
          })
    },
    getOrderGroupDetail: function (orderGroupId) {
      this.getOrderGroupDetailRequest.loading = true;
      this.axios
          .get(this.config.ecommerce_service_url + '/api/v1/users/' + this.user.id + '/order_groups/' + orderGroupId + '?includes=billing_address,shipping_address,paypal_payment,order_group_status,delivery.delivery_type,order_lines&sort=-created_at',
              {
                headers: {
                  Authorization: 'Bearer ' + this.user.jwtToken,
                  Accept: 'application/json'
                }
              })
          .then(response => {
            this.orderGroup = response.data.data.order_group;
            this.updateOrderGroup(this.orderGroup);
          })
          .catch(error => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.getOrderGroupDetailRequest.loading = false;
          })
    }
  },
  created() {
    this.getOrderGroupDetail(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
