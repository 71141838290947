var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('router-view',{on:{"shoppingList:created":function($event){return _vm.getList(_vm.user.id)},"shoppingList:deleted":function($event){return _vm.getList(_vm.user.id)},"shoppingList:updated":function($event){return _vm.getList(_vm.user.id)}}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("LE TUE LISTE")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.$router.push({ name: 'ShoppingListCreate' })}}},[_vm._v("mdi-plus-circle-outline")])],1)],1)],1),_vm._l((_vm.shoppingLists),function(list){return _c('v-col',{key:list.id,attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('v-card-title',[_vm._v(" "+_vm._s(list.name)+" ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","x-small":""},on:{"click":function($event){return _vm.$router.push({
                name: 'ShoppingListShow',
                params: { listId: list.id },
              })}}},[_vm._v("visualizza ")]),_c('v-btn',{attrs:{"outlined":"","x-small":""},on:{"click":function($event){return _vm.$router.push({
                name: 'ShoppingListEdit',
                params: { listId: list.id },
              })}}},[_vm._v(" Rinomina ")]),_c('v-btn',{attrs:{"outlined":"","x-small":""},on:{"click":function($event){return _vm.$router.push({
                name: 'ShoppingListDelete',
                params: { listId: list.id },
              })}}},[_vm._v(" Rimuovi ")])],1)],1)])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }