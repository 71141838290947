<template>
  <v-dialog
    :value="true"
    persistent
    max-width="1024"
    scrollable
    @click:outside="$router.push({ name: 'ShoppingList' })"
  >
    <v-card>
      <v-card-title
        dense
        class="white--text primary text-uppercase font-weight-bold elevation-0"
      >
        Rinomina: {{ shoppingtListRequest.name }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$router.push({ name: 'ShoppingList' })">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-col cols="12" md="12">
          <ShoppinglistForm ref="addShoppingListForm"  :shoppingListData="shoppingtListRequest"></ShoppinglistForm>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="updateShoppingList" tile large>
          Salva
          <v-icon right dark> mdi-content-save </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import ShoppinglistForm from "@/views/ecommerce/components/forms/ShoppingListForm";

export default {
  name: "ShoppingListEdit",
  components: { ShoppinglistForm },
  mixins: [StoreComputed, ResponseErrorHandler],
  data() {
    return {
      updateShoppingListRequest: { loading: false },
      getShoppingList: { loading: false },
      shoppingListId: "",
      shoppingtListRequest: {},
    };
  },
  methods: {
    getOneShoppingList(listId) {
      return this.axios.get(
        this.config.ecommerce_service_url +
          "/api/v1/users/" +
          this.user.id +
          "/channels/" +
          this.config.channel.id +
          "/shopping_lists/" +
          listId,
        {
          headers: {
            Authorization: "Bearer " + this.user.jwtToken,
            Accept: "application/json",
          },
        }
      );
    },
    updateShoppingList: function () {
      this.updateShoppingListRequest.loading = true;
      this.axios
        .put(
          this.config.ecommerce_service_url +
            "/api/v1/users/" +
            this.user.id +
            "/channels/" +
            this.config.channel.id +
            "/shopping_lists/" +
            this.shoppingListId,
          {
            data: this.$refs.addShoppingListForm.shoppingtListRequest,
          },
          {
            headers: {
              Authorization: "Bearer " + this.user.jwtToken,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.$emit("shoppingList:updated", response.data.data);
          this.$store.commit("snackbar/update", {
            show: true,
            text: "Lista rinominata con successo",
            color: "success",
          });
          this.$router.push({ name: "ShoppingList" });
        })
        .catch((error) => {
          this.handleResponseError(error);
        })
        .finally(() => {
          this.updateShoppingListRequest.loading = false;
        });
    },
  },
  mounted() {
    this.shoppingListId = this.$route.params.listId;
    this.getShoppingList.loading = true;
    this.getOneShoppingList(this.$route.params.listId)
      .then((response) => {
        this.shoppingtListRequest = response.data.data.shopping_list;
      })
      .catch((error) => {
        this.handleResponseError(error);
      })
      .finally(() => {
        this.getShoppingList.loading = false;
      });
  },
};
</script>

<style scoped></style>
