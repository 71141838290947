<template>
  <v-container>
    <router-view v-on:address:updated="setUserAddresses(user.id)"
                 v-on:address:created="setUserAddresses(user.id)"
                 v-on:address:deleted="setUserAddresses(user.id)"></router-view>
    <v-row dense>
      <v-col cols="12" md="4" sm="4">
        <CreateAddressCard class="address-card"></CreateAddressCard>
      </v-col>
      <v-col v-for="address in addresses" :key="address.id" cols="12" md="4" sm="4">
        <AddressCard v-on:address:setDefault="setDefaultAddress" class="address-card" :address="address"></AddressCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import CreateAddressCard from "@/views/ecommerce/account/shippingAddresses/components/CreateAddressCard";
import AddressCard from "@/views/ecommerce/account/shippingAddresses/components/AddressCard";


export default {
  name: "List",
  mixins: [StoreComputed, ResponseErrorHandler],
  components: {CreateAddressCard, AddressCard},
  data: () => ({
    getUserAddressesRequest: {loading: false},
    putUserAddressesRequest: {loading: false},
    addresses: []
  }),
  methods: {
    putUserAddressDefault: function (userId, addressId, address) {
      return this.axios
          .put(
              this.config.ecommerce_service_url +
              "/api/v1/users/" +
              userId +
              "/addresses/" +
              addressId,
              {
                data: address,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.user.jwtToken,
                  Accept: "application/json",
                },
              }
          )
    },
    setDefaultAddress(addressId) {
      this.putUserAddressesRequest.loading = true
      this.putUserAddressDefault(this.user.id, addressId, {default: true})
          .then(() => {
            this.setUserAddresses(this.user.id);
          })
          .catch(error => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.putUserAddressesRequest.loading = true
          })
    }
    ,
    setUserAddresses(userId) {
      this.getUserAddressesRequest.loading = true;
      this.getUserAddresses(userId)
          .then(response => {
            this.addresses = this._.map(response.data.data, (address) => {
              return address.address;
            });
          })
          .catch(error => {
            this.handleResponseError(error);
          }).finally(() => {
        this.getUserAddressesRequest.loading = false;
      })
    },
    getUserAddresses: function (userId) {
      return this.axios.get(this.config.ecommerce_service_url + "/api/v1/users/" + userId + "/addresses?shipping=1",
          {
            headers: {
              Authorization: "Bearer " + this.user.jwtToken,
              Accept: "application/json",
            }
          })
    }
    ,
  },
  created() {
    this.setUserAddresses(this.user.id)
  }
}
</script>

<style scoped>
.address-card {


}
</style>