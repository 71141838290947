<template>
  <v-dialog
    :value="true"
    persistent
    max-width="1024"
    scrollable
    @click:outside="$router.push({ name: 'ShoppingList' })"
  >
    <v-card>
      <v-card-title
        dense
        class="white--text primary text-uppercase font-weight-bold elevation-0"
      >
        Lista corrente: {{ shoppingtListRequest.name }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$router.push({ name: 'ShoppingList' })">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <ShoppinglistForm
          :products="shoppingList.ShoppingListItems"
        ></ShoppinglistForm>
      </v-card-text>

      <v-divider></v-divider>
      <ShoppingListAdvise
        :visible="showShoppingListGenerator"
        :products="shoppingList.ShoppingListItems"
      ></ShoppingListAdvise>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import ShoppinglistForm from "@/views/ecommerce/account/shoppingLists/components/shoppingListProduct";
import ShoppingListAdvise from "@/views/ecommerce/account/shoppingLists/components/ShoppingListAdvise";

export default {
  name: "ShoppingListShow",
  mixins: [StoreComputed, ResponseErrorHandler],
  components: { ShoppinglistForm, ShoppingListAdvise },
  data() {
    return {
      showShoppingListGenerator: false,
      shoppingtListRequest: {
        name: "",
        description: "",
      },
      shoppingtListProducts: [],
      getShoppingList: { loading: false },
    };
  },
  methods: {
    getOneShoppingList(listId) {
      return this.axios.get(
        this.config.ecommerce_service_url +
          "/api/v1/users/" +
          this.user.id +
          "/channels/" +
          this.config.channel.id +
          "/shopping_lists/" +
          listId,
        {
          headers: {
            Authorization: "Bearer " + this.user.jwtToken,
            Accept: "application/json",
          },
        }
      );
    },
    getShoppingListItemsById: function (listId) {
      return this.axios.get(
        this.config.ecommerce_service_url +
          "/api/v1/users/" +
          this.user.id +
          "/channels/" +
          this.config.channel.id +
          "/shopping_lists/" +
          listId +
          "/shopping_list_items?includes=catalog_product,catalog_product.product",
        {
          headers: {
            Authorization: "Bearer " + this.user.jwtToken,
            Accept: "application/json",
          },
        }
      );
    },
    isShoppingListItemAvailable: function (shoppingListItem) {
      return (
        shoppingListItem.catalog_product.deleted_at !== null ||
        shoppingListItem.catalog_product.product.deleted_at !== null ||
        shoppingListItem.catalog_product.product.product_status_id === "1"
      );
    },
  },
  computed: {
    unavailableShoppingListItems: function () {
      return this._.filter(
        this.shoppingList.ShoppingListItems,
        (shoppingListItem) => {
          return this.isShoppingListItemAvailable(shoppingListItem.shopping_list_item)
        }
      );
    },
  },
  mounted() {
    console.log(this.unavailableShoppingListItems)
    this.getShoppingListItemsById(this.$route.params.listId)
      .then((response) => {
        this.$store.commit(
          "shoppingList/setShoppingListItems",
          response.data.data
        );
      })
      .catch((error) => {
        this.handleResponseError(error);
      })
      .finally(() => {});

    this.getOneShoppingList(this.$route.params.listId)
      .then((response) => {
        console.log(response.data.data.shopping_list);
        this.shoppingtListRequest.name = response.data.data.shopping_list.name;
        this.shoppingtListRequest.description =
          response.data.data.shopping_list.description;
      })
      .catch((error) => {
        this.handleResponseError(error);
      })
      .finally(() => {
        this.getShoppingList.loading = false;
      });
  },
};
</script>

<style scoped></style>
