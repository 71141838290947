<template>
  <v-form ref="form" lazy-validation>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-text-field
              :loading="loading"
              dense
              v-model="address.addressee"
              label="Denominazione"
              :rules="[v => !!v || 'Destinatario obbligatorio']"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
              v-model="citySelect"
              :loading="getCitiesRequest.loading"
              :items="citySelectItems"
              :search-input.sync="citySelectSearch"
              clearable
              dense
              label="Comune"
              :rules="[v => !!v || 'Comune obbligatorio']"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :loading="loading"
              dense
              v-model="address.street_name"
              label="Nome strada, piazza viale"
              :rules="[v => !!v || 'Nome obbligatorio']"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :loading="loading"
              dense
              v-model="address.street_number"
              label="Numero civico"
              :rules="[v => !!v || 'civico obbligatorio']"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :loading="loading"
              dense
              v-model="address.postcode"
              label="Cap"
              :rules="[v => !!v || 'Cap obbligatorio',rules.isValidCap]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :loading="loading"
              dense
              v-model="address.phone"
              label="Telefono"
              :rules="[v => !!v || 'Telefono obbligatorio',rules.isValidNumber]"></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="address.ssn"
              label="Codice Fiscale"
              :rules="(address.vat_number==='' || address.vat_number===null) ? 
                      [validationRules.required, validationRules.isValidCuaa] : 
                      [v => (v === null || v === '') || validationRules.isValidCuaa]"
              @keyup="upperCaseFiscalCode"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              v-model="address.vat_number"
              label="Partita Iva"
              :rules="(address.ssn==='' || address.ssn===null) ? 
                      [validationRules.required, isValidVatNumber] : 
                      [v => (v === null || v === '') || isValidVatNumber]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import StoreComputed from "@/mixins/StoreComputed";
import ValidationMixin from "@/mixins/Validation/ValidationMixin";

export default {
  name: "BillingAddressForm",
  mixins: [StoreComputed, ValidationMixin],

  props: ["addressData", "loading"],
  data: () => ({
    citySelect: null,
    citySelectSearch: '',
    citySelectItems: [],
    getCitiesRequest: {loading: false},
    address: {
      addressee: "",
      city: "",
      country: "IT",
      region: "",
      district: "",
      postcode: "",
      street_name: "",
      street_number: "",
      phone: "",
      shipping: false,
      billing: true,
      default: false,
      apartment: "",
      notes: "",
      ssn: "",
      vat_number: ""
    },
    rules: {
      isValidCap: (value) => {
        let regex = RegExp(/^[0-9]{5}$/);
        return regex.test(value) || 'Inserire un cap valido'
      },
      isValidNumber: (value) => {
        let regex = RegExp(/^[0-9]{9,11}$/);
        return regex.test(value) || 'Inserire un numero di telefono valido'
      },
    },
  }),
  methods: {
    isValidVatNumber: function (value) {
      if (value === '' || value === null) {
        return true;
      } else {
        return this.validationRules.vatNumber(value)
      }
    },
    upperCaseFiscalCode: function () {
      this.address.ssn = this.address.ssn.toUpperCase()
    },
    getCities(str) {
      return this.axios
          .get(this.config.ecommerce_service_url + "/api/v1/cities?name:lk=" + str + '&includes=province,province.region', {
            headers: {
              Authorization: "Bearer " + this.user.jwtToken,
              Accept: "application/json",
            },
          })
    },
  },
  mounted() {
    if (this.addressData !== undefined && this.addressData !== null) {
      let address = Object.assign({}, this.addressData);
      delete address.id;
      delete address.created_at;
      delete address.updated_at;
      delete address.deleted_at;

      this.citySelectItems.push({
        text: address.city,
        value: {
          name: address.city,
          region: address.region,
          district: address.district
        }
      });
      this.citySelect = {
        name: address.city,
        region: address.region,
        district: address.district
      };
      this.address = address;
    }
  },
  watch: {
    citySelectSearch(str) {
      this.getCitiesRequest.loading = true
      this.getCities(str)
          .then(response => {
            this.citySelectItems = this._.map(response.data.data, (city) => {
              return {
                text: city.city.name,
                value: {
                  name: city.city.name,
                  region: city.city.province.region.name,
                  district: city.city.province.short_name
                }
              }
            })
          })
          .catch(error => {
            this.handleResponseError(error)
          })
          .finally(() => {
            this.getCitiesRequest.loading = false
          });
    },
    citySelect: {
      handler(newCity) {
        if (newCity === null) {
          this.address.city = "";
          this.address.region = "";
          this.address.district = "";
        } else {
          this.address.city = newCity.name;
          this.address.region = newCity.region;
          this.address.district = newCity.district;
        }
      },
      deep: true
    },
    addressData: function(){
      if (this.addressData !== undefined && this.addressData !== null) {
      let address = Object.assign({}, this.addressData);
      delete address.id;
      delete address.created_at;
      delete address.updated_at;
      delete address.deleted_at;

      this.citySelectItems.push({
        text: address.city,
        value: {
          name: address.city,
          region: address.region,
          district: address.district
        }
      });
      this.citySelect = {
        name: address.city,
        region: address.region,
        district: address.district
      };
      this.address = address;
    }
    }
  },
}
</script>

<style scoped>
</style>