<template>
  <v-card class="my-3">
    <v-container>
      <v-row >
        <v-col cols="12" sm="2">
          <v-row>
            <v-col cols="6" sm="12" class="py-0 font-weight-black">
              Ordine #{{orderGroupData.id}} del
            </v-col>
            <v-col cols="6 py-0 pr-0" sm="12" align-self="center">
              {{ moment(orderGroupData.created_at).format('dddd l') }}
            </v-col>
          </v-row>
        </v-col>
        <v-col class="col-12 col-xl-1 col-lg-1 col-md-1 col-sm-1">
          <v-row>
            <v-col cols="6" sm="12" class="py-0 font-weight-black">
              Totale
            </v-col>
            <v-col cols="6 py-0 pr-0" sm="12">
              {{ parseFloat(orderGroupData.total).toFixed(2) }} €
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="3">
          <v-row>
            <v-col cols="6" sm="12" class="py-0 font-weight-black">
              Stato
            </v-col>
            <v-col cols="6 py-0" sm="12" v-if="!orderGroupIsPayable(orderGroupData)">
              {{orderGroupData.order_group_status.label}}
            </v-col>
            <v-col cols="6 py-0" sm="12" v-else>
              <v-btn x-small block color="primary" class="white--text" @click="showOrderGroupDetails(orderGroupData)">Paga ora</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="3">
          <v-row>
            <v-col cols="12" class="py-0 font-weight-black">
              Indirizzo di
              <template v-if="orderGroupData.delivery.delivery_type.name === 'shipping'">
                Spedizione
              </template>
              <template v-else>
                Ritiro
              </template>
            </v-col>
            <v-col cols="12 py-0">
              <v-menu
                  v-model="value"
                  :open-on-hover="true"
                  :close-on-click="true"
                  tile
              >
                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs"
                                          v-on="on">{{ orderGroupData.shipping_address.addressee }}<v-icon color="black"
                                                                                                           small right>mdi-information-outline</v-icon>
                                    </span>
                </template>
                <div class="yellow">
                  <v-list class="white" dense flat>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Destinatario</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">
                          {{ orderGroupData.shipping_address.addressee }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Indirizzo</v-list-item-subtitle>
                        <v-list-item-title>
                          {{ orderGroupData.shipping_address.street_name }}
                          {{ orderGroupData.shipping_address.street_number }},<br>
                          <template v-if="orderGroupData.shipping_address.apartment !== null">
                            {{ orderGroupData.shipping_address.apartment + ', ' }}<br>
                          </template>
                          {{ orderGroupData.shipping_address.city }},
                          {{ orderGroupData.shipping_address.district }}
                          {{ orderGroupData.shipping_address.postcode }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>Telefono</v-list-item-subtitle>
                        <v-list-item-title>
                          <a :href="'tel:' + orderGroupData.shipping_address.phone">{{ orderGroupData.shipping_address.phone }}</a>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="orderGroupData.shipping_address.notes !== null">
                      <v-list-item-content>
                        <v-list-item-subtitle>Note</v-list-item-subtitle>
                        <v-list-item-title>{{ orderGroupData.shipping_address.notes }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="2">
          <v-row>
            <v-col cols="12" class="py-0 font-weight-black">
                Data
                <template v-if="orderGroupData.delivery.delivery_type.name === 'shipping'">
                  di Consegna
                </template>
                <template v-else>
                  di Ritiro
                </template>
              </v-col>
              <v-col cols="6" sm="12" class="py-0 pr-0">
                {{moment(orderGroupData.delivery.date).format('dddd l')}}
              </v-col>
              <v-col cols="6" sm="12" class="py-0 pr-0">
                {{orderGroupData.delivery.start}} - {{orderGroupData.delivery.end}}
              </v-col>
            </v-row>
        </v-col>
        <v-col cols="col-12" sm="1" class="text-center">
          <v-btn fab x-small color="primary" class="white--text" @click="showOrderGroupDetails(orderGroupData)">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "OrderGroupRecap",
  props: ['orderGroupData'],
  data:() => ({
    value: null,
  }),
  methods: {
    showOrderGroupDetails(orderGroupData){
      this.$router.push({name:'OrderGroupDetail',params:{id:orderGroupData.id}})
    },
    orderGroupIsPayable(orderGroupData){
      return orderGroupData?.order_group_status?.id === '1';
    }
  },
}
</script>

<style scoped>

</style>
