import Vue from 'vue'

const state = () => ({
    is:null,
    isAuthenticated: null,
    jwtToken: null,
    firstName: null,
    lastName: null,
    code_anagen: null,
    approved: false,
    role: null,
    email: null
});

const getters = {}; 
const actions = {};
const mutations = {
    update(state, attributes) {
        for (let prop in attributes) {
            if (Object.prototype.hasOwnProperty.call(attributes, prop)) {
                Vue.set(state, prop, attributes[prop])
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}