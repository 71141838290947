import Vue from 'vue'
import Vuex from 'vuex'
import Config from "@/store/modules/Config";
import Snackbar from "@/store/modules/Snackbar";
import User from "@/store/modules/User";
import Commons from "@/store/modules/Commons";
import Channel from "@/store/modules/Channel";
import Cart from "@/store/modules/Cart";
import ShoppingList from "@/store/modules/ShoppingList";
import Application from "@/store/modules/Application";
import UserOrders from "@/store/modules/UserOrders";
import ChannelCustomCategories from "@/store/modules/ChannelCustomCategories";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        config: Config,
        snackbar: Snackbar,
        user: User,
        channel: Channel,
        commons: Commons,
        cart: Cart,
        application:Application,
        shoppingList: ShoppingList,
        userOrders: UserOrders,
        channelCustomCategories:ChannelCustomCategories
    }
});

