<template>
  <v-form ref="form" v-model="form">
    <v-container>
      <v-row class="text-center justify-center mt-5">
        <v-col cols="12" class="py-0">
          <v-text-field
              v-model="data.password"
              label="Password"
              :rules="[v => !!v || 'La nuova password è richiesta',rules.isValidPassword]"
              :loading="loading"
              :disabled="loading"
              clearable
              dense
              outlined
              prepend-icon="mdi-form-textbox-password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
              v-model="confirmationPassword"
              :rules="[v => !!v || 'La conferma della password è richiesta',isEqualToPassword]"
              label="Conferma Password"
              :loading="loading"
              :disabled="loading"
              clearable
              dense
              outlined
              prepend-icon="mdi-form-textbox-password"
              :append-icon="showConfirmationPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirmationPassword ? 'text' : 'password'"
              @click:append="showConfirmationPassword = !showConfirmationPassword"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  props: ['loading'],
  name: "ResetPasswordForm",
  data: () => ({
    showPassword: false,
    showConfirmationPassword: false,
    form: true,
    data: {password: ''},
    confirmationPassword: '',
    rules: {
      isValidPassword: (value) => {
        if (value.length > 0) {
          return value.length >= 8 || 'La password deve essere lunga almeno 8 caratteri'
        } else {
          return true
        }
      }
    },
  }),
  methods: {
    isEqualToPassword: function (value) {
      return value === this.data.password || 'La password di conferma non è uguale a quella inserita'
    },
  }
}
</script>

<style scoped>

</style>