<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <h1 class="Raleway text-uppercase customGreen--text font-weight-light">le nostre filiere</h1>
            </v-col>
        </v-row>
        <v-row>
            <SupplyChainCategory v-on:changedSelectedCategory="setSelectedCategory"></SupplyChainCategory>
        </v-row>
        <template v-if="getFilterableCategoriesBusinessRequest.loading">
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        indeterminate
                        color="customGreen"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12">
                    <h1 class="roboto font-weight-thin text-uppercase border-bottom-text mr-3">
                        {{selectedCategoryName}}
                    </h1>
                </v-col>
            </v-row>
            <template v-if="catalogBusinesses.length > 0">
                <v-row>
                    <v-col v-for="(bussiness, index) in catalogBusinesses" :key="index"
                        class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                        <BusinessCard v-bind:businessData="bussiness"></BusinessCard>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <v-row>
                    <v-col>
                        Nessuna filiera al momento disponibile
                    </v-col>
                </v-row>
            </template>
        </template>
    </v-container>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import SupplyChainCategory from "./components/SupplyChainCategory.vue"
import BusinessCard from "../components/BusinessCard";

export default {
    name: 'SupplyChain',
    mixins: [StoreComputed, ResponseErrorHandler],
    components: {SupplyChainCategory, BusinessCard},
    data:() => ({
        selectedCategory: null,
        selectedCategoryName: '',
        getFilterableCategoriesBusinessRequest: {loading: false},
        businessesIncludes: ['business_type', 'province', 'province.region', 'media'],
        catalogBusinesses: [],
    }),
    watch: {
        selectedCategory: function(newVal) {
            if(newVal === 36){
                this.selectedCategoryName = 'Cereali e derivati'
            } else if(newVal === 31){
                this.selectedCategoryName = 'Carne e derivati'
            } else if(newVal === 26){
                this.selectedCategoryName = 'Latte'
            } else if(newVal === 6){
                this.selectedCategoryName = 'Olio'
            } else if(newVal === 2){
                this.selectedCategoryName = 'Vinicola'
            } else if(newVal === 16){
                this.selectedCategoryName = 'Ortofrutta'
            } else if(newVal === 45){
                this.selectedCategoryName = 'Erbe Officinali'
            } else if(newVal === 11){
                this.selectedCategoryName = 'Miele'
            } else {
                this.selectedCategoryName = ''
            }
        }
    },
    methods: {
        setSelectedCategory: function(category){
            this.selectedCategory = category;
            this.setFilterableCategoryProducts();
        },
        setFilterableCategoryProducts() {
            this.getFilterableCategoriesBusinessRequest.loading = true;
            this.getFilterableCategoryBusinesses().then(res => {
                this.catalogBusinesses = [];
                res.data.data.forEach(catalogBusiness => {
                    let business = catalogBusiness.business;
                    if(business.filterable_categories.length > 1){
                        let category = business.filterable_categories;
                        for(let i=0; i<category.length; i++) {
                            if(category[i].parent_filterable_category_id ===  this.selectedCategory || category.id === this.selectedCategory){
                                this.catalogBusinesses.push(business);
                                break;
                            }
                        }
                    } else {
                        if(business.filterable_categories.length){
                            if(business.filterable_categories[0].parent_filterable_category_id === this.selectedCategory || business.filterable_categories[0].id === this.selectedCategory){
                                this.catalogBusinesses.push(business)
                            }
                        }
                    }
                });
                let meta = res.data.meta;
                this.pagination = {total: meta.total, current_page: meta.current_page, last_page: meta.last_page}
            }).catch(error => {
                this.handleResponseError(error);
            }).finally(() => {
                this.getFilterableCategoriesBusinessRequest.loading = false;
            });
        },
        getFilterableCategoryBusinesses() {
            return this.axios.get(
                this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/businesses',
                {
                    params: {
                        includes: this.businessesIncludes.join(','),
                    },
                    headers: {
                        Authorization: 'Bearer ' + this.user.jwtToken,
                        Accept: 'application/json'
                    }
                }
            )
        }
    },
    created(){
        this.selectedCategory = 1;
        this.setFilterableCategoryProducts();
    }
}
</script>

<style scoped>
</style>