<template>
  <v-dialog
    v-model="show"
    max-width="400"
    scrollable
    @click:outside="show = false"
    :disabled="postShoppingListRequest.loading"
  >
    <v-card>
      <v-card-title
        dense
        class="red--text text-uppercase font-weight-bold elevation-0"
      >
        ATTENZIONE: sono cambiate le condizioni dei prodotti
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-col cols="12" md="12">
          prova
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
   
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";


export default {

  props: ['visible','products'],
  mixins: [StoreComputed, ResponseErrorHandler],
  data() {
    return {
      postShoppingListRequest: { loading: false },
    };
  },
  computed: {
    show: {
      get(){
        return this.visible
      },
      set(value){
        if(!value){
          this.$emit('close')
        }
      }
    }
  },
};
</script>

<style scoped></style>
