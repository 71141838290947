<template>
    <v-row class="justify-center mx-2">
            <v-col cols="3" class="text-center">
                <v-row>
                    <v-col cols="6"  @click="$emit('changedSelectedCategory', 36)">
                        <v-img src="@/assets/images/icons/icona-cereali.png" contain></v-img>
                        <h4 class="Raleway mt-2 text-uppercase customBlue--text" style="font-weight:normal">cereali</h4>
                     </v-col>
                    <v-col cols="6" @click="$emit('changedSelectedCategory', 31)">
                        <v-img src="@/assets/images/icons/icona-carne.png" contain></v-img>
                        <h4 class="Raleway mt-2 text-uppercase customBlue--text" style="font-weight:normal">carne</h4>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" class="text-center">
                <v-row>
                    <v-col cols="6"  @click="$emit('changedSelectedCategory', 26)">
                        <v-img src="@/assets/images/icons/icona-latte.png" contain></v-img>
                        <h4 class="Raleway mt-2 text-uppercase customBlue--text" style="font-weight:normal">latte</h4>
                    </v-col>
                    <v-col cols="6" @click="$emit('changedSelectedCategory', 6)">
                        <v-img src="@/assets/images/icons/icona-olio.png" contain></v-img>
                        <h4 class="Raleway mt-2 text-uppercase customBlue--text" style="font-weight:normal">olio</h4>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" class="text-center">
                <v-row>
                    <v-col cols="6" @click="$emit('changedSelectedCategory', 2)">
                        <v-img src="@/assets/images/icons/icona-vinicola.png" contain></v-img>
                        <h4 class="Raleway mt-2 text-uppercase customBlue--text" style="font-weight:normal">vinicola</h4>
                    </v-col>
                    <v-col cols="6" @click="$emit('changedSelectedCategory', 16)">
                            <v-img src="@/assets/images/icons/icona-ortofrutta.png" contain></v-img>
                            <h4 class="Raleway mt-2 text-uppercase customBlue--text" style="font-weight:normal">ortofrutta</h4>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" class="text-center">
                <v-row>
                    <v-col cols="6" @click="$emit('changedSelectedCategory', 45)">
                    <v-img src="@/assets/images/icons/icona-erbeofficinali.png" contain></v-img>
                            <h4 class="Raleway mt-2 text-uppercase customBlue--text" style="font-weight:normal">erbe officinali</h4>
                    </v-col>
                    <v-col cols="6" @click="$emit('changedSelectedCategory', 11)">
                        <v-img src="@/assets/images/icons/icona-miele.png" contain></v-img>
                        <h4 class="Raleway mt-2 text-uppercase customBlue--text" style="font-weight:normal">miele</h4>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
</template>

<script>
export default {
    name: 'SupplyChainCategory',
}
</script>

<style scoped>
</style>