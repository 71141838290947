<template>
  <v-container fuild style="max-width:1280px">
    <v-row class="px-3">
      <v-card class="pa-0 elevation-0">
        <h1 class="roboto font-weight-thin text-uppercase border-bottom-text mr-3">
          <template v-if="this.$route.params.childrenFilterableCategoryId !== undefined">
            {{ $store.getters['commons/filterableCategory'](this.$route.params.childrenFilterableCategoryId).name }}
          </template>
          <template v-else>
            {{ $store.getters['commons/filterableCategory'](this.$route.params.parentFilterableCategoryId).name }}
          </template>
        </h1>
      </v-card>
    </v-row>
    <template v-if="getFilterableCategoriesProductRequest.loading">
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
              indeterminate
              color="customGreen"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <template v-if="catalogProducts.length > 0">
        <v-row>
          <v-col v-for="(catalogProduct , index) in catalogProducts" :key="index"
                 class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 px-3 pb-6 d-flex">
            <product-card :key="index"
                          :catalogProduct="catalogProduct"
                          :show-business="true">
            </product-card>
          </v-col>
        </v-row>
        <v-row v-if="pages.length > 1">
          <v-col class="text-center middle-black-line" style="position:relative">
            <template v-for="(page,index) in pages">
              <v-btn class="elevation-0 white"
                     tile
                     text
                     :key="index"
                     @click="setFilterableCategoryProducts(16, page)"
                     :disabled="getFilterableCategoriesProductRequest.loading">
                {{ page }}
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col>
            Nessun prodotto al momento disponibile
          </v-col>
        </v-row>
      </template>
    </template>
  </v-container>
</template>

<script>
import computedMixin from "../../../mixins/StoreComputed";
import responseErrorHandler from "../../../mixins/ResponseErrorHandler";
import ProductCard from "@/views/ecommerce/components/product/ProductCard";

export default {
  name: "Index",
  components: {ProductCard},
  mixins: [computedMixin, responseErrorHandler],
  comments: {ProductCard},
  data: () => ({
    includes: ['product.business', 'product.images', 'product.certifications', 'product.specification_sheet','product.stock'],
    getFilterableCategoriesProductRequest: {loading: false, error: false},
    catalogProducts: [],
    pagination: {total: null, last_page: null, current_page: null},
  }),
  methods: {
    setFilterableCategoryProducts(limit, page) {
      this.getFilterableCategoriesProductRequest.loading = true;
      this.getFilterableCategoryProducts(limit, page).then(res => {
        this.catalogProducts = [];
        res.data.data.forEach(catalogProduct => {
          this.catalogProducts.push(catalogProduct.catalog_product)
        });
        let meta = res.data.meta;
        this.pagination = {total: meta.total, current_page: meta.current_page, last_page: meta.last_page}
      }).catch(error => {
        this.handleResponseError(error);
      }).finally(() => {
        this.getFilterableCategoriesProductRequest.loading = false;
      });
    },
    getFilterableCategoryProducts(limit, page) {
      return this.axios.get(
          this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/products',
          {
            params: {
              limit: limit,
              page: page,
              product_filterable_category_id: this.filterableCategoryParam.join(','),
              includes: this.includes.join(',')
            },
            headers: {
              Authorization: 'Bearer ' + this.user.jwtToken,
              Accept: 'application/json'
            }
          }
      )
    },
  },
  created() {
    this.setFilterableCategoryProducts(16, 1);
  },
  watch: {
    combinedFilterableCategoryId: function () {
      this.setFilterableCategoryProducts(16, 1);
    }
  },
  computed: {
    combinedFilterableCategoryId() {
      return this.$route.params.parentFilterableCategoryId + this.$route.params.childrenFilterableCategoryId;
    },
    pages: function () {
      let pages = [];
      for (let i = 1; i <= this.pagination.last_page; i++) {
        pages.push(i)
      }
      return pages;
    },
    filterableCategoryParam: function () {
      let filterableCategoryParam = [];
      if (this.$route.params.childrenFilterableCategoryId !== undefined) {
        filterableCategoryParam.push(this.$route.params.childrenFilterableCategoryId);
      } else {
        filterableCategoryParam.push(this.$route.params.parentFilterableCategoryId);
        filterableCategoryParam.push(this._.map(this.$store.getters['commons/childrenFilterableCategories'](this.$route.params.parentFilterableCategoryId), 'id'));
      }
      return filterableCategoryParam;
    }
  },
}
</script>

<style scoped>

</style>
