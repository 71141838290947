<template>
  <v-container fuild style="max-width:1280px">
    <v-row>
      <v-card class="pa-0 elevation-0">
        <h1 class="roboto font-weight-thin text-uppercase border-bottom-text mr-3">Conferma Email</h1>
      </v-card>
      <v-col class="border-shadow pa-0"></v-col>
    </v-row>
    <v-row v-if="!emailVerified">
      <v-col cols="auto" class="mx-auto">
        <v-card class="elevation-0" style="max-width: 800px">
          <v-card-text>
            <v-progress-circular
                class="align-self-center"
                indeterminate
                color="green"
            ></v-progress-circular>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="auto" class="mx-auto">
        <v-card class="elevation-0" style="max-width: 400px">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="auto">
                  <v-icon large color="success">mdi-check-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex">
                  <h1 class="mb-0 align-self-center">Verifica email Completata.</h1>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn class="font-weight-bold" color="primary" width="100%" @click="$router.push({name:'Login'})">
              Accedi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";

export default {
  name: "index.vue",
  mixins: [StoreComputed, ResponseErrorHandler],
  data: () => ({
    token: null,
    getVerifyEmailRequest: {loading: false},
    emailVerified: false
  }),
  methods: {
    getVerifyEmail: function (token) {
      this.getVerifyEmailRequest.loading = true
      this.axios
          .get(this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/verify_email/' + token, {
            headers: {
              Authorization: 'Bearer ' + this.user.jwtToken,
              Accept: 'application/json'
            }
          })
          .then(() => {
            this.emailVerified = true
          })
          .catch(error => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.getVerifyEmailRequest.loading = false;
          })
    }
  },
  mounted() {
    this.token = this.$route.params.token
    this.getVerifyEmail(this.token)
  }

}
</script>

<style scoped>

</style>