<template>
    <v-container fuild style="max-width:1280px">
        <v-row class="px-4 mb-5">
            <v-card class="pa-0 elevation-0">
                <h1 class="roboto font-weight-thin text-uppercase border-bottom-text mr-3">
                    Prodotti in evidenza
                </h1>
            </v-card>
            <v-col class="border-shadow pa-0">
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="(catalogProduct , index) in catalogProducts" :key="index" cols="3">
                <ProductCard v-bind:catalogProductData="catalogProduct"  :show-business="true"></ProductCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import ProductCard from "@/views/ecommerce/components/product/ProductCard";

    export default {
        name: "index",
        mixins: [StoreComputed, ResponseErrorHandler],
        components: {ProductCard},
        data: () => ({
            getHighlightedProductsRequest: {loading: false, error: false},
            catalogProducts: []
        }),
        methods: {
            getHighlightedProducts: function () {
                this.getHighlightedProductsRequest.loading = true;
                this.axios.get(
                    this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/products?catalog_product_is_highlighted=1&page=1&limit=5&includes=product.business,product.images,product.certifications,product.specification_sheet',
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.user.jwtToken,
                            Accept: 'application/json'
                        }
                    }
                ).then(res => {
                    this.catalogProducts = [];
                    res.data.data.forEach(catalogProduct => {
                        this.catalogProducts.push(catalogProduct.catalog_product)
                    })
                }).catch(error => {
                    this.getHighlightedProductsRequest.error = true;
                    this.handleResponseError(error)
                }).finally(() => {
                    this.getHighlightedProductsRequest.loading = false;
                })
            }
        },
        created() {
            this.getHighlightedProducts();
        }
    }
</script>

<style scoped>

</style>