import checkFiscalCode from "./fiscalCode";

export default {
    data() {
        return {
            validationRules: {
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Devi inserire un indirizzo email valido';
                },
                alpha: value => {
                    return /^[a-zA-ZÀ-ÿ\s\u00f1\u00d1]*$/.test(value) || 'Puoi specificare solo lettere';
                },
                alphaSpace: value => {
                    const pattern = /^[a-zA-ZÀ-ÿ\s\u00f1\u00d1]*$/;
                    return pattern.test(value) || 'Puoi specificare solo lettere e spazi';
                },
                required: value => {
                    return !!value || "Il campo è obbligatorio";
                },
                phone: value => {
                    return /^[0-9]{6,}$/.test(value) || 'Inserire un numero di telefono valido';
                },
                emailColdiretti: value => {
                    if (value !== null) {
                        const pattern = /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@coldiretti\.it$/;
                        return pattern.test(value) || 'Inserire un indirizzo email @coldiretti.it';
                    }else{
                        return true
                    }
                },
                pecColdiretti: value => {
                    if (value !== null) {
                        const pattern = /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@pec\.coldiretti\.it$/;
                        return pattern.test(value) || 'Inserire un indirizzo email @pec.coldiretti.it';
                    } else {
                        return true
                    }

                },
                digits: value => {
                    return /[0-9]*/.test(value) || 'Puoi specificare solo numeri';
                },
                fiscalCode: value => {
                    return checkFiscalCode(value);
                },
                vatNumber: value => {
                    const pattern = /^[0-9]{11}$/;
                    return pattern.test(value) || 'Inserire una partita iva valida';
                },
                isValidCuaa: (value) => {
                    let regex = RegExp(/^(?:[B-DF-HJ-NP-TV-Z](?:[AEIOU]{2}|[AEIOU]X)|[AEIOU]{2}X|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[\dLMNP-V][1-9MNP-V]|[1-9MNP-V][0L]))[A-Z]$|^[0-9]{11}$/);
                    return regex.test(value) || 'Inserire un codice fiscale valido'
                }
            }
        }
    }
}
