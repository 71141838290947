import Vue from 'vue'
import _ from 'lodash';

const state = () => ({
    shoppingLists:[],
    ShoppingListItems: [],
    ShoppingListSelected: []
});

const getters = {};
const actions = {};
const mutations = {
    empty(state) {
        state.ShoppingList = [];
    },
    setShoppingLists(state,shoppingLists){
        state.shoppingLists = shoppingLists;
    },
    setShoppingListItems(state, ShoppingList) {
        state.ShoppingListItems = ShoppingList;
    },
    setSelectedShoppingListItems(state, ShoppingList) {
        state.ShoppingListSelected = []
        state.ShoppingListSelected.push(ShoppingList);
    },
    removeShoppingListItem(state, ShoppingListItemId) {
        let ShoppingListItemIndexToRemove = _.findIndex(state.ShoppingListItems, function (o) {
            return o.id === ShoppingListItemId;
        });
        state.ShoppingListItems.splice(ShoppingListItemIndexToRemove, 1)
    },
    updateShoppingListItem(state, ShoppingListItem) {
        let ShoppingListItemIndexToUpdate = _.findIndex(state.ShoppingListItems, function (o) {
            return o.id === ShoppingListItem.id;
        });
        Vue.set(state.ShoppingListItems, ShoppingListItemIndexToUpdate, ShoppingListItem);

    },
    addShoppingListItem(state, ShoppingListItem) {
        state.ShoppingListItems.push(ShoppingListItem);
    },
    deleteShoppingListItems(state) {
        state.ShoppingListItems = [];
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}