<template>
  <v-card class="elevation-0">
    <template v-if="addresses.length > 0">
      <v-radio-group v-model="selectedAddressIndex" column>
        <v-radio
            v-for="(address , index) in addresses"
            :key="index"
            :value="index"
            class="mb-8"
        >
          <template v-slot:label>
            <v-row align="center">
              <v-col cols="12 py-0" md="auto pr-0">
                <b>{{ address.addressee }}&nbsp;</b>
              </v-col>
              <v-col cols="12 py-0" md="auto">
                <span>{{ address.street_name }} {{ address.street_number }},
                {{ (address.apartment !== null) ? address.apartment + ', ' : ' '}}
                {{ address.city }}, {{ address.district }} {{ address.postcode }}</span>
                <v-btn icon v-on:click.stop="openEditUserAddressDialog(index)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon v-on:click.stop="deleteUserAddress(address.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-radio>
      </v-radio-group>
    </template>
    <v-btn
        color="primary"
        tile
        text
        class="mb-2"
        v-on:click="createAddressDialog = true"
    >
      <v-icon>mdi-plus</v-icon>
      Aggiungi indirizzo di spedizione
    </v-btn>
    <v-dialog
        v-if="createAddressDialog"
        v-model="createAddressDialog"
        max-width="800"
        scrollable
    >
      <v-card>
        <v-card-title class="headline primary">
          Crea Indirizzo
        </v-card-title>
        <v-card-text>
          <ShippingAddressForm ref="create_address_form"></ShippingAddressForm>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="createAddressDialog = false"
          >
            Chiudi
          </v-btn>
          <v-btn
              color="primary"
              @click="postUserAddress"
          >
            Salva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-if="editAddressDialog"
        v-model="editAddressDialog"
        max-width="800"
        scrollable
    >
      <v-card>
        <v-card-title class="headline primary">
          Modifica Indirizzo
        </v-card-title>
        <v-card-text>
          <ShippingAddressForm v-bind:addressData="editedAddressData" ref="edit_address_form"></ShippingAddressForm>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="editAddressDialog = false"
          >
            Chiudi
          </v-btn>
          <v-btn
              color="primary"
              @click="updateUserAddress"
          >
            Salva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>

import Vue from "vue";
import computedMixin from "../../../../mixins/StoreComputed";
import responseErrorHandler from "../../../../mixins/ResponseErrorHandler";
import ShippingAddressForm from "@/views/ecommerce/components/forms/ShippingAddressForm";

export default {
  name: "ShippingAddress",
  components: {ShippingAddressForm},
  mixins: [computedMixin, responseErrorHandler],
  data: () => ({
    editAddressDialog: false,
    createAddressDialog: false,
    selectedAddressIndex: undefined,
    addresses: [],
    editedAddressData: undefined,

    getUserAddressesRequest: {loading: false, error: false},
    postUserAddressRequest: {loading: false, error: false},
    updateUserAddressRequest: {loading: false, error: false},
    deletetUserAddressRequest: {loading: false, error: false}
  }),
  methods: {
    getUserAddresses: function () {
      this.getUserAddressesRequest.loading = true;
      this.axios.get(
          this.config.ecommerce_service_url + '/api/v1/users/' + this.user.id + '/addresses?shipping=1',
          {
            headers: {
              Authorization: 'Bearer ' + this.user.jwtToken,
              Accept: 'application/json'
            }
          }
      ).then(res => {
        res.data.data.forEach(address => {
          this.addresses.push(address.address);
        })
      }).catch(error => {
        this.handleResponseError(error)
      }).finally(() => {
        this.getUserAddressesRequest.loading = false;
      });
    },
    postUserAddress: function () {
      if (this.$refs.create_address_form.$refs.form.validate()) {
        this.postUserAddressRequest.loading = true;
        this.axios.post(
            this.config.ecommerce_service_url + '/api/v1/users/' + this.user.id + '/addresses',
            {
              data: this.$refs.create_address_form.address
            },
            {
              headers: {
                Authorization: 'Bearer ' + this.user.jwtToken,
                Accept: 'application/json'
              }
            }
        ).then(response => {
          this.createAddressDialog = false;
          this.addresses.push(response.data.data.address);
        }).catch(error => {
          this.handleResponseError(error);
        }).finally(() => {
          this.postUserAddressRequest.loading = false;
        });
      }
    },
    updateUserAddress: function () {
      if (this.$refs.edit_address_form.$refs.form.validate()) {
        this.updateUserAddressRequest.loading = true;
        this.axios.put(
            this.config.ecommerce_service_url + '/api/v1/users/' + this.user.id + '/addresses/' + this.editedAddressData.id,
            {
              data: this.$refs.edit_address_form.address
            },
            {
              headers: {
                Authorization: 'Bearer ' + this.user.jwtToken,
                Accept: 'application/json'
              }
            }
        ).then(response => {
          this.editAddressDialog = false;
          let address = response.data.data.address;
          let addressIndexToUpdate = this._.findIndex(this.addresses, function (o) {
            return o.id === address.id;
          });
          this.editedAddressData = null;
          Vue.set(this.addresses, addressIndexToUpdate, address);
        }).catch(error => {
          this.handleResponseError(error);
        }).finally(() => {
          this.updateUserAddressRequest.loading = false;
        });
      }
    },
    deleteUserAddress: function (addressId) {
      this.deletetUserAddressRequest.loading = true;
      this.axios.delete(
          this.config.ecommerce_service_url + '/api/v1/users/' + this.user.id + '/addresses/' + addressId,
          {
            headers: {
              Authorization: 'Bearer ' + this.user.jwtToken,
              Accept: 'application/json'
            }
          }
      ).then(() => {
        let addressIndexToDelete = this._.findIndex(this.addresses, function (o) {
          return o.id === addressId;
        });
        if (addressIndexToDelete === this.selectedAddressIndex) {
          this.selectedAddressIndex = undefined;
        }
        this.addresses.splice(addressIndexToDelete, 1)
      }).catch(error => {
        this.handleResponseError(error);
      }).finally(() => {
        this.deletetUserAddressRequest.loading = false;
      });
    },
    openEditUserAddressDialog: function (index) {
      this.editedAddressData = this.addresses[index];
      this.editAddressDialog = true;
    }
  },
  created() {
    this.getUserAddresses();
  },
  computed: {
    addressData: function () {
      if (this.selectedAddressIndex === undefined) {
        return null;
      } else {
        return this.addresses[this.selectedAddressIndex];
      }
    }
  },
  watch: {
    addressData: function (newAddressData) {
      this.$emit('updated:addressData', newAddressData)
    }
  }
}
</script>

<style scoped>

</style>