import Vue from 'vue'
import goTo from 'vuetify/es5/services/goto'
import VueRouter from 'vue-router'
import Index from '../views/ecommerce/Index'
import Home from '../views/ecommerce/home/Index'
import Category from '../views/ecommerce/category/Index'
import WhoAreWe from '../views/ecommerce/who_are_we/Index'
import SupplyChain from '../views/ecommerce/supply_chain/Index'
import Businesses from '../views/ecommerce/business/List'
import Business from '../views/ecommerce/business/Index'
import Account from '../views/ecommerce/account/Index'
import LoginBackend from '../views/login/Backend'
import LoginKeycloak from '../views/login/Keycloak'

import AccountPersonalInformation from "@/views/ecommerce/account/personalInformation/AccountPersonalInformation";
import AccountAddresses from "@/views/ecommerce/account/shippingAddresses/Index";
import AccountAddressesList from "@/views/ecommerce/account/shippingAddresses/List";
import AccountAddressesCreate from "@/views/ecommerce/account/shippingAddresses/Create";
import AccountAddressesEdit from "@/views/ecommerce/account/shippingAddresses/Edit";
import AccountAddressesDelete from "@/views/ecommerce/account/shippingAddresses/Delete";

import AccountBillingAddresses from "@/views/ecommerce/account/billingAddresses/Index";
import AccountBillingAddressesList from "@/views/ecommerce/account/billingAddresses/List";
import AccountBillingAddressesCreate from "@/views/ecommerce/account/billingAddresses/Create";
import AccountBillingAddressesEdit from "@/views/ecommerce/account/billingAddresses/Edit";
import AccountBillingAddressesDelete from "@/views/ecommerce/account/billingAddresses/Delete";

import ShoppingLists from "@/views/ecommerce/account/shoppingLists/Index";
import ShoppingList from "@/views/ecommerce/account/shoppingLists/List";
import ShoppingListCreate from "@/views/ecommerce/account/shoppingLists/Create";
import ShoppingListDelete from "@/views/ecommerce/account/shoppingLists/Delete";
import ShoppingListEdit from "@/views/ecommerce/account/shoppingLists/Edit";
import ShoppingListShow from "@/views/ecommerce/account/shoppingLists/Show";

import OrderGroupDetail from "@/views/ecommerce/account/orders/Components/OrderGroupDetail";
import AccountOrders from "@/views/ecommerce/account/orders/AccountOrders";

import Cart from "@/views/ecommerce/cart/Index";
import ShoppingListCart from "@/views/ecommerce/cart/shoppingList";

import SignUp from "@/views/ecommerce/signup/SignUp"
import EmailVerification from "@/views/ecommerce/email_verification/Index"
import ResetPassword from "@/views/ecommerce/reset_password/ResetPassword";
import ForgotPassword from "@/views/ecommerce/forgot_password/ForgotPassword";


import HighlightedProducts from "@/views/ecommerce/highlighted_products/Index";
import SearchProducts from "@/views/ecommerce/search_produts/Index";

import CompletePayment from "@/views/ecommerce/complete-payment/CompletePayment";
import Checkout from "@/views/ecommerce/checkout/Index";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index,
        children: [{
            path: '',
            name: 'Home',
            component: Home
        }, {
            path: 'signup',
            name: 'SignUp',
            component: SignUp
        },{
            path: 'reset_password/:token',
            name: 'ResetPassword',
            component: ResetPassword
        },{
            path: 'forgot_password',
            name: 'ForgotPassword',
            component: ForgotPassword
        }, {
            path: 'email_verification/:token',
            name: 'EmailVerification',
            component: EmailVerification
        }, {
            path: 'who-are-we',
            name: 'WhoAreWe',
            component: WhoAreWe
        }, {
            path: 'supply-chain',
            name: 'SupplyChain',
            component: SupplyChain
        }, {
            path: 'businesses',
            name: 'Businesses',
            component: Businesses
        }, {
            path: 'business/:id',
            name: 'Business',
            component: Business
        }, {
            path: 'category/:parentFilterableCategoryId/:childrenFilterableCategoryId?',
            name: 'Category',
            component: Category
        }, {
            path: 'cart',
            name: 'Cart',
            component: Cart,
            children: [
                {

                    path: 'show',
                    name: 'ShoppingListCart',
                    component: ShoppingListCart,

                },]
        }, {
            path: 'highlighted_products',
            name: 'HighlightedProducts',
            component: HighlightedProducts
        }, {
            path: 'search_products/:searchString',
            name: 'SearchProducts',
            component: SearchProducts
        },
            {
                path: 'checkout',
                name: 'Checkout',
                component: Checkout,
                beforeEnter: (to, from, next) => {
                    if (localStorage.getItem('ecommerce-coldiretti-access-token')) {
                        next();
                    } else {
                        next({name: 'Home'});
                    }
                },
            },
            {
                path: 'complete-payment',
                name: 'CompletePayment',
                component: CompletePayment,
                beforeEnter: (to, from, next) => {
                    if (localStorage.getItem('ecommerce-coldiretti-access-token')) {
                        next();
                    } else {
                        next({name: 'Home'});
                    }
                },
            },
            {
                path: 'account',
                name: 'Account',
                component: Account,
                redirect: {name: 'AccountPersonalInformation'},
                beforeEnter: (to, from, next) => {
                    if (localStorage.getItem('ecommerce-coldiretti-access-token')) {
                        next();
                    } else {
                        next({name: 'Home'});
                    }
                },
                children: [
                    {
                        path: 'informazioni-personali',
                        name: 'AccountPersonalInformation',
                        component: AccountPersonalInformation
                    },
                    {
                        path: 'ordini',
                        name: 'AccountOrders',
                        component: AccountOrders,
                        children: [
                            {
                                path: 'dettaglio-ordine/:id',
                                name: 'OrderGroupDetail',
                                component: OrderGroupDetail
                            }
                        ]
                    },
                    {
                        path: 'indirizzi-di-spedizione',
                        name: 'AccountAddresses',
                        component: AccountAddresses,
                        redirect: {name: 'AccountAddressesList'},
                        children: [
                            {
                                path: '',
                                name: 'AccountAddressesList',
                                component: AccountAddressesList,
                                children: [
                                    {
                                        path: 'create',
                                        name: 'AccountAddressesCreate',
                                        component: AccountAddressesCreate
                                    },
                                    {
                                        path: 'edit/:addressId',
                                        name: 'AccountAddressesEdit',
                                        component: AccountAddressesEdit
                                    },
                                    {
                                        path: 'delete/:addressId',
                                        name: 'AccountAddressesDelete',
                                        component: AccountAddressesDelete
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'indirizzi-di-fatturazione',
                        name: 'AccountBillingAddresses',
                        component: AccountBillingAddresses,
                        redirect: {name: 'AccountBillingAddressesList'},
                        children: [
                            {
                                path: '',
                                name: 'AccountBillingAddressesList',
                                component: AccountBillingAddressesList,
                                children: [
                                    {
                                        path: 'create',
                                        name: 'AccountBillingAddressesCreate',
                                        component: AccountBillingAddressesCreate
                                    },
                                    {
                                        path: 'edit/:addressId',
                                        name: 'AccountBillingAddressesEdit',
                                        component: AccountBillingAddressesEdit
                                    },
                                    {
                                        path: 'delete/:addressId',
                                        name: 'AccountBillingAddressesDelete',
                                        component: AccountBillingAddressesDelete
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'shoppinglist',
                        name: 'shoppingLists',
                        component: ShoppingLists,
                        redirect: {name: 'ShoppingList'},
                        children: [
                            {
                                path: '',
                                name: 'ShoppingList',
                                component: ShoppingList,
                                children: [
                                    {
                                        path: 'create',
                                        name: 'ShoppingListCreate',
                                        component: ShoppingListCreate,
                                    },
                                    {
                                        path: 'delete/:listId',
                                        name: 'ShoppingListDelete',
                                        component: ShoppingListDelete,
                                    },
                                    {
                                        path: 'edit/:listId',
                                        name: 'ShoppingListEdit',
                                        component: ShoppingListEdit,
                                    },
                                    {
                                        path: 'show/:listId',
                                        name: 'ShoppingListShow',
                                        component: ShoppingListShow,
                                    }
                                ]
                            },
                        ]
                    }
                ]
            }]
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginBackend,
    }, {
        path: '/login-socio',
        name: 'LoginSocio',
        component: LoginKeycloak,
    },
]

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    routes
})

export default router
