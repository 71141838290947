import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import 'typeface-roboto/index.css';

Vue.config.productionTip = false

import moment from 'moment';
moment.locale('it');
Vue.prototype.moment = moment;
import lodash from 'lodash';
Vue.prototype._ = lodash;
import keycloakJs from 'keycloak-js';
Vue.prototype.keycloakJs = keycloakJs;
import axios from 'axios';
Vue.prototype.axios = axios;

new Vue({ //
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
 