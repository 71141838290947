<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="1024"
    @click:outside="show = false"
  >
    <v-card>
      <v-card-title
        dense
        class="white--text customRaspBerry text-uppercase font-weight-bold elevation-0"
      >
        Liste
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-col cols="12" md="12">
            <ShoppinglistForm ref="addShoppingListForm"></ShoppinglistForm>
        </v-col>
        <v-col cols="12" md="12">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="customRaspBerry" text tile large @click="postShoppingList()">
              aggiungi nuova lista
              <v-icon right dark> mdi-content-save </v-icon>
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-col cols="12" v-for="list in shoppingList.ShoppingListItems" :key="list.id">
        <v-card color="customRaspBerry" dark>
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-card-title>
              {{ list.name }}
            </v-card-title>
            <v-card-actions>
              <v-btn outlined @click="postShoppingListItem(list.id)">aggiungi</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import ShoppinglistForm from "@/views/ecommerce/components/forms/ShoppingListForm";


export default {
  name: "ShoppingListShow",
  props: ['visible'],
  components: { ShoppinglistForm },
  mixins: [StoreComputed, ResponseErrorHandler],
  data() {
    return {
      shoppingLists: [],
      getShoppingList: { loading: false },
      postShoppingListRequest: { loading: false }
    };
  },
  computed: {
    show: {
      get(){
        return this.visible
      },
      set(value){
        if(!value){
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    postShoppingList: function () {
      if (this.$refs.addShoppingListForm.$refs.form.validate()) {
        this.postShoppingListRequest.loading = true;
        this.axios
          .post(
            this.config.ecommerce_service_url +
              "/api/v1/users/" +
              this.user.id +
              "/channels/" +
              this.config.channel.id +
              "/shopping_lists",
            {
              data: this.$refs.addShoppingListForm.shoppingtListRequest,
            },
            {
              headers: {
                Authorization: "Bearer " + this.user.jwtToken,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response)
            this.$store.commit('shoppingList/addShoppingListItem', response.data.data.shopping_list);
          })
          .catch((error) => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.postShoppingListRequest.loading = false;
          });
      }
    },
    getList(userId) {
      this.getShoppingList.loading = true;
      this.getShoppingLists(userId)
        .then((response) => {
          this.shoppingLists = this._.map(response.data.data, (list) => {
            return list.shopping_list;

          
          });
          let shoppingListItems = [];
                    response.data.data.forEach(list => {
                        shoppingListItems.push(list.shopping_list);
                    });
                    this.$store.commit('shoppingList/setShoppingListItems', shoppingListItems);
        })
        .catch((error) => {
          this.handleResponseError(error);
        })
        .finally(() => {
          this.getShoppingList.loading = false;
        });
    },
    getShoppingLists: function (userId) {
      return this.axios.get(
        this.config.ecommerce_service_url +
          "/api/v1/users/" +
          this.user.id +
          "/channels/" +
          this.config.channel.id +
          "/shopping_lists",
        {
          headers: {
            Authorization: "Bearer " + this.user.jwtToken,
            Accept: "application/json",
          },
        }
      );
    },
    postShoppingListItem: function (shoppingListId) {
      this.axios
        .post(
          this.config.ecommerce_service_url +
            "/api/v1/users/" +
            this.user.id +
            "/channels/" +
            this.config.channel.id +
            "/shopping_lists/" +
            shoppingListId+"/shopping_list_items",
          {
            data: {
              catalog_product_id: this.shoppingList.ShoppingListSelected[0].catalog_product_id,
              notes: "A note",
              quantity: this.shoppingList.ShoppingListSelected[0].quantity,
              priority: 4,
            },
          },
          {
            headers: {
              Authorization: "Bearer " + this.user.jwtToken,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response)
          this.$store.commit("snackbar/update", {
            show: true,
            color: "success",
            text: "Prodotto aggiunto alla lista della spesa",
          });
        })
        .catch((error) => {
          this.handleResponseError(error);
        })
        .finally(() => {
        });
    },
  },
  created() {
      this.getList(this.user.id);
   
  },
};
</script>

<style scoped></style>
