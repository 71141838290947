<template>
  <v-dialog
    :value="true"
    persistent
    max-width="1024"
    scrollable
    @click:outside="$router.push({ name: 'ShoppingList' })"
    :disabled="postShoppingListRequest.loading"
  >
    <v-card>
      <v-card-title
        dense
        class="white--text primary text-uppercase font-weight-bold elevation-0"
      >
        Aggiungi Lista Della Spesa
        <v-spacer></v-spacer>
        <v-btn icon @click="$router.push({ name: 'ShoppingList' })">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-col cols="12" md="12">
          <ShoppinglistForm ref="addShoppingListForm"></ShoppinglistForm>
        </v-col>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          tile
          large
          @click="postShoppingList()"
          :loading="postShoppingListRequest.loading"
        >
          Aggiungi
          <v-icon right dark> mdi-plus </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import ShoppinglistForm from "@/views/ecommerce/components/forms/ShoppingListForm";

export default {
  name: "ShoppingListCreate",
  components: { ShoppinglistForm },
  mixins: [StoreComputed, ResponseErrorHandler],
  data() {
    return {
      postShoppingListRequest: { loading: false },
      dialog: true,
    };
  },
  methods: {
    postShoppingList: function () {
      if (this.$refs.addShoppingListForm.$refs.form.validate()) {
        this.postShoppingListRequest.loading = true;
        this.axios
          .post(
            this.config.ecommerce_service_url +
              "/api/v1/users/" +
              this.user.id +
              "/channels/" +
              this.config.channel.id +
              "/shopping_lists",
            {
              data: this.$refs.addShoppingListForm.shoppingtListRequest,
            },
            {
              headers: {
                Authorization: "Bearer " + this.user.jwtToken,
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            this.$emit("shoppingList:created", response.data.data);
            this.$store.commit("snackbar/update", {
              show: true,
              text: "Lista aggiunta con successo",
              color: "success",
            });
            this.$router.push({ name: "ShoppingList" });
          })
          .catch((error) => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.postShoppingListRequest.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
