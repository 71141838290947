<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xs"
    width="500"
    transition="dialog-bottom-transition"
    v-model="show"
  >
    <v-layout column>
      <v-card>
        <v-card-text>
          <v-text-field  label="Password Corrente"></v-text-field>
          <v-text-field  label="Nuova Password"></v-text-field>
          <v-text-field  label="Ripeti la Password"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary"> Cambia </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  async mounted() {
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
  },
};
</script>
