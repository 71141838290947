<template>
  <v-container fuild style="max-width:1280px">
    <v-row>
      <v-card class="pa-0 pt-3 elevation-0">
        <h1 class="roboto font-weight-thin text-uppercase border-bottom-text mr-3">Reset Password</h1>
      </v-card>
      <v-col class="border-shadow pa-0"></v-col>
    </v-row>
    <v-row v-if="!resetPasswordCompleted">
      <v-col cols="auto" class="mx-auto">
        <v-card class="elevation-0" style="max-width: 800px;margin-bottom: 30px">
          <v-card-text>
            <ResetPasswordForm ref="resetPasswordForm" :loading="postResetPasswordRequest.loading"/>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="postResetPassword" class="font-weight-bold white--text" color="primary">
              Richiedi Reset Password
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="auto" class="mx-auto">
        <v-card class="elevation-0" style="max-width: 700px">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="auto">
                  <v-icon large color="success">mdi-check-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex">
                  <h1 class="mb-0 align-self-center"> Reset Password Effettuato con successo.</h1>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto">
                  <v-icon large color="warning">mdi-alert-circle-outline</v-icon>
                </v-col>
                <v-col class="d-flex">
                  <p class="mb-0 align-self-center">Ora puoi effettuare l'accesso usando le nuove credenziali cliccando <router-link :to="{ name: 'Login'}">qui</router-link></p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ResetPasswordForm from "@/views/ecommerce/reset_password/Components/ResetPasswordForm";
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";

export default {
  name: "ResetPassword",
  mixins: [StoreComputed, ResponseErrorHandler],
  components: {ResetPasswordForm},
  data: () => ({
    postResetPasswordRequest: {loading: false},
    resetPasswordCompleted: false
  }),
  methods: {
    postResetPassword: function () {
      if (this.$refs.resetPasswordForm.$refs.form.validate()) {
        this.postResetPasswordRequest = true;
        this.axios.post(this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/reset_password', {
              data: {
                token: this.$route.params.token,
                password: this.$refs.resetPasswordForm.data.password
              }
            },
            {
              headers: {
                Authorization: 'Bearer ' + this.user.jwtToken,
                Accept: 'application/json'
              }
            }
        ).then(() => {
          this.resetPasswordCompleted = true;
        }).catch(error => {
          this.handleResponseError(error);
        }).finally(() => {
          this.postResetPasswordRequest = false;
        })
      }
    }
  }
}
</script>

<style scoped>

</style>