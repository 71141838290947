let StoreComputed = {
    computed: {
        config() {
            return this.$store.state.config;
        },
        snackbar() {
            return this.$store.state.snackbar;
        },
        user() {
            return this.$store.state.user;
        },
        channel() {
            return this.$store.state.channel;
        },
        commons() {
            return this.$store.state.commons;
        },
        cart() {
            return this.$store.state.cart;
        },
        application() {
            return this.$store.state.application;
        },
        shoppingList() {
            return this.$store.state.shoppingList;
        },
        channelCustomCategories() {
            return this.$store.state.channelCustomCategories
        }


    }
}

export default StoreComputed;