<template>
  <v-app id="app">
    <v-dialog v-model="noEcommerceModal" max-width="400">
      <v-card>
        <v-toolbar dense class="customGreen">
          <v-toolbar-title class="Raleway font-weight-bold" style="color: white">Io Sono Lucano</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          <h3 class="Raleway font-weight-bold text-center">Grazie di aver visitato il nostro store.</h3>
          <p class="pt-5 text-center">Attualmente non è possibile effettuare ordini, vi preghiamo di tornare a
            trovarci.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text tile color="customGreen" @click="noEcommerceModal = !noEcommerceModal">
            CHIUDI
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.show" top :color="snackbar.color" :timeout="3000" tile>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
            :color="snackbar.color"
            fab
            x-small
            dark
            v-bind="attrs"
            @click="$store.commit('snackbar/update', { show: false })"
            class="elevation-6"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-progress-linear
        v-if="isSomethingLoading && preFlightChecklistsPassed !== false"
        :height="4"
        :indeterminate="true"
        color="customGreen"
    >
    </v-progress-linear>
    <Ecommerce
        v-else-if="preFlightChecklistsPassed && isSomethingLoading === false"
    ></Ecommerce>
  </v-app>
</template>

<script>
import Ecommerce from "@/views/ecommerce/Ecommerce";
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";

import CartItems from "@/mixins/rest_api/CartItems"
import Categories from "@/mixins/rest_api/Categories"
import FilterableCategories from "@/mixins/rest_api/FilterableCategories"
import ProductCertifications from "@/mixins/rest_api/ProductCertifications"
import Provinces from "@/mixins/rest_api/Provinces"
import Regions from "@/mixins/rest_api/Regions"
import ShoppingLists from "@/mixins/rest_api/ShoppingLists"
import User from "@/mixins/rest_api/User"
import Status from "@/mixins/rest_api/Status"


import UnitsOfMeasure from "@/mixins/rest_api/UnitsOfMeasure"

export default {
  name: "Index",
  components: {Ecommerce},
  mixins: [StoreComputed, ResponseErrorHandler, Categories, User, FilterableCategories, CartItems, ProductCertifications, Provinces, Regions, UnitsOfMeasure, Status, ShoppingLists],
  data: () => ({

    noEcommerceModal: false,


    preFlightChecklistsPassed: null,
    cartItemsIncludes: ['catalog_product', 'catalog_product.product', 'catalog_product.product.business', 'catalog_product.product.images','catalog_product.product.stock'],
    getStatusRequest: {loading: true},
    getUserRequest: {loading: true},
    getUnitsOfMeasureRequest: {loading: true},
    getCategoriesRequest: {loading: true},
    getFilterableCategoriesRequest: {loading: true},
    getProductCertificationsRequest: {loading: true},
    getProvincesRequest: {loading: true},
    getRegionsRequest: {loading: true},
    getCartItemsRequest: {loading: true},
    getShoppingListsRequest: {loading: true}
  }),
  created() {
    let self = this;
    this.executeUserPreflightCheckList()
        .then(self.executeCommonsPreflightCheckList())
        .then(self.syncCartItems)
        .then(self.setCartItems)
        //.then(self.setShoppingLists)
        .then(() => {
          self.preFlightChecklistsPassed = true;
        })
        .catch(err => {
          self.preFlightChecklistsPassed = false;
          this.handleResponseError(err)
        });

  },
  methods: {
    executeUserPreflightCheckList: function () {
      let self = this;
      return new Promise(function (resolve, reject) {
        if (!localStorage.getItem('ecommerce-coldiretti-access-token')) {
          self.getStatusRequest.loading = false;
          self.getUserRequest.loading = false;
          resolve();
        } else {
          self.$store.commit('user/update', {jwtToken: localStorage.getItem('ecommerce-coldiretti-access-token')});
          self.getStatus()
              .then(statusResponse => {
                self.getStatusRequest.loading = false;
                self.$store.commit('user/update', {
                  id: statusResponse.data.user.id,
                  isAuthenticated: true
                });
                return self.getUser(self.user.id);
              })
              .then((userResponse) => {
                self.getUserRequest.loading = false;
                let user = userResponse.data.data.user;
                self.$store.commit('user/update', {
                  firstName: user.first_name,
                  lastName: user.last_name,
                  code_anagen: user.code_anagen,
                  approved: user.approved,
                  role: user.role,
                  email: user.email
                });
              })
              .catch(error => {
                reject(error)
              })
              .finally(() => {
                resolve();
              });
        }
      });
    },
    executeCommonsPreflightCheckList: function () {
      let self = this;
      return new Promise(function (resolve, reject) {
        self.getCategories()
            .then((categoriesResponse) => {
              self.getCategoriesRequest.loading = false;
              self.$store.commit("commons/setCategories", categoriesResponse.data.data.map(function (category) {
                return category.category;
              }));
              return self.getFilterableCategories();
            })
            .then((filterableCategoriesResponse) => {
              self.getFilterableCategoriesRequest.loading = false;
              self.$store.commit("commons/setFilterableCategories", filterableCategoriesResponse.data.data.map(function (filterableCategory) {
                return filterableCategory.filterable_category;
              }));
              return self.getUnitsOfMeasure();
            })
            .then((unitsOfMeasureResponse) => {
              self.getUnitsOfMeasureRequest.loading = false;
              self.$store.commit("commons/setUnitsOfMeasure", unitsOfMeasureResponse.data.data.map(function (unitOfMeasure) {
                return unitOfMeasure.unit_of_measure;
              }));
              return self.getProductCertifications();
            })
            .then((productCertificationsResponse) => {
              self.getProductCertificationsRequest.loading = false;
              self.$store.commit("commons/setProductCertifications", productCertificationsResponse.data.data.map(function (productCertification) {
                return productCertification.certification;
              }));
              return self.getProvinces()
            })
            .then((provincesResponse) => {
              self.getProvincesRequest.loading = false;
              self.$store.commit("commons/setProvinces", provincesResponse.data.data.map(function (province) {
                return province.province;
              }));
              return self.getRegions()
            })
            .then((regionsResponse) => {
              self.getRegionsRequest.loading = false;
              self.$store.commit("commons/setRegions", regionsResponse.data.data.map(function (region) {
                return region.region;
              }));
            })
            .catch(error => {
              reject(error)
            })
            .finally(() => {
              resolve();
            })
      });
    },
    setShoppingLists: function () {
      let self = this;
      return new Promise(function (resolve, reject) {
        if (!localStorage.getItem('ecommerce-coldiretti-access-token')) {
          self.getShoppingListsRequest.loading = false;
          resolve();
        } else {
          self.getShoppingListsRequest.loading = true;
          self.getShoppingLists()
              .then(data => {
                self.getShoppingListsRequest.loading = false;
                let shopping_lists = [];
                data.data.data.forEach(element => {
                  shopping_lists.push(element.shopping_list);
                });
                self.$store.commit('shoppingList/setShoppingLists', shopping_lists);
              })
              .catch(error => {
                reject(error);
              })
              .finally(() => {
                resolve();
              });
        }

      });
    },
    syncCartItems: function () {
      let self = this;
      return new Promise(function (resolve) {
        if (self.user.isAuthenticated) {
          let localStorageCartItems = JSON.parse(localStorage.getItem('mercati-campagnamica-guest-cart'));
          if (localStorageCartItems.length > 0) {
            let cartItemsRequests = [];
            localStorageCartItems.forEach(cartItem => {
              cartItemsRequests.push(self.postCartItem(cartItem.catalog_product_id, cartItem.quantity))
            })
            Promise.allSettled(cartItemsRequests).then(() => {
              localStorage.setItem('mercati-campagnamica-guest-cart', JSON.stringify([]));
              resolve();
            })
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      })
    },
    setCartItems: function () {
      let self = this;
      return new Promise(function (resolve, reject) {
        if (!self.user.isAuthenticated) {
          if (!localStorage.getItem('mercati-campagnamica-guest-cart')) {
            localStorage.setItem('mercati-campagnamica-guest-cart', JSON.stringify([]));
          }
          self.$store.commit('cart/setCartItems', JSON.parse(localStorage.getItem('mercati-campagnamica-guest-cart')));
          let catalogProductsIds = self.cart.cartItems.map((cartItem) => {
            return cartItem.catalog_product_id;
          })
          if (catalogProductsIds.length > 0) {
            self.getCartItemsRequest.loading = true;
            self.axios
                .get(self.config.ecommerce_service_url +
                    "/api/v1/channels/" +
                    self.channel.id + '/products?catalog_product_id=' + catalogProductsIds.join(',') + '&includes=product,product.business,product.images')
                .then(response => {
                  let catalogProducts = response.data.data.map((catalogProduct) => {
                    return catalogProduct.catalog_product
                  })
                  let refreshedCartItems = self.cart.cartItems.map((cartItem) => {
                    let cartItemRelatedCatalogProduct = catalogProducts.find(catalogProduct => catalogProduct.id === cartItem.catalog_product_id)
                    if (cartItemRelatedCatalogProduct !== undefined) {
                      delete cartItem.catalog_product;
                      cartItem.catalog_product = cartItemRelatedCatalogProduct;
                      return cartItem
                    }
                  })
                  refreshedCartItems = refreshedCartItems.filter(val => val !== undefined)
                  self.$store.commit("cart/setCartItems", refreshedCartItems);
                })
                .catch(error => {
                  self.handleResponseError(error)
                }).finally(() => {
              self.getCartItemsRequest.loading = false;
            })
          }
          self.getCartItemsRequest.loading = false;
          resolve();
        } else {
          self.getCartItemsRequest.loading = true;
          self.getCartItems(self.cartItemsIncludes)
              .then(data => {
                self.getCartItemsRequest.loading = false;
                let cart_items = [];
                data.data.data.forEach(element => {
                  cart_items.push(element.cart_item);
                });
                self.$store.commit('cart/setCartItems', cart_items);
              })
              .catch(error => {
                reject(error);
              })
              .finally(() => {
                resolve();
              });
        }

      });
    },
  },
  computed: {
    isSomethingLoading: function () {
      return (
          this.getStatusRequest.loading === true ||
          this.getUserRequest.loading === true ||
          this.getUnitsOfMeasureRequest.loading === true ||
          this.getCategoriesRequest.loading === true ||
          this.getFilterableCategoriesRequest.loading === true ||
          this.getProductCertificationsRequest.loading === true ||
          this.getProvincesRequest.loading === true ||
          this.getRegionsRequest.loading === true ||
          this.getCartItemsRequest === true
      );
    },
  },
};
</script>

<style scoped>
</style>