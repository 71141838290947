<template>
  <v-card class="elevation-0">
    <avatar-picker
        v-model="showAvatarPicker"
        :current-avatar="form.avatar"
        @selected="selectAvatar"
    ></avatar-picker>
    <v-container fluid>
      <v-row>
        <v-col cols="9">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Nome</v-list-item-title>
                <v-list-item-subtitle v-text="user.firstName"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Cognome</v-list-item-title>
                <v-list-item-subtitle v-text="user.lastName"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle v-text="user.email"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions v-if="false">
      <v-spacer></v-spacer>
      <v-btn @click="openAvatarPicker">Modifica Password</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AvatarPicker from "../../components/AvatarPicker";
import StoreComputed from "@/mixins/StoreComputed";

export default {
  name: "AccountPersonalInformation",
  components: {AvatarPicker},
  mixins: [StoreComputed],
  data() {
    return {
      loading: false,
      form: {
        nome: "John",
        cognome: "Doe",
        email: "john@doe.com",
        codiceFiscale: "SADKASKDSAKDSAKDKASD",
        avatar: "MALE_CAUCASIAN_BLOND_BEARD",
      },
      showAvatarPicker: false,
    };
  },
  methods: {
    openAvatarPicker() {
      this.showAvatarPicker = true;
    },
    selectAvatar(avatar) {
      this.form.avatar = avatar;
    },
  },
}
</script>

<style scoped>

</style>