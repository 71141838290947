import _ from 'lodash'

const state = () => ({
    months: [
        {id: 1, name: 'Gennaio'},
        {id: 2, name: 'Febbraio'},
        {id: 3, name: 'Marzo'},
        {id: 4, name: 'Aprile'},
        {id: 5, name: 'Maggio'},
        {id: 6, name: 'Giugno'},
        {id: 7, name: 'Luglio'},
        {id: 8, name: 'Agosto'},
        {id: 9, name: 'Settembre'},
        {id: 10, name: 'Ottobre'},
        {id: 11, name: 'Novembre'},
        {id: 12, name: 'Dicembre'}],
    unitsOfMeasure: [],
    categories: [],
    filterableCategories: [],
    orderStatuses: [],
    orderStatusesColors: [
        {id: '1', color: 'light-grey'},
        {id: '2', color: 'info'},
        {id: '3', color: 'success'},
        {id: '4', color: 'error'}
    ],
    orderGroupStatusesColors: [
        {id: 1, color: 'light-grey'},
        {id: 2, color: 'info'},
        {id: 3, color: 'success'},
        {id: 4, color: 'error'}
    ],
    provinces: [],
    businessTypes: [],
});

const getters = {
    selectablePaymentMethods: state => {
        return _.map(state.paymentMethods, (paymentMethod) => {
            return {
                text: paymentMethod.label,
                value: paymentMethod.id,
            }
        });
    },
    orderGroupColor: (state) => (id) => {
        return state.orderGroupStatusesColors.find(orderGroupStatusColor => orderGroupStatusColor.id === id)
    },
    paymentMethod: (state) => (id) => {
        return state.paymentMethods.find(paymentMethod => paymentMethod.id === id)
    },
    selectableCategories: state => {
        let childCategories = _.filter(state.categories, function (o) {
            return o.parent_category_id !== '';
        });
        return _.map(childCategories, (category) => {
            return {text: category.name, value: category.id}
        });
    },
    selectableProductCertifications: state => {
        return _.map(state.productCertifications, (productCertification) => {
            return {
                text: productCertification.name,
                value: productCertification.id
            }
        });
    },
    selectableFilterableCategories: state => {
        let childCategories = _.filter(state.filterableCategories, function (o) {
            return o.parent_filterable_category_id !== '';
        });
        return _.map(childCategories, (category) => {
            return {text: category.name, value: category.id}
        });
    },
    selectableUnitsOfMeasure: state => {
        return _.map(state.unitsOfMeasure, (unitOfMeasure) => {
            return {
                text: unitOfMeasure.foreign_name,
                value: unitOfMeasure.id,
                symbol: unitOfMeasure.symbol
            }
        });
    },
    selectableOrderStatuses: state => {
        return _.map(state.orderStatuses, (orderStatus) => {
            return {
                text: orderStatus.label,
                value: orderStatus.id,
            }
        });
    },
    selectableProvinces: state => {
        return _.map(state.provinces, (province) => {
            return {
                region: province.region,
                text: province.name,
                value: province.id
            }
        });
    },
    selectableBusinessTypes: state => {
        return _.map(state.businessTypes, (businessType) => {
            return {
                text: businessType.label,
                value: businessType.id
            }
        });
    },
    orderStatus: (state) => (id) => {
        return state.orderStatuses.find(orderStatus => orderStatus.id === id)
    },
    orderColor: (state) => (id) => {
        return state.orderStatusesColors.find(orderStatusColor => orderStatusColor.id === id)
    },
    unitOfMeasure: (state) => (id) => {
        return state.unitsOfMeasure.find(unitOfMeasure => unitOfMeasure.id === id)
    },
    category: (state) => (id) => {
        return state.categories.find(category => category.id === id)
    },
    month: (state) => (id) => {
        return state.months.find(month => month.id === id)
    },
    filterableCategory: (state) => (id) => {
        return state.filterableCategories.find(filterableCategory => filterableCategory.id === id)
    },
    productCertification: (state) => (id) => {
        return state.productCertifications.find(productCertification => productCertification.id === id)
    },
    province: (state) => (id) => {
        return state.provinces.find(province => province.id === id)
    },
    businessType: (state) => (id) => {
        return state.businessTypes.find(businessType => businessType.id === id)
    },
    structuredFilterableCategories: state => {
        let gerarchyFilterableCategory = [];
        let parentFilterableCategories = _.filter(state.filterableCategories, function (o) {
            return o.parent_filterable_category_id === '';
        });
        parentFilterableCategories.forEach(parentFilterableCategory => {
            let parentFilterableCategoryCopy = Object.assign({}, parentFilterableCategory);
            parentFilterableCategoryCopy.childrens = _.filter(state.filterableCategories, function (o) {
                return o.parent_filterable_category_id === parentFilterableCategory.id;
            });
            gerarchyFilterableCategory.push(parentFilterableCategoryCopy);
        });
        return gerarchyFilterableCategory;
    },
    childrenFilterableCategories: (state) => (parentFilterableId) => {
        return state.filterableCategories.filter(filterableCategory => {
            return filterableCategory.parent_filterable_category_id === parentFilterableId

        });
    }
};
const actions = {};
const mutations = {
    setPaymentMethods(state, paymentMethods) {
        state.paymentMethods = paymentMethods;
    },
    setUnitsOfMeasure(state, unitsOfMeasure) {
        state.unitsOfMeasure = unitsOfMeasure;
    },
    setCategories(state, categories) {
        state.categories = categories;
    },
    setFilterableCategories(state, filterableCategories) {
        state.filterableCategories = filterableCategories;
    },
    setProductCertifications(state, productCertifications) {
        state.productCertifications = productCertifications;
    },
    setOrderStatuses(state, orderStatuses) {
        state.orderStatuses = orderStatuses;
    },
    setProvinces(state, provinces) {
        state.provinces = provinces;
    },
    setRegions(state, regions) {
        state.regions = regions;
    },
    setBusinessTypes(state, businessTypes) {
        state.businessTypes = businessTypes;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}