import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css';
import it from 'vuetify/es5/locale/it';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#C2CF1A',
                secondary: '#fed91f',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                customGreen: '#C2CF1A',
                customBlue: '#0F53A1',
                customGrey: '#535959'
            },
        },
    },
    lang: {
        locales: {it},
        current: 'it',
    }
});

