<template>
  <v-container fuild style="max-width:1280px">
    <v-row>
      <v-col cols="12" xl="2" lg="3" md="4">
        <v-sheet>
          <v-list color="transparent" dense>
            <v-list-item
                v-for="accountLink in accountLinks"
                :key="accountLink.label"
                link
                :to="accountLink.to"
                active-class=""
            >
              <v-list-item-avatar>
                <v-icon
                    v-text="accountLink.icon"
                    class="primary"
                    dark
                ></v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ accountLink.label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" xl="10" lg="9" md="8" sm="12">
        <v-sheet min-height="70vh">
          <router-view></router-view>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StoreComputed from "../../../mixins/StoreComputed";

export default {
  pageTitle: "My Profile",
  mixins: {StoreComputed},
  data: () => ({
    accountLinks: [
      {
        to: {name: 'AccountPersonalInformation'},
        label: 'Informazioni Personali',
        icon: 'mdi-account'
      },
      {
        to: {name: 'AccountOrders'},
        label: 'Ordini',
        icon: 'mdi-file-document-multiple-outline'
      },
      {
        to: {name: 'AccountAddresses'},
        label: 'Indirizzi Di Spedizione',
        icon: 'mdi-map-marker-outline'
      },
      {
        to: {name: 'AccountBillingAddresses'},
        label: 'Indirizzi Di Fatturazione',
        icon: 'mdi-map-marker-outline'
      },
    ]
  })
};
</script>
<style lang="scss">


</style>