import { render, staticRenderFns } from "./BusinessRecap.vue?vue&type=template&id=43aa592a&scoped=true&"
import script from "./BusinessRecap.vue?vue&type=script&lang=js&"
export * from "./BusinessRecap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43aa592a",
  null
  
)

export default component.exports