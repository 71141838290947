<template>
  <v-container fluid style="max-width:1280px">
    <template v-if="checkOutCompletedDialog">
      <v-row>
        <v-col cols="12" class="d-flex justify-center mt-10">
          <v-card max-width="400px" tile>
            <v-card-title class="roboto font-weight-medium primary">
              Ordine Generato
            </v-card-title>
            <v-card-text class="text-center pa-3">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
              <p class="roboto font-weight-medium pt-3">Stai per essere redirezionato alla pagina di pagamento</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-row v-if="!checkOutCompletedDialog">
      <v-col cols="12" md="8">
        <v-stepper v-model="checkoutStep" vertical class="elevation-0">
          <v-stepper-step :complete="checkoutStep > 1" step="1" color="primary">
            Scegli la modalità di consegna
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card>
              <v-container>
                <v-row>
                  <v-col>
                    <v-form>
                      <v-select dense
                                :loading="getChannelDeliveryTypesRequest.loading"
                                :items="selectableDeliveryTypes"
                                v-model="selectedDeliveryTypeId"
                                label="modalità di consegna"
                                clearable
                      >
                      </v-select>
                    </v-form>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-btn color="primary" tile :disabled="selectedDeliveryTypeId === null" @click="checkoutStep = 2">
                  Continua
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-step :complete="checkoutStep > 2" step="2" color="primary">
            Indirizzo di consegna
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card>
              <v-container>
                <v-row>
                  <v-col v-if="checkoutStep === 2">
                    <ShippingAddress v-if="selectedDeliveryTypeId === '1'" ref="address"
                                     v-on:updated:addressData="setShippingAddress"></ShippingAddress>
                    <template v-else-if="checkOutData.shipping_address !== null">
                      <v-row>
                        <v-col cols="12" class="py-0">
                          <p>Potrai ritirare il tuo ordine presso:</p>
                        </v-col>
                        <v-col cols="12" sm="auto" class="py-0 pr-0">
                          <b class="mr-1">{{ addressee }}&nbsp;</b>
                        </v-col>
                        <v-col cols="12" sm="auto" class="py-0">
                          <span>{{shippingAddress}}</span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions class="pt-5">
                <v-btn color="primary" tile :disabled="checkOutData.shipping_address === null"
                       @click="checkoutStep = 3" >
                  Continua
                </v-btn>
                <v-btn color="primary" tile
                       @click="checkoutStep = 1">
                  Indietro
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-step :complete="checkoutStep > 3" step="3" color="primary">
            Opzione di consegna
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card>
              <v-container>
                <v-row>
                  <v-col>
                    <v-form valid="deliveryTypeFormValid" v-if="selectableDeliveries.length !== 0">
                      <v-select dense
                                :loading="getChannelElegibleDeliveries.loading"
                                :items="selectableDeliveries"
                                v-model="checkOutData.delivery_option_id"
                                label="opzione di consegna"
                                clearable 
                                style="white-space: pre-wrap!important;"
                      >
                      </v-select>
                    </v-form>
                    <template v-else>
                      <p><b>Non ci sono opzioni di spedizione compatibili per l'indirizzo selezionato</b></p>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-btn color="primary" tile :disabled="checkOutData.delivery_option_id === null"
                       @click="checkoutStep = 4">
                  Continua
                </v-btn>
                <v-btn color="primary" tile
                       @click="checkoutStep = 2">
                  Indietro
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-step :complete="checkoutStep > 4" step="4" color="primary">
            Indirizzo Di Fatturazione
          </v-stepper-step>
          <v-stepper-content step="4">
            <v-card>
              <v-container>
                <v-row>
                  <v-col>
                    <BillingAddress ref="address" v-on:updated:addressData="setBillingAddress"
                                    v-bind:shippingAddress="checkOutData.shipping_address"
                                    v-bind:deliveryType="selectedDeliveryTypeId"
                    ></BillingAddress>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-btn color="primary" tile :disabled="checkOutData.billing_address === null"
                       @click="checkoutStep = 5">Continua
                </v-btn>
                <v-btn color="primary" tile
                       @click="checkoutStep = 3">
                  Indietro
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-step :complete="checkoutStep > 5" step="5" color="primary">
            Riepilogo Prodotti
          </v-stepper-step>
          <v-stepper-content step="5">
            <v-card class="mb-2 elevation-0" tile :key="'business_card' + cartBusiness.id"
                    v-for="cartBusiness in businessGroupedCartItems">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <CartItem class="mb-3 elevation-0"
                              v-for="(cartItem , index) in cartBusiness.cartItems"
                              :key="index"
                              v-bind:cartItemData="cartItem"></CartItem>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-btn color="primary" @click="checkOut" tile
                   :disabled="isCheckOutDisabled"
                   :loading="isSomethingLoading">Ordina Ora
            </v-btn>
            <v-btn color="primary" tile class="ml-3"
                   @click="checkoutStep = 4">
              Indietro
            </v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-col>
      <v-col cols="12" md="4">
        <v-card tile>
          <v-toolbar dense color="primary" tile>
            <v-toolbar-title>Riepilogo Ordine</v-toolbar-title>
          </v-toolbar>
          <v-list two-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Articoli
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.getters['cart/total'] }} €
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Costi Di Consegna
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ shippingCosts.toFixed(2) }} €
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Totale Ordine
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ (parseFloat($store.getters['cart/total']) + parseFloat(shippingCosts)).toFixed(2) }} €
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-btn tile color="primary" width="100%" class="elevation-0"
                 @click="checkOut"
                 :disabled="isCheckOutDisabled"
                 :loading="isSomethingLoading">
            Ordina Ora
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import ShippingAddress from "./components/ShippingAddress";
import BillingAddress from "./components/BillingAddress";
import CartItem from "../cart/components/CartItem";
import { mapGetters } from "vuex";
import computedMixin from "../../../mixins/StoreComputed";
import responseErrorHandler from "../../../mixins/ResponseErrorHandler";

export default {
  components: {ShippingAddress, BillingAddress, CartItem},
  name: "Index",
  mixins: [computedMixin, responseErrorHandler],
  data: () => ({
    getChannelDeliveryTypesRequest: {loading: true},
    getChannelElegibleDeliveriesRequest: {loading: false},
    postCheckOutRequest: {loading: false, error: false},

    checkOutData: {
      shipping_address: null,
      billing_address: null,
      delivery_option_id: null
    },

    selectedDeliveryTypeId: null,
    selectableDeliveryTypes: [],

    checkoutStep: 1,
    checkOutCompletedDialog: false,
    elegibleDeliveries: [],
    channelWithdrawAddress: {
      "addressee": "Mercato Campagna Amica San Teodoro",
      "country": "IT",
      "city": "Roma",
      "region": "Lazio",
      "district": "RM",
      "postcode": "00186",
      "street_name": "Via di San Teodoro",
      "street_number": "74",
      "apartment": null,
      "notes": null,
      "ssn": null,
      "vat_number": null,
      "sdi_addressee_code": null,
      "billing": false,
      "shipping": true,
    }
  }),
  computed: {
    ...mapGetters("cart", ["total"]),
    isCheckOutDisabled() {
      return this.checkOutData.shipping_address === null || this.checkOutData.billing_address === null || this.checkOutData.delivery_option_id === null || this.checkoutStep !== 5;
    },
    isSomethingLoading() {
      return this.getChannelDeliveryTypesRequest.loading || this.getChannelElegibleDeliveriesRequest.loading || this.postCheckOutRequest.loading
    },
    shippingCosts() {
      if (this.checkOutData.delivery_option_id === null) {
        return 0
      } else {
        return this.elegibleDeliveries.find(o => o.delivery.delivery_option_id === this.checkOutData.delivery_option_id).delivery.cost
      }
    },
    selectableDeliveries() {
      return this.elegibleDeliveries
          .map(elegibleDelivery => {
            return {
              value: elegibleDelivery.delivery.delivery_option_id,
              text: this.getEligibleDeliveryText(elegibleDelivery)
            }
          });
    },
    businessGroupedCartItems() {
      let businesses = [];
      this.cart.cartItems.forEach(cartItem => {
        let businessIndex = this._.findIndex(businesses, function (business) {
          return business.id === cartItem.catalog_product.product.business.id;
        });
        if (businessIndex === -1) {
          let newBusiness = cartItem.catalog_product.product.business;
          newBusiness.cartItems = [];
          newBusiness.cartItems.push(cartItem);
          businesses.push(newBusiness)
        } else {
          businesses[businessIndex].cartItems.push(cartItem);
        }
      });
      return businesses;
    },
    addressee(){
      return this.checkOutData.shipping_address.addressee;
    },
    shippingAddress(){
      return this.checkOutData.shipping_address.street_name + ' ' + this.checkOutData.shipping_address.street_number + ', ' +
          ((this.checkOutData.shipping_address.apartment !== null) ? this.checkOutData.shipping_address.apartment + ', ' : ' ') +
          this.checkOutData.shipping_address.city + ', ' + this.checkOutData.shipping_address.district + ' ' + this.checkOutData.shipping_address.postcode;
    },
  },
  watch: {
    cartTotal:function(newVal){
      console.log(newVal);
    },
    checkoutStep:function(newVal,oldVal){
      if(oldVal === 2 && newVal === 1){
        this.checkOutData.shipping_address = null;
        this.checkOutData.delivery_option_id === null
      }
      if(oldVal === 3 && newVal === 2){
        if(this.selectedDeliveryTypeId === '2'){
          this.checkOutData.shipping_address = this.channelWithdrawAddress;
        }else {
          this.checkOutData.shipping_address = null;
        }
      }
      if(oldVal === 1 && newVal === 2 && this.selectedDeliveryTypeId === '2'){
        this.checkOutData.shipping_address = this.channelWithdrawAddress
      }
    },
    selectedDeliveryTypeId: function (newVal, oldVal) {
      if (newVal !== null && newVal === '2') {
        this.checkOutData.shipping_address = this.channelWithdrawAddress
      }
      if(oldVal !== newVal){
        this.elegibleDeliveries = [];
        this.checkOutData.delivery_option_id = null;
      }
    },
    'checkOutData.shipping_address': function (newVal) {
      if (newVal !== null) {
        this.getChannelElegibleDeliveries()
      }
    }
  },
  methods: {
    getEligibleDeliveryMomentDate(eligibleDelivery){
      return eligibleDelivery?.delivery?.date ? this.moment(eligibleDelivery.delivery.date) : null;
    },
    getEligibleDeliveryMomentDateTxt(momentDate){
      return momentDate ? momentDate.format('dddd l') : "";
    },
    getEligibleDeliveryText(eligibleDelivery){
      const deliveryDate = this.getEligibleDeliveryMomentDate(eligibleDelivery);
      const deliveryLabel = eligibleDelivery?.delivery?.delivery_type?.label;
      const deliveryDateTxt = this.getEligibleDeliveryMomentDateTxt(deliveryDate);
      const deliveryCost = `${eligibleDelivery?.delivery?.cost}€`
      return `${deliveryLabel} - entro ${deliveryDateTxt} - ${deliveryCost}`;
    },
    setShippingAddress: function (address) {
      this.checkOutData.shipping_address = address;
    },
    setBillingAddress: function (address) {
      this.checkOutData.billing_address = address;
    },
    getChannelDeliveryTypes: function () {
      this.getChannelDeliveryTypesRequest.loading = true;
      this.axios.get(this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/delivery_types',
          {
            headers: {
              Authorization: 'Bearer ' + this.user.jwtToken,
              Accept: 'application/json'
            }
          })
          .then(response => {
            this.selectableDeliveryTypes = response.data.data.map(deliveryType => {
              return {text: deliveryType.delivery_type.label, value: deliveryType.delivery_type.id}
            });
          })
          .catch(error => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.getChannelDeliveryTypesRequest.loading = false;
          })
    },
    getChannelElegibleDeliveries: function () {
      this.getChannelElegibleDeliveriesRequest.loading = true;
      this.axios.get(this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/delivery_types/' + this.selectedDeliveryTypeId + '/elegible_deliveries/' + this.checkOutData.shipping_address.postcode,
          {
            headers: {
              Authorization: 'Bearer ' + this.user.jwtToken,
              Accept: 'application/json'
            }
          }).then(response => {
        this.elegibleDeliveries = response.data.data;
      }).catch(error => {
        this.handleResponseError(error);
      }).finally(() => {
        this.getChannelElegibleDeliveriesRequest.loading = false;
      })
    },
    checkOut: function () {
      this.postCheckOutRequest.loading = true;
      return this.axios
          .post(
              this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/checkouts',
              {
                data: this.checkOutData
              },
              {
                headers: {
                  Authorization: 'Bearer ' + this.user.jwtToken,
                  Accept: 'application/json'
                }
              }
          )
          .then((response) => {
            window.location.href = response.data.data.order_group.paypal_payment.approve_link
            this.$store.commit('cart/deleteCartItems');
            this.checkOutCompletedDialog = true;
          })
          .catch(error => {
            console.log(error);
            this.handleResponseError(error);
          })
          .finally(() => {
            this.postCheckOutRequest.loading = false;
          });
    },
  },
  created: function () {
    if (this.cart.cartItems.length === 0) {
      this.$router.push({name: 'Home'})
    }
    this.getChannelDeliveryTypes();
  }
}
</script>

<style scoped>
</style>
