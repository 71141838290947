<template>
    <v-app>
        <v-main>

        </v-main>
    </v-app>
</template>

<script>
    import StoreComputed from "@/mixins/StoreComputed";
    export default { //
        name: "LoginSocio",
        mixins: [StoreComputed],
        created() {
            this.keycloak = new this.keycloakJs({
                url: this.config.keycloak.member.url + '/auth',
                realm: this.config.keycloak.member.realm,
                clientId: this.config.keycloak.member.clientId,
                flow: this.config.keycloak.member.flow,
            });
            this.keycloak.init({
                onLoad: 'login-required',
                checkLoginIframe: false
            }).then((auth) => {
                if (!auth) { 
                    console.log('problema nel login con keycloak');
                } else {
                    localStorage.setItem('ecommerce-coldiretti-access-token', this.keycloak.idToken);
                    this.$router.push({name: 'Home'}).then(() => console.log('LoggedIn Successfully')).catch(e => console.log(e));
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }
</script>