<template>
  <v-form ref="form" v-model="form">
    <v-container>
      <v-row class="text-center justify-center mt-5">
        <v-col cols="12">
          <v-text-field
              :loading="loading"
              :disabled="loading"
              dense
              v-model="data.email"
              label="Email"
              type="email"
              :rules="[value => !!value || 'Inserire l\'indirizzo email', rules.email]"/>
        </v-col>
      </v-row>
      <v-row class="text-center justify-center">
        <v-col class="col-sm-3 col-xs-6">
          <CaptchaImage ref="captchaImg" v-on:createdCaptchaCode="getCaptchaCode"></CaptchaImage>
        </v-col>
        <v-col class="col-sm-5 col-xs-6">
          <v-text-field
              :disabled="loading"
              dense
              v-model="inputCaptcha"
              label="Digitare i caratteri"
              :rules="[value => !!value || 'Inserire il codice mostrato nell\'immagine',checkCaptcha]"
          ></v-text-field>
          <a @click="regenerateCapcha()">
            <v-icon dense color="primary">mdi-reload</v-icon>
            Nuovo codice
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import CaptchaImage from "@/views/ecommerce/signup/components/CaptchaImage";

export default {
  name: "ForgotPasswordForm",
  props: ['loading'],
  components: {CaptchaImage},
  data: () => ({
    form: true,
    rules: {
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Inserire indirizzo email valido'
      },
    },
    codeCaptcha: '',
    inputCaptcha: '',
    data: {email: ''}
  }),
  methods: {
    checkCaptcha: function (value) {
      return value === this.codeCaptcha || 'Codice di verifica errato'
    },
    getCaptchaCode: function (codice) {
      this.codeCaptcha = codice;
    },
    regenerateCapcha: function () {
      this.inputCaptcha = "";
      this.$refs.captchaImg.createCaptcha();
    },
  }
}
</script>

<style scoped>

</style>