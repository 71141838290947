<template>
  <v-card v-bind:class="{ card: true , default: address.default}" outlined height="100%">
    <v-responsive :aspect-ratio="1" class="d-flex">
      <v-card-title dense flat class="px-3 align-self-end">
        <v-btn
            v-if="address.default"
            dark
            color="primary"
            x-small
            class="elevation-0"
        >
          <v-icon left small>
            mdi-star
          </v-icon>
          Predefinito
        </v-btn>
        <v-btn
            plain
            text
            v-else
            color="primary"
            x-small
            @click="$emit('address:setDefault',address.id)"
        >
          Imposta Come Predefinito
        </v-btn>
      </v-card-title>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title class="headline mb-4">
            {{ address.addressee }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ address.street_name }} {{ address.street_number }}, {{address.postcode}}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ address.city }} , {{address.district}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions class="card-actions px-3">
        <v-btn
            color="primary"
            x-small
            class=""
            outlined
            @click="$router.push({
                    name: 'AccountBillingAddressesEdit',
                    params: { addressId: address.id },
                  })"
        >
          Modifica
        </v-btn>
        <v-btn
            outlined
            color="primary"
            x-small
            @click="$router.push({
                    name: 'AccountBillingAddressesDelete',
                    params: { addressId: address.id },
                  })"
        >
          Rimuovi
        </v-btn>
      </v-card-actions>
    </v-responsive>
  </v-card>
</template>

<script>
export default {
  name: "AddressCard",
  props: ['address'],
}
</script>

<style scoped lang="scss">
.card {
  position: relative;

  &.default {
    border-left: 5px solid #8d2a50 !important;
  }
}


.card-actions {
  position: absolute;
  bottom: 0;
}
</style>