import Vue from 'vue'
import _ from 'lodash';

const state = () => ({
    cartItems: []
});

const getters = {
    total: (state, getters, rootState, rootGetters) => {
        let total = 0;
        state.cartItems.forEach(cartItem => {
            let cartItemTotal = (parseFloat(cartItem.catalog_product.value) * parseFloat(cartItem.quantity) * (
                    1 +
                    parseFloat(rootGetters['commons/category'](cartItem.catalog_product.product.category_id).vat_percentage) / 100)
            );
            total = total + cartItemTotal;
        });
        return total.toFixed(2);
    },
    subtotal: (state) => {
        let subtotal = 0;
        state.cartItems.forEach(cartItem => {
            subtotal = subtotal + parseFloat(cartItem.catalog_product.value) * parseFloat(cartItem.quantity);
        });
        return subtotal.toFixed(2);
    }
};
const actions = {};
const mutations = {
    empty(state) {
        state.cartItems = [];
    },
    setCartItems(state, cartItems) {
        state.cartItems = cartItems;
    },
    removeCartItem(state, cartItemId) {
        let CartItemIndexToRemove = _.findIndex(state.cartItems, function (o) {
            return o.id === cartItemId;
        });
        state.cartItems.splice(CartItemIndexToRemove, 1)
    },
    updateCartItem(state, cartItem) {
        let CartItemIndexToUpdate = _.findIndex(state.cartItems, function (o) {
            return o.id === cartItem.id;
        });
        Vue.set(state.cartItems, CartItemIndexToUpdate, cartItem);

    },
    addCartItem(state, cartItem) {
        state.cartItems.push(cartItem);
    },
    deleteCartItems(state) {
        state.cartItems = [];
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}