export default {
    namespaced: true,
    state: () => ({
        orders: [],
    }),
    getters: {
        orders: (state) => state.orders,
    },
    actions: {
        setOrders({ commit }, orders) {
            commit('SET_ORDERS', orders);
        },
        cleanOrders({ commit }) {
            commit('SET_ORDERS', []);
        },
        updateOrderData({ commit }, updatedOrder) {
            commit('UPDATE_ORDER_DATA', updatedOrder);
        },
    },
    mutations: {
        SET_ORDERS(state, orders) {
            state.orders = orders === null ? [] : [...orders];
        },
        UPDATE_ORDER_DATA(state, updatedOrder) {
            const orderToUpdateIndex = state.orders.findIndex( so => parseInt(so.id) === parseInt(updatedOrder.id));
            if(orderToUpdateIndex === -1){
                console.log("appending order group....");
                state.orders = [...state.orders, {updatedOrder}];
            }
            else{
                console.log("Replacing order group ", orderToUpdateIndex);
                state.orders[orderToUpdateIndex] = updatedOrder;
                state.orders = [...state.orders];
            }
        },
    },
};
