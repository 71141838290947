let unitsOfMeasures = {
    methods: {
        getUnitsOfMeasure: function () {
            return this.axios
                .get(
                    this.config.ecommerce_service_url +
                    "/api/v1/channels/" + this.channel.id + "/unit_of_measures?symbol=l,cl,ml,kg,g",
                    {
                        headers: {
                            //Authorization: "Bearer " + this.user.jwtToken,
                            Accept: "application/json",
                        },
                    }
                )
        },
    }
};
export default unitsOfMeasures