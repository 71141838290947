<template>
  <v-list two-line>
    <template v-for="(product, index) in products">
      <v-list-title :key="product.shopping_list_item.id" avatar>
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                {{ product.shopping_list_item.catalog_product.product.name }}
              </v-list-item-title>
              <v-list-item-subtitle
                >{{
                  product.shopping_list_item.catalog_product.product.description
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions class="justify-center">
            <v-btn
              outlined
              rounded
              text
              @click="cutShoppingList(product.shopping_list_item.id)"
            >
              <v-icon>mdi-minus-circle-outline</v-icon>
            </v-btn>    
          </v-card-actions>
          <v-row>
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" style="width: 20%">Costo</th>
                  <th class="text-left" style="width: 20%">Quantità</th>
                  <th class="text-left" style="width: 20%">Iva</th>
                  <th class="text-left" style="width: 20%">Imponibile</th>
                  <th class="text-right" style="width: 20%">Totale</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{
                      product.shopping_list_item.catalog_product.product.packaged
                        ? parseFloat(
                            product.shopping_list_item.catalog_product.value
                          ).toFixed(2) + "€"
                        : getProductPrice(
                            product.shopping_list_item.catalog_product.product,
                            product.shopping_list_item.catalog_product
                          )
                     }} 
                  </td>
                  <td>
                    <template
                      v-if="product.shopping_list_item.catalog_product.product.packaged"
                    >
                      {{ Math.floor(parseInt(product.shopping_list_item.quantity)) }} pz
                    </template>
                    <template v-else>
                      {{ parseFloat(product.shopping_list_item.quantity).toFixed(2)
                      }}{{
                        $store.getters["commons/unitOfMeasure"](
                          product.shopping_list_item.catalog_product.product
                            .unit_of_measure_id
                        ).symbol
                      }}
                    </template>
                  </td>
                  <td>
                    {{
                      parseFloat(
                        $store.getters["commons/category"](
                          product.shopping_list_item.catalog_product.product.category_id
                        ).vat_percentage
                      ).toString()
                    }}
                    %
                  </td>
                  <td>
                    {{
                      (
                        parseFloat(product.shopping_list_item.catalog_product.value) *
                        parseFloat(product.shopping_list_item.quantity)
                      ).toFixed(2)
                    }}€
                  </td>
                  <td class="text-right">
                    {{
                      (
                        (parseFloat(product.shopping_list_item.catalog_product.value) *
                          parseFloat(product.shopping_list_item.quantity)) /
                        (1 -
                          parseFloat(
                            $store.getters["commons/category"](
                              product.shopping_list_item.catalog_product.product.category_id
                            ).vat_percentage
                          ) /
                            100)
                      ).toFixed(2)
                    }}
                    €
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
        </v-card>
      </v-list-title>
    </template>
  </v-list>
</template>

<script>
import Product from "@/mixins/Product";
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";

export default {
  name: "shoppingListProduct",
  props: ["products"],
  mixins: [StoreComputed, ResponseErrorHandler],
  components: { Product },
  methods: {
    cutShoppingList: function (ItemId) {
      this.axios
        .delete(
          this.config.ecommerce_service_url +
            "/api/v1/users/" +
            this.user.id +
            "/channels/" +
            this.config.channel.id +
            "/shopping_lists/" +
            this.products[0].shopping_list_item.shopping_list_id +
            "/shopping_list_items/" +
            ItemId,
          {
            headers: {
              Authorization: "Bearer " + this.user.jwtToken,
              Accept: "application/json",
            },
          }
        )
        .then(() => {
          this.$emit("shoppingList:deleted");
          this.$store.commit("snackbar/update", {
            show: true,
            text: "Prodotto eliminato dalla shopping list",
            color: "success",
          });
        })
        .catch((error) => {
          this.handleResponseError(error);
        })
        .finally(() => {
          this.$store.commit("shoppingList/removeShoppingListItem", ItemId);
        });
    },
  },
  created() {},
};
</script>

<style scoped lang="scss">
</style>