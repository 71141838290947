<template>
  <v-row class="my-5">
    <v-col cols="12">
      <h1 class="text-uppercase font-weight-thin mr-3">I nostri prodotti</h1>
      <v-col class="border-shadow pa-n6"></v-col>
    </v-col>
    <v-col class="text-center" cols="12" v-if="getProductsRequest.loading">
      <v-progress-circular indeterminate color="customGreen"></v-progress-circular>
    </v-col>
    <template v-else>
      <v-col cols="12" v-if="businessCatalogProducts.length > 0">
        <v-row>
          <v-col v-for="(catalogProduct, index) in businessCatalogProducts" :key="index"
                 class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 px-3 pb-6 d-flex">
            <product-card  :key="index"
                          :catalogProduct="catalogProduct">
            </product-card>
            </v-col>
        </v-row>
        <v-row v-if="pages.length > 1">
          <v-col class="text-center middle-black-line" style="position:relative">
            <template v-for="(page, index) in pages">
              <v-btn :key="index" tile text
                     @click="setBusinessProducts(12, page)"
                     class="elevation-0 white"
              >
                {{ page }}
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-else>
        Nessun prodotto disponibile al momento
      </v-col>
    </template>
  </v-row>
</template>

<script>
import StoreComputed from '@/mixins/StoreComputed';
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import ProductCard from "@/views/ecommerce/components/product/ProductCard";

export default {
  name: 'BusinessProducts',
  mixins: [StoreComputed, ResponseErrorHandler],
  components: {ProductCard},
  props: {
    business: Object
  },
  data: () => ({
    businessProductsIncludes: ['product.business', 'product.images', 'product.certifications', 'product.unit_of_measure', 'product.specification_sheet','product.stock'],
    getProductsRequest: {loading: false},
    pagination: {total: null, last_page: null, current_page: null},
    businessCatalogProducts: [],
  }),
  computed: {
    pages: function () {
      let pages = [];
      for (let i = 1; i <= this.pagination.last_page; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  created() {
    this.setBusinessProducts(12, 1)
  },
  methods: {
    setBusinessProducts(limit, page) {
      this.getProductsRequest.loading = true;
      this.getBusinessProducts(limit, page)
          .then((data) => {
            this.businessCatalogProducts = [];
            data.data.data.forEach(element => {
              this.businessCatalogProducts.push(element.catalog_product)
            });
            let meta = data.data.meta;
            this.pagination = {total: meta.total, last_page: meta.last_page, current_page: meta.current_page}
            this.getProductsRequest.loading = false;
          })
          .catch(error => {
            this.handleResponseError(error);
          })
          .finally(() => {
            this.getProductsRequest.loading = false;
          });
    },
    getBusinessProducts: function (limit, page) {
      return this.axios.get(this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/products', {
        params: {
          product_business_id: this.business.id,
          page: page,
          limit: limit,
          includes: this.businessProductsIncludes.join(',')
        },
        headers: {
          Authorization: "Bearer " + this.user.jwtToken,
          Accept: "application/json",
        }
      })
    },
  },
}
</script>

<style scoped>
</style>