let orderLine = {
    data: () => ({
        weightMeasures: ['kg', 'g'],
        volumMeasures: ['l', 'ml', 'cl'],
    }),
    methods: {
        getOrderLinePackageDescription: function (order_line) {
            if (order_line.product_packaged === 0) {
                if (this.weightMeasures.includes(order_line.unit_of_measure)) {
                    return 'A peso';
                } else {
                    return 'Alla mescita';
                }
            } else {
                if (parseInt(order_line.product_pieces) === 1) {
                    return order_line.product_short_description + ' ' + parseFloat(order_line.product_quantity).toString() + order_line.unit_of_measure
                } else {
                    return order_line.product_short_description + ' ' + order_line.product_pieces + ' x ' + parseFloat(order_line.product_quantity).toString() + order_line.unit_of_measure
                }
            }
        },
    }

};
export default orderLine