<template>
  <v-card
      class="card"
      @click="$router.push({name:'AccountAddressesCreate'})"
  >
    <v-responsive :aspect-ratio="1" class="align-center justify-center">
      <v-icon large>
        mdi-plus-thick
      </v-icon>
      <p  class="font-weight-bold grey--text">Aggiungi Indirizzo</p>
    </v-responsive>
  </v-card>
</template>

<script>
export default {
  name: "CreateAddressCard"
}
</script>

<style scoped lang="scss">
.card {
  border-style: dashed;
  border-width: 2px;
  border-color: var(--v-primary-base) !important;
  text-align: center;
  vertical-align: middle;
}

</style>