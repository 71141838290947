const state = () => ({
    id: window.appConfig.channel.id
});

const getters = {};
const actions = {};
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}