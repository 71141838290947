<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  data: () => ({}),
};
</script>
<style>
  .border-shadow {
    -webkit-box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 1)!important;
    -moz-box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 1)!important;
    box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 1)!important;
  }

  .custom button {
    background-color: #8d2a50;
  }

  .custom button.v-carousel__controls__item.v-btn.v-item--active.v-btn--active.v-btn--icon,
  .custom .v-btn--active:before {
    background-color: transparent;
    color: #8d2a50;
  }

  .custom button.v-carousel__controls__item.v-btn.v-btn--icon.v-btn--round,
  .custom .v-btn--active:before {
    background-color: transparent;
    color: #8d2a50;
  }

  .Raleway {
    font-family: 'Raleway', sans-serif;
  }
</style>
 