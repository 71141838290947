import _ from "lodash";

const state = () => ({
        defaultFilterableCategories: [],
        channelCustomCategories: [
            {
                relatedDefaultFilterableCategories: [6, 3, 2],
                name: "Vino e olio",
                parentCategoryId: null,
                id: 1
            },
            {relatedDefaultFilterableCategories: [6], name: "Olio e Olive", parentCategoryId: 1, id: 2},
            {relatedDefaultFilterableCategories: [3], name: "Aceto", parentCategoryId: 1, id: 3},
            {relatedDefaultFilterableCategories: [2], name: "Vino", parentCategoryId: 1, id: 4},
            {
                relatedDefaultFilterableCategories: [5, 4, 10],
                name: "Altre bevande",
                parentCategoryId: null,
                id: 5
            },
            {relatedDefaultFilterableCategories: [5], name: "Grappe, distillati, liquori", parentCategoryId: 5, id: 6},
            {relatedDefaultFilterableCategories: [4], name: "Birra", parentCategoryId: 5, id: 7},
            {relatedDefaultFilterableCategories: [10], name: "Succhi e Nettari di frutta", parentCategoryId: 5, id: 8},
            {
                relatedDefaultFilterableCategories: [9, 8, 12, 11, 30, 48],
                name: "Dispensa",
                parentCategoryId: null,
                id: 9
            },
            {relatedDefaultFilterableCategories: [12], name: "Passate e sughi", parentCategoryId: 9, id: 10},
            {relatedDefaultFilterableCategories: [11], name: "Miele", parentCategoryId: 9, id: 11},
            {relatedDefaultFilterableCategories: [9, 8], name: "Conserve Dolci e Salate", parentCategoryId: 9, id: 12},
            {relatedDefaultFilterableCategories: [30], name: "Gelato", parentCategoryId: 9, id: 13},
            {relatedDefaultFilterableCategories: [48], name: "Infusi e tisane", parentCategoryId: 9, id: 14},
            {
                relatedDefaultFilterableCategories: [13, 25, 24, 22, 23, 20, 21, 17, 18, 19, 35],
                name: "Frutta e verdura",
                parentCategoryId: null,
                id: 15
            },
            {relatedDefaultFilterableCategories: [13], name: "Erbe Aromatiche e Spezie", parentCategoryId: 15, id: 16},
            {relatedDefaultFilterableCategories: [25], name: "Tartufi", parentCategoryId: 15, id: 17},
            {relatedDefaultFilterableCategories: [24], name: "Funghi", parentCategoryId: 15, id: 18},
            {relatedDefaultFilterableCategories: [22, 23], name: "Verdura", parentCategoryId: 15, id: 19},
            {relatedDefaultFilterableCategories: [20, 21, 17, 18, 19], name: "Frutta", parentCategoryId: 15, id: 20},
            {relatedDefaultFilterableCategories: [35], name: "Lumache", parentCategoryId: 15, id: 21},
            {
                relatedDefaultFilterableCategories: [14, 39],
                name: "Legumi e cereali",
                parentCategoryId: null, id: 22
            },
            {relatedDefaultFilterableCategories: [14], name: "Legumi", parentCategoryId: 22, id: 23},
            {relatedDefaultFilterableCategories: [39], name: "Cereali", parentCategoryId: 22, id: 24},
            {
                relatedDefaultFilterableCategories: [29, 28, 27, 34],
                name: "Formaggi e latticini",
                parentCategoryId: null,
                id: 25
            },
            {relatedDefaultFilterableCategories: [29], name: "Latte e latticini", parentCategoryId: 25, id: 26},
            {
                relatedDefaultFilterableCategories: [28],
                name: "Formaggi semistagionati/stagionati",
                parentCategoryId: 25,
                id: 27
            },
            {relatedDefaultFilterableCategories: [27], name: "Formaggi freschi", parentCategoryId: 25, id: 28},
            {relatedDefaultFilterableCategories: [34], name: "Uova", parentCategoryId: 25, id: 29},
            {
                relatedDefaultFilterableCategories: [32, 33],
                name: "Carne e salumi",
                parentCategoryId: null,
                id: 30
            },
            {relatedDefaultFilterableCategories: [33], name: "Salumi", parentCategoryId: 30, id: 31},
            {relatedDefaultFilterableCategories: [32], name: "Carne Fresca", parentCategoryId: 30, id: 32},
            {
                relatedDefaultFilterableCategories: [15, 40, 38, 37],
                name: "Forno, pasta e farine",
                parentCategoryId: null,
                id: 33
            },
            {relatedDefaultFilterableCategories: [15], name: "Piatti Pronti", parentCategoryId: 33, id: 34},
            {relatedDefaultFilterableCategories: [40], name: "Pane e prodotti da forno", parentCategoryId: 33, id: 35},
            {relatedDefaultFilterableCategories: [38], name: "Farine", parentCategoryId: 33, id: 36},
            {relatedDefaultFilterableCategories: [37], name: "Pasta", parentCategoryId: 33, id: 37},
            {
                relatedDefaultFilterableCategories: [42, 43, 44],
                name: "Pesce",
                parentCategoryId: null,
                id: 38
            },
            {relatedDefaultFilterableCategories: [44], name: "Pesce fresco", parentCategoryId: 38, id: 39},
            {relatedDefaultFilterableCategories: [43], name: "Molluschi", parentCategoryId: 38, id: 40},
            {relatedDefaultFilterableCategories: [42], name: "Crostacei", parentCategoryId: 38, id: 41},
            {
                relatedDefaultFilterableCategories: [46, 47],
                name: "Benessere",
                parentCategoryId: null,
                id: 42
            },
            {relatedDefaultFilterableCategories: [47], name: "Detergenti", parentCategoryId: 42, id: 43},
            {relatedDefaultFilterableCategories: [46], name: "Cosmesi", parentCategoryId: 42, id: 44},
            {
                relatedDefaultFilterableCategories: [50, 51],
                name: "Fiori e piante",
                parentCategoryId: null,
                id: 45
            },
            {relatedDefaultFilterableCategories: [50], name: "Fiori", parentCategoryId: 45, id: 46},
            {relatedDefaultFilterableCategories: [51], name: "Piante", parentCategoryId: 45, id: 47},
            {
                relatedDefaultFilterableCategories: [52],
                name: "Cassetta Del Contadino",
                parentCategoryId: null,
                id: 48
            },


        ]

    }
);
const getters = {
    channelCustomCategory: (state) => (id) => {
        return state.channelCustomCategories.find(channelCustomCategory => channelCustomCategory.id === parseInt(id))
    },
    structuredFilterableCategories: state => {
        let gerarchyFilterableCategory = [];


        let parentFilterableCategories = _.filter(state.channelCustomCategories, function (o) {
            return o.parentCategoryId === null;
        });
        parentFilterableCategories.forEach(parentFilterableCategory => {
            let parentFilterableCategoryCopy = Object.assign({}, parentFilterableCategory)
            parentFilterableCategoryCopy.childrens = _.filter(state.channelCustomCategories, function (o) {
                return o.parentCategoryId === parentFilterableCategoryCopy.id;
            });
            gerarchyFilterableCategory.push(parentFilterableCategoryCopy);
        });
        return gerarchyFilterableCategory;
    },
};
const actions = {};
const mutations = {

    setdefaultFilterableCategories(state, defaultFilterableCategories) {
        state.defaultFilterableCategories = defaultFilterableCategories;
        state.channelCustomCategories.forEach(channelCustomCategory => {
            channelCustomCategory.products_count = 0;
            channelCustomCategory.relatedDefaultFilterableCategories.forEach(relatedDefaultFilterableCategoryId => {
                let relatedCategory = state.defaultFilterableCategories.find(defaultCategory => parseInt(defaultCategory.id) === relatedDefaultFilterableCategoryId)
                if (relatedCategory !== undefined) {
                    channelCustomCategory.products_count += relatedCategory.products_count;
                }
            });
        })


    },
};

export default {
    namespaced: true, //
    state,
    getters,
    actions,
    mutations
}