<template>
  <v-form ref="form" lazy-validation>
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
              dense
              v-model="shoppingtListRequest.name"
              label="Nome lista della Spesa"
              :rules="[v => !!v || 'Nome obbligatorio']"></v-text-field>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
              dense
              v-model="shoppingtListRequest.description"
              label="Descrizione lista della Spesa"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";

export default {
  name: "ShoppinglistForm",
  mixins: [StoreComputed],
  props: ["shoppingListData"],
  data: () => ({
    shoppingtListRequest: {
        name: "",
        description: "",
      },
  }),
  watch: {
    shoppingListData: function (newShoppingListData) {
      if (newShoppingListData !== undefined && newShoppingListData !== null) {
        let shoppinglist = Object.assign({}, newShoppingListData);
        delete shoppinglist.id;
        delete shoppinglist.created_at;
        delete shoppinglist.updated_at;
        delete shoppinglist.channel_id;
        delete shoppinglist.user_id;
        this.shoppingtListRequest = shoppinglist;
      }
    },
  },
}
;
</script>

<style scoped>
</style>

