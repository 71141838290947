<template>
  <v-app-bar
      app
      color="white"
      class="elevation-0"
      clipped-left
      id="app_bar"
      absolute
      elevate-on-scroll
      prominent
      dense
  >
    <v-btn color="customGreen" fab dark small v-if="application.showMobileMenu" @click.stop="$store.commit('application/toggleDrawer')" class="align-self-center mr-3">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <template v-if="showMobileSearch && isMobile">
      <v-btn icon @click="showMobileSearch = false">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </template>
    <template v-else>
      <v-spacer></v-spacer>
      <img class="site-logo d-block" src="@/assets/logos/io-sono-lucano.png"
           alt="io-sono-lucano"
           @click="$router.push({name:'Home'})"
      />
      <v-spacer></v-spacer>
      <v-btn @click="$router.push({name:'Cart'})" icon x-large class="align-self-center">
        <v-badge
            class="mx-4"
            :overlap="true"
            color="customGreen"
            :value="cart.cartItems.length !== 0"
            :content="cart.cartItems.length"
        >
          <v-icon>
            mdi-cart
          </v-icon>
        </v-badge>
      </v-btn>
      <v-menu
          v-model="userMenu"
          :close-on-content-click="true"
          bottom
          open-on-hover
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="md-mr-5 align-self-center mr-6"
              icon x-large
              v-bind="attrs"
              v-on="on"
              :to="{name:'Account'}"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-card v-if="user.isAuthenticated">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user.firstName + ' ' + user.lastName }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item link :to="{name:'AccountPersonalInformation'}" v-if="this.user.role.name === 'user'">
              <v-list-item-content>
                <v-list-item-title>Informazioni Personali</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{name:'AccountOrders'}" v-if="this.user.role.name === 'user'">
              <v-list-item-content>
                <v-list-item-title>Ordini</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{name:'AccountAddresses'}" v-if="this.user.role.name === 'user'">
              <v-list-item-content>
                <v-list-item-title>Indirizzi di spedizione</v-list-item-title>
              </v-list-item-content>
            </v-list-item>


            <v-list-item link @click="logout">
              <v-list-item-content>
                <v-list-item-title>Esci</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-card v-else>
          <v-list dense>
            <v-list-item link :to="{name:'Login'}">
              <v-list-item-content>
                <v-list-item-title>Accedi</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{name:'SignUp'}">
              <v-list-item-content>
                <v-list-item-title>Registrati</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>
    <template v-slot:extension v-if="!application.showMobileMenu">
      <FilterableCategoryMenu ></FilterableCategoryMenu>
    </template>
  </v-app-bar>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import FilterableCategoryMenu from "@/views/ecommerce/components/FilterableCategoryMenu";

export default {
  name: "Header",
  components: {FilterableCategoryMenu},
  mixins: [StoreComputed],
  data: () => ({
    drawer: false,
    showMobileSearch: false,
    userMenu: false,
    productSearchString: '',
    keycloak: {},
    isMobile: false,
    showMobileMenu: false
  }),
  methods: {
    logout: function () {
      localStorage.removeItem('ecommerce-coldiretti-access-token');
      this.$router.go('/');
    },
    onResize() {
      this.isMobile = window.innerWidth < 960;
      this.showMobileMenu = window.innerWidth < 1130;
    },
    search() {
      this.$router.push({name: 'SearchProducts', params: {searchString: this.productSearchString}});
      this.showMobileSearch = false;
      this.productSearchString = '';
    }
  },
  watch: {
    isMobile: function (isMobile) {
      if (!isMobile && this.showMobileSearch) {
        this.showMobileSearch = false;
      }
    },
    showMobileMenu: function (showMobileMenu) {
      if (!showMobileMenu && this.drawer) {
        this.drawer = false;
      }
    }
  },
  created() {
    this.keycloak = new this.keycloakJs({
      url: this.config.keycloak.member.url + '/auth',
      realm: this.config.keycloak.member.realm,
      clientId: this.config.keycloak.member.clientId,
      flow: this.config.keycloak.member.flow,
    });
    this.keycloak.init({});
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, {passive: true})
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, {passive: true})
  },
}
</script>

<style lang="scss">
#app_bar {
  max-width: 1280px;
  margin: 0 auto;

  .site-logo {
    height: 100%;
    width: auto;
  }

}

.v-toolbar__extension {
  padding: 0 !important;
}
</style>