<template>
    <v-container class="pt-0" fuild style="max-width:1280px">
        <v-card tile flat>
            <v-container fluid class="px-0">
                <v-row>
                    <v-col class="px-0">
                        <v-img cover
                            src="@/assets/images/chi-siamo-banner.png"
                        ></v-img>    
                    </v-col>
                </v-row>
                <v-row class="mx-10">
                    <v-col cols="12">
                        <h1 class="text-uppercase font-weight-light customGreen--text">chi siamo</h1>
                    </v-col>
                </v-row>
                <v-row class="mx-10">
                    <v-col cols="6">
                        <v-card-text>
                            <p style="font-family:Arial; font-size:smaller">
                                “Io Sono Lucano” <br>
                                Accentuata volatilità dei prezzi, continua crescita del potere asimmetrico lungo le filiere agroalimentari, 
                                ampia diffusione delle frodi e delle contraffazioni alimentari, in tale contesto le imprese agricole si vedono 
                                minare in modo preoccupante la loro capacità di stare sul mercato e, soprattutto, la possibilità di poter cogliere 
                                le tante opportunità connesse ai nuovi comportamenti ed alle nuove sensibilità dei cittadini-consumatori. <br>
                                Nell’ambito di queste tendenze la Coldiretti di Basilicata, nel porsi la questione su cosa fosse necessario fare 
                                per consentire all’agricoltura di giocare con successo un nuovo ruolo e, quindi, di cogliere le opportunità 
                                derivanti dai cambiamenti di scenario, rispondendo efficacemente ai comportamenti di acquisto “Critici e 
                                Responsabili” dei cittadini/consumatori e dare vigore allo sviluppo sostenibile dell’agricoltura lucana, ha deciso 
                                di coordinare progetti di filiera da proporre nell’ambito del PSR Basilicata.  <br>
                                La volontà è stata quella di proporre progetto economico, sociale e culturale di grande respiro strategico per 
                                la Basilicata; un progetto che prende in considerazione tutti i molteplici aspetti connessi alla produzione del 
                                “cibo di qualità” e del suo consumo: dai modelli di produzione alle forme di commercializzazione; dall’organizzazione 
                                dei mercati locali all’internazionalizzazione; dagli strumenti di comunicazione trasparenti all’assunzione di 
                                comportamenti responsabili lungo tutta la filiera, fino alla fase finale dell’acquisto. In tale contesto il cibo 
                                ha assunto il carattere di “bene comune”.  <br>
                                Un Progetto che ha quale obiettivo quello di provocare la realizzazione di una rete territoriale dei prodotti e 
                                delle imprese utile a testare la fattibilità, la sostenibilità e l’efficienza, sia in termini organizzativi che 
                                economici e sociali. Un Progetto che punta anche a costruire un sistema di imprese che sia competitivo sul mercato, 
                                capace di sviluppare un dialogo aperto e intenso con il cittadino consumatore ed un rapporto corretto e senza 
                                intermediazioni con la DO/GDO riequilibrando il valore generato dalla filiera. <br> 
                                Un Progetto che ha quale paradigma la “Dieta Mediterranea”, i cui ingredienti principali sono olio di oliva, cereali, 
                                frutta, verdura e legumi (freschi o secchi), una parte moderata di pesce, prodotti lattiero-caseari e carne, numerosi 
                                condimenti e spezie, il tutto accompagnato da vino o infusioni, sempre nel rispetto delle convinzioni di ogni comunità. 
                                Questo è l’agroalimentare della Basilicata, compreso tutte le produzioni DOP, IGP e i Prodotti Agricoli Territoriali, 
                                e questo è alla base del progetto “Io Sono Lucano”. <br>
                                Per una gestione del progetto più sostenibile, efficiente ed efficace 
                                i partner di filiera hanno voluto darsi una governance di sistema costituendo la Società Consortile Cooperativo 
                                “La Nuova Aurora” per parlare veramente di sistema agroalimentare lucano.  <br>
                                A tal fine, la Coldiretti Basilicata ha creato il marchio collettivo “IO SONO LUCANO”, registrato al MISE il 21 gennaio 
                                2019, destinato a contraddistinguere, mediante “contratto di licenza d’uso” con “La Nuova Aurora”, i prodotti agroalimentari 
                                lucani. Per una gestione del progetto più sostenibile, efficiente ed efficace i partner di filiera hanno voluto darsi 
                                una governance di sistema costituendo la Società Consortile Cooperativo “La Nuova Aurora” per parlare veramente di 
                                sistema agroalimentare lucano, controllato e certificato.
                            </p>
                        </v-card-text>
                    </v-col>
                    <v-col cols="6">
                        <v-card-text>
                            <p style="font-family:Arial; font-size:smaller">
                                <b> PRODOTTI AGROALIMENTARI A MARCHIO </b> <br> 
                                Pasta secca vari formati <br>
                                Pasta secca formati tradizionali <br>
                                Farina varie tipologie <br> 
                                Legumi (ceci e lenticchie) <br>
                                Fagiolo Bianco di Rotonda DOP <br>
                                Fagiolo di Sarconi IGP <br> 
                                Ortofrutta fresca  <br>
                                Pelati  <br>
                                Funghi freschi  <br>
                                Sughi pronti e similari  <br>
                                Peperoni di Senise IGP  <br>
                                Melanzana Rossa DOP (fresca e trasformata)  <br>
                                Salumi Carni fresche e confezionate  <br>
                                Caciocavallo Podolico  <br>
                                Canestrato di Moliterno IGP  <br>
                                Pecorino di Filiano DOP  <br>
                                Latte e yogurt  <br>
                                Latticini  <br>
                                Olio extra vergine di oliva 100% Lucano  <br>
                                Vino (tutte le DOP della Basilicata)  <br>
                                Miele  <br>
                                Erbe officinali <br><br>
                                
                                <b>Caratteristiche del marchio Il marchio collettivo</b> <br>
                                - può essere concesso in uso non esclusivo, previa autorizzazione della Coldiretti stessa, 
                                    ad una o più SOCIETA’ DI SCOPO, partecipate da imprenditori agricoli e/o loro associazioni <br>
                                – quali quelle che sono previste nell’ambito dei PVF candidati al Bando PSR Basilicata; <br>
                                - Il marchio può essere concesso anche ad altre filiere, diverse da quelle proposte a Bando,
                                    ad esempio quelle dell’olio, del miele e del vino; <br>
                                - può essere utilizzato esclusivamente per contraddistinguere i prodotti della filiera agricola 
                                    lucana a marchio “Io sono Lucano” conformi alle condizioni e termini fissati nel regolamento d’uso, 
                                    nonché per contraddistinguere locali di vendita e spazi di vendita, anche all’interno di strutture 
                                    commerciali della DO/GDO. Può essere abbinato anche a marchi aziendali, sempre della filiera; tale 
                                    tipologia verrà utilizzata nella fase di avviamento del progetto (Progetto Pilota).
                                <br>
                                <b>LA GESTIONE DEL MARCHIO</b> <br> 
                                Presuppone per i licenziatari: <br>
                                    - La sottoscrizione del Regolamento d’uso del Marchio (registrato al MISE); <br>
                                    - L’approvazione del Disciplinare Tecnico di Filiera (DTF): il documento atto 
                                        a garantire la rintracciabilità ed i requisiti di idoneità agricola, origine e 
                                        controllo del prodotto a marchio IO SONO LUCANO, a partire dalle imprese agricole 
                                        fino alla consegna del prodotto confezionato presso i distributori; <br> 
                                    - Il marchio può essere concesso anche ad altre filiere, diverse da quelle proposte a Bando,
                                        ad esempio quelle dell’olio, del miele e del vino; <br>
                            </p>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-row>
            <v-col cols="12" class="white px-0 pt-1 pb-0">
                <v-img
                    :aspect-ratio="2.9/1"
                    src="@/assets/images/sfondo-banner.png"
                >
                    <v-overlay
                        absolute opacity=0
                        class="d-flex align-end justify-start"
                    >
                        <v-card class="transparent mb-16 pb-9 elevation-0">
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-btn icon class="px-14 pt-10">
                                            <v-icon>
                                                mdi-arrow-right
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="px-14 Raleway font-weight-bold banner-text">
                                        <h1>E COMMERCE<br> 
                                        Un nuovo modo di fare la spesa</h1>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-overlay>
                </v-img>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'WhoAreWe',
}
</script>