const state = () => ({
        google_analytics_tracking_id: window.appConfig.google_analytics_tracking_id,
        ecommerce_service_url: window.appConfig.ecommerce_service_url,
        keycloak: {
            member: {
                url: window.appConfig.keycloak.member.url,
                realm: window.appConfig.keycloak.member.realm,
                clientId: window.appConfig.keycloak.member.clientId,
                flow: window.appConfig.keycloak.member.flow,
                logoutRedirect_uri: window.appConfig.keycloak.member.logoutRedirect_uri,
            },
        },
        channel: {id: window.appConfig.channel.id}
    }
);
const getters = {
    google_analytics_tracking_id(state) {
        return state.google_analytics_tracking_id;
    }
};
const actions = {};
const mutations = {};

export default {
    namespaced: true, //
    state,
    getters,
    actions,
    mutations
}
