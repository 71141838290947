<template>
  <v-container>
    <v-row class="px-4 mb-5">
      <v-card class="pa-0 elevation-0">
        <h1 class="roboto font-weight-thin text-uppercase border-bottom-text mr-3">
          Ricerca: {{ searchString }}
        </h1>
      </v-card>
      <v-col class="border-shadow pa-0">
      </v-col>
    </v-row>
    <template v-if="getCategoriesProductRequest.loading">
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <template v-if="catalogProducts.length > 0">
        <v-row>
          <v-col v-for="(catalogProduct , index) in catalogProducts" :key="index"
                 class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 px-3 pb-6 d-flex">
            <product-card :key="index"
                          :catalogProduct="catalogProduct"
                          :show-business="true">
            </product-card>
          </v-col>
        </v-row>
        <v-row v-if="pages.length > 1">
          <v-col class="text-center middle-black-line" style="position:relative">
            <template v-for="(page,index) in pages">
              <v-btn class="elevation-0 white"
                     tile
                     text
                     :key="index"
                     @click="getProducts(16, page)"
                     :disabled="getCategoriesProductRequest.loading">
                {{ page }}
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col>
            La ricerca non ha prodotto risultati
          </v-col>
        </v-row>
      </template>
    </template>
  </v-container>
</template>

<script>
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import ProductCard from "@/views/ecommerce/components/product/ProductCard";

export default {
  mixins: [StoreComputed, ResponseErrorHandler],
  name: "index",
  components: {ProductCard},
  data: () => ({
    catalogProducts: [],
    getCategoriesProductRequest: {loading: true, error: false},
    pagination: {total: null, last_page: null, current_page: null}
  }),
  methods: {
    getProducts: function (limit, page) {
      this.getCategoriesProductRequest.loading = true;
      this.axios.get(
          this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/products?limit=' + limit + '&page=' + page + '&includes=product.business,product.images,product.certifications,product.specification_sheet&product_name:lk=' + this.searchString,
          {
            headers: {
              Authorization: 'Bearer ' + this.user.jwtToken,
              Accept: 'application/json'
            }
          }
      ).then(res => {
        this.catalogProducts = [];
        res.data.data.forEach(catalogProduct => {
          this.catalogProducts.push(catalogProduct.catalog_product)
        });
        let meta = res.data.meta;
        this.pagination = {total: meta.total, current_page: meta.current_page, last_page: meta.last_page}
      }).catch(error => {
        this.handleResponseError(error);
      }).finally(() => {
        this.getCategoriesProductRequest.loading = false;
      });
    },
  },
  watch: {
    searchString: function () {
      this.getProducts(16, 1);
    }
  },
  computed: {
    pages: function () {
      let pages = [];
      for (let i = 1; i <= this.pagination.last_page; i++) {
        pages.push(i)
      }
      return pages;
    },
    searchString: function () {
      return this.$route.params.searchString;
    }
  },
  mounted() {
    this.getProducts(16, 1);
  }
}
</script>

<style scoped>

</style>