<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "shoppingLists",
};
</script>

<style scoped>

</style>
