<template>
    <canvas class="canvas" ref="canvas" v-on:keyup="createCaptcha"></canvas>
</template>

<script>
export default {
    name: "CaptchaImage",
    data: () => ({
        captcha: [],
        ctx: '',
        filled: false,
    }),
    mounted() {
        this.ctx = this.$refs.canvas.getContext('2d');
        this.ctx.font = "55px Georgia";
        this.ctx.strokeText(this.captcha.join(""), 15, 60);
    },
    created() {
        this.createCaptcha();
    },
    methods: {
        createCaptcha: function() {
            this.captcha = [];
            let charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
            for(let i=0; i<6; i++){
                let index = Math.floor(Math.random() * charsArray.length + 1); //prende il char dopo dall'array
                if (this.captcha.indexOf(charsArray[index]) == -1){
                    this.captcha.push(charsArray[index]);
                } else {
                    i--;
                }
            }

            let code = this.captcha.join("");

            if(this.filled) {
                this.ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
                this.fillCanvas();
            } else {
                this.filled = true;
            }
            this.$emit('createdCaptchaCode', code);
        },
        fillCanvas: function() {
            this.ctx.strokeText(this.captcha.join(""), 15, 60);
        }
    }
}
</script>

<style scoped>
    .canvas {
        width: 100%; 
        height: fit-content;
    }
</style>