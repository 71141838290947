let cartItem = {
    methods: {
        isCartItemAvailable: function (cartItem) {
            return cartItem.catalog_product.deleted_at !== null ||
                cartItem.catalog_product.product.deleted_at !== null ||
                cartItem.catalog_product.product.product_status_id === '1';
        },
        isCartItemQuantityValid: function (cartItem) {
            let quantityAboveMinimum = (parseFloat(cartItem.quantity) - parseFloat(cartItem.catalog_product.quantity_min)) * 1000;
            let step = parseFloat(cartItem.catalog_product.step) * 1000;
            let module = (quantityAboveMinimum.toFixed() % step.toFixed()) / 1000;
            return module === 0;
        },
        isCartQuantityInStock: function (cartItem) {
            console.log(cartItem.catalog_product.product);
            if (cartItem.catalog_product.product.stock !== null) {
                return parseFloat(cartItem.quantity) <= parseFloat(cartItem.catalog_product.product.stock.quantity)
            }
            return true;
        },
        isCartQuantityLessThenMaximumQuantity: function (cartItem) {
            if (cartItem.catalog_product.quantity_max !== null) {
                return parseFloat(cartItem.quantity) <= parseFloat(cartItem.catalog_product.quantity_max)
            }
            return true;
        }
    }
};
export default cartItem