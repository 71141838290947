<template>
  <v-container fluid >
    <router-view></router-view>
    <v-row>
      <v-col>
        <v-card tile>
          <v-toolbar dense color="primary" class="white--text">
            <v-toolbar-title>Ordini</v-toolbar-title>
          </v-toolbar>
        </v-card>
        <v-container fluid class="px-0">
          <v-row>
            <v-col>
              <OrderGroupRecap v-for="(orderGroup , index) in orderGroups" v-bind:orderGroupData="orderGroup" :key="index"/>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import computedMixin from "@/mixins/StoreComputed";
import responseErrorHandler from "@/mixins/ResponseErrorHandler";
import OrderGroupRecap from "@/views/ecommerce/account/orders/Components/OrderGroupRecap";
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "AccountOrders",
  mixins: [computedMixin, responseErrorHandler],
  components: {OrderGroupRecap},
  computed: {
    ...mapGetters({
      orders: 'userOrders/orders',
    }),
    orderGroups: {
      get(){
        return this.$store.getters['userOrders/orders'];
      },
      set(orderGroups){
        this.setOrderGroups(orderGroups);
      }
    }
  },
  methods: {
    ...mapActions({
      setOrderGroups: 'userOrders/setOrders',
      destroyOrderGroups: 'userOrders/cleanOrders',
    }),
    getUserOrderGroups: function () {
      this.axios.get(this.config.ecommerce_service_url + '/api/v1/users/' + this.user.id + '/order_groups?includes=shipping_address,paypal_payment,order_group_status,delivery.delivery_type&sort=-created_at',
          {
            headers: {
              Authorization: 'Bearer ' + this.user.jwtToken,
              Accept: 'application/json'
            }
          })
          .then(response => {
            this.orderGroups = response.data.data.map(orderGroup => orderGroup.order_group);
          })
          .catch(error => {
            this.handleResponseError(error);
          });
    }
  },
  created() {
    this.getUserOrderGroups();
  },
  beforeDestroy() {
    this.destroyOrderGroups();
  }
}
</script>

<style scoped>

</style>
