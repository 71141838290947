<template>
  <v-dialog
      :value="dialog"
      max-width="800"
      @click:outside="$emit('close-dialog')"
  >
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="5">
            <v-carousel
                height="auto"
                show-arrows-on-hover
                hide-delimiter-background
                :show-arrows="catalogProduct.product.images.length > 1"
                :hide-delimiters="catalogProduct.product.images.length <= 1"
            >
              <template v-if="catalogProduct.product.images.length !== 0">
                <v-carousel-item
                    v-for="(item,index) in catalogProduct.product.images"
                    :key="index"
                >
                  <v-img
                      :key="index"
                      :src="item.media.url"
                      :srcset="item.media.srcset"
                      sizes="10vw"
                      contain
                      min-width="100%"
                      :aspect-ratio="1"
                  >
                  </v-img>
                </v-carousel-item>
              </template>
              <template v-else>
                <v-carousel-item>
                  <v-img
                      src="@/assets/placeholders/default-placeholder.jpeg"
                      cover
                      min-width="100%"
                      :aspect-ratio="1"
                  >
                  </v-img>
                </v-carousel-item>
              </template>
            </v-carousel>
          </v-col>
          <v-col cols="7" class="pt-12">
            <v-row>
              <v-col cols="12" class="py-0">
                <a class="roboto font-weight-black customRaspBerry--text"
                   @click="$router.push({name:'Business', params:{id :catalogProduct.product.business.id}})">{{
                    catalogProduct.product.business.name
                  }}</a>
              </v-col>
              <v-col cols="12" class="py-0">
                <p class="roboto font-weight-medium">{{ catalogProduct.product.name }}</p>
              </v-col>
              <v-col cols="12" class="py-0">
                <p class="roboto font-weight-regular">
                  {{ getProductSellingDescription(catalogProduct.product) }}</p>
              </v-col>
              <v-col cols="12" class="py-0">
                <p class="roboto font-weight-black mb-3">
                  {{ getProductPrice(catalogProduct.product, catalogProduct, true) }}</p>
              </v-col>
            </v-row>
            <CartQuantityModifier v-bind:catalogProductData="catalogProduct" class="mt-3"></CartQuantityModifier>
            <v-row>
              <v-col cols="12">
                    <span class="roboto font-weight-medium text-uppercase product-dialog-data-headers">
                                <template
                                    v-if="catalogProduct.product.availability_from_month === '1' && catalogProduct.product.availability_to_month === '12'">
                            SEMPRE DISPONIBILE
                        </template>
                        <template v-else>
                            DISPONIBILE DA
                            {{ $store.getters['commons/month'](parseInt(catalogProduct.product.availability_from_month)).name }}
                            A {{ $store.getters['commons/month'](parseInt(catalogProduct.product.availability_to_month)).name }}
                        </template>
                            </span>
              </v-col>
            </v-row>
            <v-row v-if="catalogProduct.product.bio">
              <v-col cols="12" class="px-4">
                <span class="roboto font-weight-medium text-uppercase product-dialog-data-headers">Bio</span>
                <v-icon color="green">
                  mdi-leaf
                </v-icon>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <v-expansion-panels flat tile>
              <v-expansion-panel v-if="catalogProduct.product.description !== null">
                <v-expansion-panel-header class="text-uppercase font-weight-bold">Descrizione</v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ catalogProduct.product.description }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="catalogProduct.product.notes !== null">
                <v-expansion-panel-header class="text-uppercase font-weight-bold">Note</v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ catalogProduct.product.notes }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                  v-if="catalogProduct.product.certifications !== null && catalogProduct.product.certifications.length !== 0">
                <v-expansion-panel-header class="text-uppercase font-weight-bold">Certificazioni
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-chip
                      style="border-radius:0"
                      v-for="( certification, index ) in catalogProduct.product.certifications"
                      class="ma-2"
                      :key="index"
                      tile
                  >
                    {{ certification.certification.name }}
                  </v-chip>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                  v-if="catalogProduct.product.ingredients !== null && catalogProduct.product.ingredients.length !== 0">
                <v-expansion-panel-header class="text-uppercase font-weight-bold">Ingredienti</v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ catalogProduct.product.ingredients.join(', ') + '.' }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                  v-if="catalogProduct.product.allergens !== null && catalogProduct.product.allergens.length !== 0">
                <v-expansion-panel-header class="text-uppercase font-weight-bold">Allergeni</v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ catalogProduct.product.allergens.join(', ') + '.' }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="catalogProduct.product.directions !== null">
                <v-expansion-panel-header class="text-uppercase font-weight-bold">Istruzioni d'uso
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ catalogProduct.product.directions }}
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="catalogProduct.product.storage_conditions !== null">
                <v-expansion-panel-header class="text-uppercase font-weight-bold">Conservazione
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  {{ catalogProduct.product.storage_conditions }}
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Product from '@/mixins/Product';

import CartQuantityModifier from "@/views/ecommerce/components/product/CartQuantityModifier";

export default {
  name: 'ProductDialog',
  components: {CartQuantityModifier},
  props: ['catalogProduct', 'dialog'],
  mixins: [Product],
  data: () => ({
    showImgZoom: false,
    imgSrc: null,
    containerImgZoom: {
      width: '',
      height: ''
    },
    overlaySize: {
      width: '',
      height: ''
    },
    percWidth: '',
    percHeight: '',
    left: '',
    top: '',
  }),
  methods: {
    mouseOver: function (imgSrc) {
      this.showImgZoom = true;
      this.imgSrc = imgSrc;
    },
    mouseLeave: function () {
      this.showImgZoom = false;
      this.imgSrc = null;
    },
    mouseMove: function (event) {
      let rect = event.target.getBoundingClientRect();
      //console.log(event)
      this.containerImgZoom.width = this.$refs.containerImgZoom.offsetWidth - 40;
      this.containerImgZoom.height = this.$refs.containerImgZoom.offsetHeight - 40;

      let imgWidth = rect.width;
      let imgHeight = rect.height;

      let x = event.clientX - rect.left;
      let y = event.clientY - rect.top;

      this.percWidth = (x / imgWidth) * 100;
      this.percHeight = (y / imgHeight) * 100;

      let ratio = this.containerImgZoom.height / this.containerImgZoom.width;

      this.overlaySize.width = (this.containerImgZoom.width * ratio) * 0.35;
      this.overlaySize.height = (this.containerImgZoom.height * ratio) * 0.35;

      this.left = x - (this.overlaySize.width / 2);
      this.top = y - (this.overlaySize.height / 2);

      if (this.left > imgWidth - this.overlaySize.width) {
        this.left = imgWidth - this.overlaySize.width;
      }
      if (this.left < 0) {
        this.left = 0;
      }
      if (this.top > imgHeight - this.overlaySize.height) {
        this.top = imgHeight - this.overlaySize.height;
      }
      if (this.top < 0) {
        this.top = 0;
      }
    },
  }
}
</script>

<style scoped>

</style>