const state = () => ({
    drawer:false,
    isMobile:false,
    showMobileMenu:false
});

const getters = {};
const actions = {};
const mutations = {
    toggleDrawer(state){
        state.drawer = !state.drawer;
    },
    setDrawer(state,drawer){
        state.drawer = drawer;
    },
    setIsMobile(state,isMobile){
        state.isMobile = isMobile;
    },
    setShowMobileMenu(state,showMobileMenu){
        state.showMobileMenu = showMobileMenu;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}