<template>

  <v-row class="align-center justify-lg-space-between">
    <v-col class="d-flex">
      <v-btn tile
             color="white"
             fab
             @click="decrement"
             small
             :disabled="postCartItemRequest.loading||putCartItemRequest.loading||deleteCartItemRequest.loading||!catalogProductIsAvailable"
             class="black--text elevation-0">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <div class="d-flex align-stretch" style="border:2px solid black">
        <span class="align-self-center mx-3" style="white-space: nowrap">
                     <template v-if="this.catalogProductData.product.packaged">
                         {{ Math.floor(parseInt(cartQuantityInput)) }} conf
                     </template>
                     <template v-else>
                         {{
                         parseFloat(cartQuantityInput).toFixed(2)
                       }}{{
                         this.$store.getters['commons/unitOfMeasure'](catalogProductData.product.unit_of_measure_id).symbol
                       }}
                     </template>
                </span>
      </div>
      <v-tooltip top :disabled="!isIncrementButtonDisabled">
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn tile
                   color="white"
                   fab
                   @click="increment"
                   :loading="postCartItemRequest.loading||putCartItemRequest.loading||deleteCartItemRequest.loading"
                   :disabled="!catalogProductIsAvailable || isIncrementButtonDisabled"
                   small
                   class="black--text elevation-0">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>

        </template>
        <span v-if="!catalogProductIsAvailable">Prodotto non disponibile</span>
        <span v-else-if="isIncrementButtonDisabled">Massima quantità raggiunta</span>
      </v-tooltip>
    </v-col>
    <v-col class="text-right">
      <v-btn
          v-if="false"
          tile
          small
          color="black"
          class="white--text elevation-0 mr-4"
          fab
          @click="postShoppingListItem"
          :disabled="cartButton.disabled || !catalogProductIsAvailable"
          :loading="postCartItemRequest.loading||putCartItemRequest.loading||deleteCartItemRequest.loading">
        <v-icon dark>mdi-basket</v-icon>
      </v-btn>
      <v-btn
          tile
          small
          color="black"
          class="white--text elevation-0"
          fab
          :disabled="cartButton.disabled || !catalogProductIsAvailable"
          @click="cartButton.onClick"
          :loading="postCartItemRequest.loading||putCartItemRequest.loading||deleteCartItemRequest.loading">
        <v-icon dark>{{ cartButton.icon }}</v-icon>
      </v-btn>


    </v-col>
  </v-row>
</template>

<script>
import Product from "@/mixins/Product";
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";
import CartItem from "@/mixins/CartItem";
import _ from "lodash";
import Vue from "vue";
import Cart from "@/mixins/rest_api/Cart";
//import shoppingList from "@/views/ecommerce/cart/shoppingList";
export default {
  props: ['catalogProductData'],
  data: () => ({
    showShoppingList: false,
    dialog: false,
    postCartItemRequest: {loading: false},
    putCartItemRequest: {loading: false},
    deleteCartItemRequest: {loading: false},
    cartQuantityInput: 0.000,
    selectedItemShoppingList: {
      catalog_product_id: "",
      quantity: "",
    }
  }),
  mixins: [Product, StoreComputed, ResponseErrorHandler, CartItem, Cart],
  name: "cart-quantity-modifier",
  methods: {
    addCartItem: function () {
      if (this.user.isAuthenticated) {
        this.postCartItemRequest.loading = true;
        this.postCartItem(this.catalogProductData.id, this.cartQuantityInput).then(response => {
          let newCartItem = response.data.data.cart_item;
          newCartItem.catalog_product.product = this.catalogProductData.product;
          this.$store.commit('cart/addCartItem', newCartItem);
          this.$store.commit('snackbar/update', {
            show: true,
            color: 'success',
            text: 'Prodotto aggiunto al carrello'
          })
        }).catch((error => {
          this.handleResponseError(error);
        })).finally(() => {
          this.postCartItemRequest.loading = false;
        })
      } else {
        let cart = JSON.parse(localStorage.getItem('mercati-campagnamica-guest-cart'))
        cart.push({
          catalog_product_id: this.catalogProductData.id,
          quantity: this.cartQuantityInput.toFixed(3),
          catalog_product: this.catalogProductData
        })
        localStorage.setItem('mercati-campagnamica-guest-cart', JSON.stringify(cart))
        this.$store.commit('cart/setCartItems', cart);
      }
    },
    increment: function () {
      if (this.cartQuantityInput === 0.000) {
        this.cartQuantityInput = parseFloat(this.catalogProductData.quantity_min);
      } else if (this.catalogProductData.quantity_max !== null && this.cartQuantityInput + parseFloat(this.catalogProductData.step) >= parseFloat(this.catalogProductData.quantity_max)) {
        this.cartQuantityInput = parseFloat(this.catalogProductData.quantity_max);
      } else if (this.catalogProductData.product.stock !== null && this.cartQuantityInput + parseFloat(this.catalogProductData.step) >= parseFloat(this.catalogProductData.product.stock.quantity)) {
        this.cartQuantityInput = parseFloat(this.catalogProductData.product.stock.quantity);
      } else {
        let quantityAboveMinimum = (parseFloat(this.cartQuantityInput) - parseFloat(this.catalogProductData.quantity_min)) * 1000;
        let step = parseFloat(this.catalogProductData.step) * 1000;
        let module = (quantityAboveMinimum % step) / 1000;
        if (module === 0) {
          this.cartQuantityInput = this.cartQuantityInput + parseFloat(this.catalogProductData.step);
        } else {
          this.cartQuantityInput = this.cartQuantityInput - module + parseFloat(this.catalogProductData.step);
        }
      }
    },
    decrement: function () {
      if (this.cartQuantityInput - parseFloat(this.catalogProductData.step) >= parseFloat(this.catalogProductData.quantity_min)) {
        let quantityAboveMinimum = (parseFloat(this.cartQuantityInput) - parseFloat(this.catalogProductData.quantity_min)) * 1000;
        let step = parseFloat(this.catalogProductData.step) * 1000;
        let module = (quantityAboveMinimum % step) / 1000;
        if (module === 0) {
          this.cartQuantityInput = this.cartQuantityInput - parseFloat(this.catalogProductData.step);
        } else {
          this.cartQuantityInput = this.cartQuantityInput - module - parseFloat(this.catalogProductData.step);
        }
      } else {
        this.cartQuantityInput = 0.000
      }
    },
    postShoppingListItem: function () {
      this.selectedItemShoppingList.catalog_product_id = this.catalogProductData.id;
      this.selectedItemShoppingList.quantity = this.cartQuantityInput.toFixed(3);
      this.$store.commit('shoppingList/setSelectedShoppingListItems', this.selectedItemShoppingList);
      this.showShoppingList = true;
    },
    updateCartItem: function () {
      if (this.user.isAuthenticated) {
        this.postCartItemRequest.loading = true;
        this.axios.put(
            this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/cart_items/' + this.catalogProductCartStatus.id,
            {
              data: {quantity: this.cartQuantityInput.toFixed(3)}
            },
            {
              headers: {
                Authorization: 'Bearer ' + this.user.jwtToken,
                Accept: 'application/json'
              }
            }
        ).then((response) => {
          let newCartItem = response.data.data.cart_item;
          newCartItem.catalog_product = this.catalogProductData;
          this.$store.commit('cart/updateCartItem', newCartItem);
          this.$store.commit('snackbar/update', {
            show: true,
            color: 'success',
            text: 'Quantità nel carrello aggiornata'
          })
        }).catch(error => {
          this.handleResponseError(error);
        }).finally(() => {
          this.postCartItemRequest.loading = false;
        });
      } else {
        let cart = JSON.parse(localStorage.getItem('mercati-campagnamica-guest-cart'))
        let self = this;
        let CartItemIndexToUpdate = _.findIndex(cart, function (o) {
          return o.catalog_product_id === self.catalogProductData.id;
        });
        Vue.set(cart, CartItemIndexToUpdate, {
          catalog_product_id: this.catalogProductData.id,
          quantity: this.cartQuantityInput.toFixed(3),
          catalog_product: this.catalogProductData
        });
        localStorage.setItem('mercati-campagnamica-guest-cart', JSON.stringify(cart))
        this.$store.commit('cart/setCartItems', cart);
      }
    },
    removeCartItem: function () {
      if (this.user.isAuthenticated) {
        this.postCartItemRequest.loading = true;
        this.axios.delete(
            this.config.ecommerce_service_url + '/api/v1/channels/' + this.channel.id + '/cart_items/' + this.catalogProductCartStatus.id,

            {
              headers: {
                Authorization: 'Bearer ' + this.user.jwtToken,
                Accept: 'application/json'
              }
            }
        ).then(() => {
          this.$store.commit('cart/removeCartItem', this.catalogProductCartStatus.id);
          this.$store.commit('snackbar/update', {
            show: true,
            color: 'success',
            text: 'Prodotto rimosso dal carrello'
          })
        }).catch(error => {
          this.handleResponseError(error);
        }).finally(() => {
          this.postCartItemRequest.loading = false;
        });
      } else {
        let cart = JSON.parse(localStorage.getItem('mercati-campagnamica-guest-cart'))
        let self = this;
        let CartItemIndexToRemove = _.findIndex(cart, function (o) {
          console.log(self.catalogProductData.id);
          return o.catalog_product_id === self.catalogProductData.id;
        });
        cart.splice(CartItemIndexToRemove, 1)
        localStorage.setItem('mercati-campagnamica-guest-cart', JSON.stringify(cart))
        this.$store.commit('cart/setCartItems', cart);
      }
    }
  },
  computed: {
    catalogProductIsAvailable() {
      let date = new Date()
      let month = date.getMonth();
      return parseInt(this.catalogProductData.product.availability_from_month) <= month && parseInt(this.catalogProductData.product.availability_to_month) >= month;
    },
    isIncrementButtonDisabled: function () {
      if (this.catalogProductData.product.stock !== null) {
        return this.cartQuantityInput + parseFloat(this.catalogProductData.step) > parseFloat(this.catalogProductData.product.stock.quantity)
      }
      return this.catalogProductData.quantity_max !== null && this.cartQuantityInput + parseFloat(this.catalogProductData.step) > parseFloat(this.catalogProductData.quantity_max)
    },
    catalogProductCartStatus() {
      return this.cart.cartItems.find(cartItem => cartItem.catalog_product_id === this.catalogProductData.id);
    },
    cartButton() {
      if (this.catalogProductCartStatus === undefined && parseFloat(this.cartQuantityInput) > 0) {
        return {icon: 'mdi-cart-plus', onClick: this.addCartItem}
      } else if (this.catalogProductCartStatus === undefined && parseFloat(this.cartQuantityInput) === 0) {
        return {
          icon: 'mdi-cart', onClick: () => {
            return null
          }, disabled: true
        }
      } else if (parseFloat(this.cartQuantityInput) === 0) {
        return {icon: 'mdi-cart-remove', onClick: this.removeCartItem, disabled: false}
      } else if (parseFloat(this.catalogProductCartStatus.quantity) < parseFloat(this.cartQuantityInput)) {
        return {icon: 'mdi-cart-arrow-down', onClick: this.updateCartItem, disabled: false}
      } else if (parseFloat(this.catalogProductCartStatus.quantity) > parseFloat(this.cartQuantityInput)) {
        return {icon: 'mdi-cart-arrow-up', onClick: this.updateCartItem, disabled: false}
      } else {
        return {
          icon: 'mdi-cart', onClick: () => {
            return null
          }, disabled: true
        }
      }
    }
  },
  watch: {
    catalogProductCartStatus: function (newStatus) {
      if (newStatus !== undefined) {
        this.cartQuantityInput = parseFloat(newStatus.quantity);
      } else {
        this.cartQuantityInput = 0;
      }
    }
  },
  created() {
    if (this.catalogProductCartStatus !== undefined) {
      this.cartQuantityInput = parseFloat(this.catalogProductCartStatus.quantity);
    }
  }
}
</script>

<style scoped>

</style>