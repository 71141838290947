<template>
  <v-dialog
      :value="true"
      persistent
      max-width="1024"
      scrollable
      @click:outside="$router.push({name:'AccountBillingAddressesList'})"
      :disabled="postUserAddressRequest.loading"
  >
    <v-card>
      <v-card-title dense class="white--text primary text-uppercase font-weight-bold elevation-0">
        Aggiungi indirizzo
        <v-spacer></v-spacer>
        <v-btn icon @click="$router.push({name: 'AccountBillingAddressesList'})">
          <v-icon color="primary">mdi-close-thick</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-3">
        <BillingAddressForm ref="addAddressForm"></BillingAddressForm>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text tile large @click="postUserAddress()"
               :loading="postUserAddressRequest.loading">
          Aggiungi
          <v-icon right dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BillingAddressForm from "@/views/ecommerce/components/forms/BillingAddressForm";
import StoreComputed from "@/mixins/StoreComputed";
import ResponseErrorHandler from "@/mixins/ResponseErrorHandler";

export default {
  name: "AccountAddressesAdd",
  components: {BillingAddressForm},
  mixins: [StoreComputed, ResponseErrorHandler],
  data() {
    return {
      postUserAddressRequest: {loading: false},
      dialog: true,
    };
  },
  methods: {
    postUserAddress: function () {
      if (this.$refs.addAddressForm.$refs.form.validate()) {
        this.postUserAddressRequest.loading = true;
        this.axios
            .post(
                this.config.ecommerce_service_url +
                "/api/v1/users/" +
                this.user.id +
                "/addresses",
                {
                  data: this.$refs.addAddressForm.address,
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.user.jwtToken,
                    Accept: "application/json",
                  },
                }
            )
            .then((response) => {
              this.$emit('address:created', response.data.data.address);
              this.$store.commit('snackbar/update', {
                show: true,
                text: 'Indirizzo aggiunto con successo',
                color: 'success'
              })
              this.$router.push({name: "AccountBillingAddressesList"});
            })
            .catch((error) => {
              this.handleResponseError(error);
            })
            .finally(() => {
              this.postUserAddressRequest.loading = false;
            });
      }
    },
  },
};
</script>

<style scoped></style>
