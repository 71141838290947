let filterableCategories = {
    methods: {
        getFilterableCategories: function () {
            return this.axios
                .get(this.config.ecommerce_service_url + "/api/v1/channels/" + this.channel.id + "/filterable_categories", {
                    headers: {
                        //Authorization: "Bearer " + this.user.jwtToken,
                        Accept: "application/json",
                    },
                })
        },
    }
};
export default filterableCategories





